.reminders-list {
  position: absolute;
  width: 100%;
  height: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px;
  }
  &__title {
    h2 {
      font-size: 17px;
      color: $darkBlue;
      font-family: $fontLato;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__table {
    overflow-x: auto;
    @media screen and (max-width: 1200px) {
      table {
        table-layout: unset;
        tbody {
          .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            //   height: -webkit-fill-available;
          }
          tr {
            height: unset;
          }
          td {
            vertical-align: middle;
            white-space: nowrap;
            padding: 2px 15px 10px 15px;
          }
        }
      }
    }
  }
}
