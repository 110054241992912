@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700;800&display=swap);
@charset "UTF-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

input[type='range'] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */ }

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; }

input[type='range']:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */ }

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent; }

/* Small devices */
/* Medium devices */
/* Large devices */
/* Extra large devices */
/* Extra large devices */
/* Custom breakpoint */
table {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  width: 100%;
  padding: 0 15px;
  table-layout: fixed;
  font-family: "Lato", sans-serif; }
  table thead {
    height: 35px;
    line-height: 20px;
    border-radius: 3px; }
    table thead tr th {
      border-bottom: 1px solid #707070;
      font-size: 12px;
      font-weight: 600;
      color: #2e2e2e;
      padding: 0 3px 13px 10px;
      text-align: left;
      white-space: nowrap; }
      table thead tr th input {
        background-color: #f3f3f3;
        border-radius: 6px;
        border: none;
        height: 22px;
        width: 100%;
        margin-top: 10px;
        padding: 0 7px;
        line-height: 22px;
        outline: none; }
    table thead tr .actions {
      width: 130px; }
    table thead tr .remind {
      width: 235px; }
  table tbody:before {
    content: '';
    margin-top: 5px;
    display: block; }
  table tbody tr {
    height: 40px;
    line-height: 15px;
    border-radius: 4px; }
    table tbody tr:last-of-type td {
      border: none; }
    table tbody tr .action-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      height: inherit; }
      table tbody tr .action-icons .fa-headphones-alt {
        font-size: 1.5rem;
        color: #bab7b7;
        margin-right: 10px; }
        table tbody tr .action-icons .fa-headphones-alt:hover {
          color: #727ce5; }
      table tbody tr .action-icons .fa-heart {
        font-size: 1.5rem;
        color: #bab7b7;
        margin-right: 10px; }
      table tbody tr .action-icons .fa-download {
        font-size: 1.2rem;
        color: #bab7b7; }
  table tbody td {
    padding: 2px 15px 25px 15px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.22);
    position: relative;
    font-size: 12px;
    font-weight: bold;
    color: #2e2e2e; }
    table tbody td:not(:last-child):after {
      content: '';
      position: absolute;
      right: 0;
      z-index: 100;
      top: 0;
      width: 1px;
      height: 85%;
      /* or 100px */
      background: rgba(112, 112, 112, 0.22); }
      table tbody td:not(:last-child):after:last-of-type {
        display: none; }
  table tbody .actions {
    vertical-align: -webkit-baseline-middle; }
  table tbody .case {
    color: #222d5c; }
  table tbody .remind {
    font-weight: 400;
    line-height: 19px; }

.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column; }

.ReactTable * {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 18px; }
  @media (max-width: 1665px) {
    .ReactTable * {
      font-size: 11px; } }

.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: visible; }

.ReactTable .rt-thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0; }

.ReactTable .rt-thead.-header {
  border-bottom: 2px solid rgba(0, 0, 0, 0.13);
  font-weight: 700; }
  .ReactTable .rt-thead.-header .rt-th {
    text-align: left; }

.ReactTable .rt-thead .rt-tr {
  text-align: center; }

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative; }

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6); }

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6); }

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer; }

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0; }

.ReactTable .rt-thead .rt-th:focus {
  outline-width: 0; }

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible; }

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden; }

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis; }

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7; }

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px; }

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px; }

.ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto; }

.ReactTable .rt-tbody .rt-tr-group {
  margin-bottom: 4px;
  background-color: #f6f6f6;
  border-radius: 2px;
  position: relative; }

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
  margin-bottom: 0; }

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0; }

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip; }

.ReactTable .rt-tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex; }
  .ReactTable .rt-tr--new-post::before, .ReactTable .rt-tr--new-post-editable::before {
    position: absolute;
    content: 'N';
    text-align: center;
    font-size: 13px;
    bottom: 1px;
    left: 15px;
    width: 30px;
    height: 20px;
    z-index: 10;
    border-radius: 5px;
    border: 1px solid #000; }
  .ReactTable .rt-tr--new-post-editable::before {
    left: 45px; }

.ReactTable .rt-th,
.ReactTable .rt-td {
  flex: 1 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px 15px;
  line-height: 26px;
  overflow: visible;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity; }
  .ReactTable .rt-th .rating__container,
  .ReactTable .rt-td .rating__container {
    display: flex;
    align-items: center;
    height: 40px; }
    .ReactTable .rt-th .rating__container__icons,
    .ReactTable .rt-td .rating__container__icons {
      display: flex;
      align-items: center; }
    .ReactTable .rt-th .rating__container:nth-child(n + 2),
    .ReactTable .rt-td .rating__container:nth-child(n + 2) {
      display: none; }
    .ReactTable .rt-th .rating__container .grey-face,
    .ReactTable .rt-td .rating__container .grey-face {
      margin: 0 15px;
      width: 25px;
      height: 25px; }
    .ReactTable .rt-th .rating__container img,
    .ReactTable .rt-td .rating__container img {
      width: 25px; }
    .ReactTable .rt-th .rating__container svg,
    .ReactTable .rt-td .rating__container svg {
      margin: 0 -10px; }

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important; }

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px; }

.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer; }

.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%) rotate(0); }

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10; }

.ReactTable .rt-tfoot {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15); }

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05); }

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0; }

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03); }

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05); }

.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0; }

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline-width: 0; }

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default; }

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff; }

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 1 1;
  text-align: center; }

.ReactTable .-pagination .-center {
  flex: 1.5 1;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around; }

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap; }

.ReactTable .-pagination .-pageJump {
  display: inline-block; }

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center; }

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px; }

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 7px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px; }

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none; }

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all; }

.ReactTable .-loading.-active > div {
  transform: translateY(50%); }

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.cell--centered {
  text-align: center;
  font-size: 21px;
  line-height: 25px; }

.dictionaryTable .rt-tbody {
  max-height: calc(100vh - 308px);
  min-width: 50px; }

.ReactTable .react-select__menu {
  z-index: 99999; }

.ReactTable.extensionRatingIcons .rating__container {
  background-color: unset; }

.ReactTable.extensionRatingIcons .rating__container__icons {
  min-width: 100px; }

.ReactTable.bhpTable .css-2b097c-container, .ReactTable.bhpTable .css-2b097c-container {
  position: absolute;
  width: calc(100% - 10px); }

.ReactTable.bhpTable--dictionaries, .ReactTable.bhpTable--institutions, .ReactTable.bhpTable--norms, .ReactTable.bhpTable--compendiums {
  margin: 0 20px;
  min-height: 250px; }

.ReactTable.bhpTable .rt-thead .rt-th {
  padding-left: 0;
  padding-right: 10px; }

.ReactTable.bhpTable .rt-thead .rt-resizable-header-content {
  padding: 6px 15px;
  border-radius: 15px;
  background-color: #efefef; }

.ReactTable.bhpTable .rt-td {
  white-space: pre-line;
  padding: 9px 15px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .ReactTable.bhpTable .rt-td.cell--noPadding {
    padding-left: 0;
    padding-right: 0;
    text-align: center; }
  .ReactTable.bhpTable .rt-td.cell--white {
    background-color: #fff; }

.ReactTable.bhpTable .sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1; }
  .ReactTable.bhpTable .sticky--hidden {
    background-color: #fff; }
    .ReactTable.bhpTable .sticky--hidden .rt-resizable-header-content {
      background-color: #fff;
      cursor: none; }
  .ReactTable.bhpTable .sticky--0 {
    left: 0px; }
  .ReactTable.bhpTable .sticky--20 {
    left: 30px; }
  .ReactTable.bhpTable .sticky--200 {
    left: 200px; }
  .ReactTable.bhpTable .sticky--220 {
    left: 230px; }
  .ReactTable.bhpTable .sticky--cell {
    background-color: #f6f6f6;
    overflow: hidden;
    text-overflow: ellipsis; }
  .ReactTable.bhpTable .sticky--header {
    background-color: #fff; }
  .ReactTable.bhpTable .sticky--last:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #fff;
    height: 100%; }

.ReactTable.bhpTable .rt-tbody {
  max-height: calc(100vh - 280px); }

.ReactTable.bhpTable--compendiums .rt-tbody {
  overflow: visible !important;
  max-height: calc(100vh - 300px); }
  @media (max-width: 1665px) {
    .ReactTable.bhpTable--compendiums .rt-tbody {
      max-height: calc(100vh - 300px); } }

.ReactTable.bhpTable--compendiums .rt-thead {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 99; }
  .ReactTable.bhpTable--compendiums .rt-thead:first-of-type {
    top: 0;
    margin-bottom: 0; }
  .ReactTable.bhpTable--compendiums .rt-thead:nth-of-type(2) {
    top: 42px; }

.ReactTable.bhpTable--compendiums .rt-table {
  overflow: auto; }

.extendText {
  white-space: pre-line;
  text-align: justify;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .extendText--btn {
    font-family: "Montserrat", sans-serif;
    color: #c9c9c9;
    cursor: pointer;
    margin-top: 10px;
    font-weight: 700; }

.bigText {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center; }

.extendedView {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 170px; }
  .extendedView:first-child--new-post::before, .extendedView:first-child--new-post-editable::before {
    position: absolute;
    content: 'N';
    text-align: center;
    font-size: 13px;
    bottom: 1px;
    left: 15px;
    width: 30px;
    height: 20px;
    z-index: 10;
    border-radius: 5px;
    border: 1px solid #000; }
  .extendedView--identifier {
    font-size: 10px;
    font-weight: 600; }
  .extendedView--btn {
    margin-top: 15px;
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
    border-radius: 7px;
    height: 30px;
    width: 135px; }
    .extendedView--btn span {
      margin-right: 10px; }
    .extendedView--btn svg {
      fill: #0080ff;
      width: 30px;
      height: 15px;
      margin-left: -3px; }
    .extendedView--btn:hover {
      background-color: #eae9e9; }
  .extendedView--bhp {
    border: 2px solid #0080ff; }
    .extendedView--bhp svg {
      fill: #0080ff; }
  .extendedView--energy {
    border: 2px solid #ffaa00; }
    .extendedView--energy svg {
      fill: #ffaa00; }
  .extendedView--environment {
    border: 2px solid #00d154; }
    .extendedView--environment svg {
      fill: #32c832; }

.rating {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .rating__container {
    border-radius: 15px;
    background-color: #efefef;
    height: 30px;
    display: flex;
    align-items: center;
    padding-right: 15px;
    font-weight: 600; }
    .rating__container img {
      margin: 0 15px; }
  .rating--files {
    width: 14px;
    margin-right: auto;
    margin-left: 10px; }
  .rating--stars {
    background-color: #efefef;
    border-radius: 15px;
    padding: 6px 18px;
    display: inline-block; }
    .rating--stars span {
      cursor: pointer;
      font-size: 20px; }
    .rating--stars--red span.active-hover,
    .rating--stars--red span.active-rating {
      color: #ff5454; }
    .rating--stars--orange span.active-hover,
    .rating--stars--orange span.active-rating {
      color: #ffaa00; }
    .rating--stars--green span.active-hover,
    .rating--stars--green span.active-rating {
      color: #00d154; }
  .rating--document {
    display: flex;
    margin-left: 5px; }
  .rating--alerts {
    margin-left: 30px;
    display: flex;
    align-items: center; }
    .rating--alerts__single {
      width: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #efefef;
      border-radius: 15px; }
      .rating--alerts__single svg {
        display: inline-block; }
  .rating--i {
    margin: 0 5px; }

.logins-amount {
  font-weight: 700;
  height: 27px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center; }
  .logins-amount svg {
    margin: 1px 14px 0 0; }
  .logins-amount--expired-date {
    color: #c9c9c9; }
    .logins-amount--expired-date svg {
      fill: #c9c9c9; }

.edit--cell {
  text-align: center;
  display: inline-block;
  cursor: pointer; }

.table--actions {
  position: relative; }

.detailedHeader__star {
  color: #0080ff;
  font-size: 16px;
  margin-right: 2px; }

.uppercase {
  text-transform: uppercase; }

.react-select__control {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 3px !important;
  min-height: 30px !important;
  margin-left: 0px; }
  .react-select__control--is-focused {
    box-shadow: none !important; }

.react-select__value-container {
  padding: 1px 8px !important; }

.react-select__indicator {
  padding: 1px !important; }

.react-select__indicator-separator {
  display: none; }

.react-select__clear-indicator {
  padding: 4px !important; }

.react-select__menu {
  z-index: 1000 !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  text-align: left; }

.multi-filter.react-select__control {
  overflow: auto;
  max-height: 50px; }

.rt-thead .rt-resizable-header-content {
  text-transform: uppercase;
  text-align: center; }

.ReactTable .rt-td {
  white-space: pre-line; }

.arrow {
  position: absolute;
  top: 50%;
  z-index: 105;
  background-color: #c9c9c9;
  height: 60px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 0.3; }
  .arrow:hover {
    opacity: 0.9;
    cursor: pointer; }
  .arrow .svg {
    width: 25px;
    height: 20px; }
  .arrow__right {
    right: 0;
    transform: rotate(180deg); }

.scrollable .rt-table {
  overflow: auto !important; }

.cell--visibility .btn {
  color: inherit; }

.table__noData {
  margin-top: 40px;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  color: #707070; }

.badge-wrapper {
  display: flex; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

input[type='range'] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */ }

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; }

input[type='range']:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */ }

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent; }

html {
  scroll-behavior: smooth; }

body {
  line-height: 1.5;
  font-size: 16px; }

.btn {
  background: none;
  outline: none;
  border: 0;
  border-radius: 5px;
  display: block;
  font-weight: 400;
  font-size: 17px;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  text-align: center;
  cursor: pointer; }
  .btn--default {
    height: 48px;
    line-height: 48px;
    background-color: #212748;
    font-weight: black;
    color: #fff;
    border-radius: 3px;
    width: 100%;
    text-transform: uppercase;
    font-size: 13px; }
    .btn--default:hover {
      background-color: #212748cb; }
  .btn--extendTextManage {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    background-color: #efefef;
    font-weight: black;
    color: #000;
    border-radius: 3px;
    display: inline-block;
    margin-left: 20px;
    font-size: 13px; }
  .btn--extendActive {
    background-color: #a8a8a8;
    color: #fff; }
  .btn--login {
    font-size: 17px; }
  .btn--navigation {
    height: 37px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 13px;
    margin: 0;
    line-height: 37px;
    padding: 0 5px;
    box-sizing: content-box;
    background-color: unset;
    color: #222d5c;
    font-weight: bold; }
    .btn--navigation span {
      margin-right: 7px; }
    .btn--navigation:hover {
      font-weight: 900; }
  .btn--center {
    margin-left: auto;
    margin-right: auto; }
  .btn--delete {
    background-color: #e80000;
    color: #fff; }
    .btn--delete:hover {
      background-color: #d80202; }
  .btn--disabled {
    color: #e4e4e4;
    cursor: not-allowed; }
    .btn--disabled:hover {
      background-color: #fff;
      box-shadow: 0px 1px 5px rgba(156, 156, 156, 0.32); }
  .btn--blank {
    padding: 0; }
  .btn--edit-client {
    background-color: #f0f0f0;
    border-radius: 5px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 27px;
    width: 85px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #4d4d4d; }
    .btn--edit-client:hover {
      background-color: #e9e9e9; }
  .btn--status-expired, .btn--status-active, .btn--expires-month {
    background-color: #f0f0f0;
    border-radius: 5px;
    height: 27px;
    width: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; }
  .btn--status-active, .btn--expires-month {
    width: 30px; }
  .btn--status-extend {
    background-color: #9e9e9e;
    color: #fff;
    border-radius: 5px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 300;
    width: 90px;
    display: flex;
    justify-content: space-evenly;
    align-items: center; }
    .btn--status-extend:hover {
      background-color: #979797; }
  .btn--expires-month {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: space-evenly;
    padding: 0 5px; }
    .btn--expires-month img {
      margin-right: 8px; }

.btn-action {
  background: none;
  outline: none;
  border: 0;
  border-radius: 6px;
  display: block;
  background-color: #f3f3f3;
  width: 25px;
  height: 25px;
  float: left;
  margin-right: 8px;
  cursor: pointer; }
  .btn-action--reply {
    background-image: url(/static/media/reply-icon.7d8287ea.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--reply:hover {
      background-image: url(/static/media/reply-hover-icon.67f5c0c6.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--date {
    background-image: url(/static/media/date-icon.c8c3cda7.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--date:hover {
      background-image: url(/static/media/date-hover-icon.e192e5ba.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--cancel {
    background-image: url(/static/media/cancel-icon.e8205662.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--cancel:hover {
      background-image: url(/static/media/cancel-hover-icon.63bd881b.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #ffaa00; }
  .btn-action--edit {
    background-image: url(/static/media/edit-icon.bb029e46.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--edit:hover {
      background-image: url(/static/media/edit-hover-icon.cb066285.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--search {
    background-image: url(/static/media/search-icon.06dc9219.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--search:hover {
      background-image: url(/static/media/search-hover-icon.083af10e.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--open {
    background-image: url(/static/media/open-icon.edb156ca.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--open:hover {
      background-image: url(/static/media/open-hover-icon.8cd5ac02.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--favorite {
    background-image: url(/static/media/favorite-icon.567170f4.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--favorite:hover {
      background-image: url(/static/media/favorite-hover-icon.2949e1cb.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: 15px;
  caret-color: #fff;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  background-color: #f3f3f3; }

:-internal-autofill-previewed {
  font-size: 15px !important; }

.Toastify__close-button {
  color: #717171 !important; }

.Toastify__toast {
  color: #444343;
  font-family: "Lato", sans-serif;
  text-align: center; }
  @media (min-width: 481px) {
    .Toastify__toast {
      border-radius: 5px !important; } }
  .Toastify__toast--error {
    background: #f3f3f3 !important;
    border-left: 8px solid #e74c3c; }
  .Toastify__toast--success {
    background: #f3f3f3 !important;
    border-left: 8px solid #07bc0c; }

.btn--green, .btn--orange, .btn--blue, .btn--navy-blue, .btn--navy-blue-stroke, .btn--red, .btn--cancel {
  background-color: #00d154;
  padding: 12px;
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: 700;
  border-radius: 5px;
  font-size: 17px;
  line-height: 22px;
  font-family: "Montserrat", sans-serif; }
  @media (max-width: 1665px) {
    .btn--green, .btn--orange, .btn--blue, .btn--navy-blue, .btn--navy-blue-stroke, .btn--red, .btn--cancel {
      font-size: 15px; } }

.btn--orange {
  background-color: #ffaa00; }

.btn--blue {
  background-color: #0080ff; }

.btn--navy-blue {
  background-color: #212748; }

.btn--navy-blue-stroke {
  border: 1px solid #212748;
  background-color: unset;
  color: #252D53;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  max-height: 40px;
  display: flex;
  align-items: center; }

.btn--red {
  background-color: #ff0000;
  min-width: 150px;
  font-weight: 700; }

.btn--cancel {
  background-color: transparent;
  color: #000;
  min-width: 150px;
  font-weight: 700; }
  .btn--cancel:hover {
    background-color: #f1f0f0; }

.btn--small {
  padding: 4px 23px;
  width: auto;
  font-family: "Open Sans", sans-serif; }

.btn--xs {
  padding: 4px 15px; }

.btn.disabled {
  cursor: not-allowed;
  opacity: 0.6; }

.btn--transparent {
  font-family: "Montserrat", sans-serif;
  padding: 12px; }

.btn--blue {
  background-color: #0080ff;
  color: #fff; }

.btn--loader {
  margin-right: 5px; }
  .btn--loader__inner {
    width: 17px;
    height: 17px;
    border: 2px solid #fff;
    border-radius: 50%;
    border-bottom-color: transparent;
    -webkit-animation: spinx 1s infinite linear both;
            animation: spinx 1s infinite linear both; }

.btn.is-loading {
  padding-left: 22px;
  display: flex;
  align-items: center; }

.btn--bordered {
  border: 1px solid #efefef; }
  .btn--bordered:hover {
    background-color: #f1f1f1; }

.font-big, .dashboardDictionary__submenu-item.active, .dashboardUsers__submenu-item.active, .learnMore__ctaDescription, .manageUser__companyName, .associatedUsersHeader__title, .areaManagement__header--title h1, .compendiumSingle__header--title h1, .changelog__title, .settings__title {
  font-size: 30px;
  line-height: 37px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif; }

.font-lg, .bigText, .compendiumsMobile .compendiumLetters__single.compendiumLetters__single--active {
  font-size: 21px;
  line-height: 25px; }

.font-sm, .btn--small, .associateUserRow .form__field input, .badge, .tableSelect__value, .tableSelect__options, .areaManagement__header--title h3, .compendiumElement--term, .compendiumPreview__term, .compendiumSingle__header--title h3, .infoBox__title, .infoBox__badge, .infoBox__content, .ratingBox__title, .instutitionElement,
.normElement {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600; }

.font-xs, .extendText--btn, .extendedView--btn, .btn--xs, .compendiumElement--department, .compendiumsMobile .compendiumLetters__single, .compendiumPreview__department, .compendiumPreview__extendedView, .infoBox--mobile .infoBox__content, .ratingBox__note-title, .ratingBox__content, .instutitionElement__block .label,
.normElement__block .label {
  font-size: 12px;
  line-height: 15px; }

.font-md, .areaManagement__header--backButton button, .compendiumPreview__goBack span, .compendiumSingle__header--backButton button, .ratingBox__risk, .hiddenRowsToggle, .extendTextToggle {
  font-size: 17px;
  line-height: 20px; }

.family-m, .compendiumPreview__term, .compendiumPreview__department {
  font-family: "Montserrat", sans-serif; }

.bold-500, .compendiumPreview__department, .infoBox__content, .instutitionElement__block .value,
.instutitionElement__block .extendText,
.normElement__block .value,
.normElement__block .extendText {
  font-weight: 500; }

.bold-600, .btn--xs {
  font-weight: 600; }

.bold-700, .areaManagement__header--backButton button, .areaManagement__header--title h3, .compendiumPreview__term, .compendiumPreview__extendedView, .compendiumPreview__goBack span, .compendiumSingle__header--backButton button, .compendiumSingle__header--title h3, .infoBox__content--bolded, .instutitionElement__name,
.normElement__name, .instutitionElement__block .label,
.normElement__block .label {
  font-weight: 700; }

@-webkit-keyframes spinx {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes spinx {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.tooltip > .tooltip-inner {
  background-color: #fff; }

.tooltip {
  font-weight: 500 !important;
  border-radius: 10px !important;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  background-color: #fff !important;
  color: #000 !important;
  padding: 5px 10px !important;
  opacity: 0.9 !important;
  max-width: 250px; }
  .tooltip--5 {
    border: 2px solid grey !important; }
  .tooltip--4 {
    border: 2px solid #0080ff !important; }
  .tooltip--3 {
    border: 2px solid #70ad47 !important; }
  .tooltip--2 {
    border: 2px solid #ffc000 !important; }
  .tooltip--1 {
    border: 2px solid #ff4f00 !important; }

.tooltip.place-left:after {
  border-left-color: transparent !important;
  border-left-width: 8px !important; }

.tooltip.place-right:after {
  border-right-color: #fff !important;
  border-right-width: 6px !important; }

.tooltip > .tooltip-arrow {
  background-color: #fff; }

.pdf-file {
  display: flex;
  margin: 5px 0; }
  .pdf-file__item {
    border: 1px solid #e2e2e2;
    padding: 8px 10px;
    display: flex;
    align-items: center; }
  .pdf-file--delete {
    cursor: pointer; }
  .pdf-file a {
    text-decoration: none;
    margin: 0; }
  .pdf-file svg {
    margin-left: 10px; }

.select-error {
  border: 1px solid red; }

.skeleton-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  margin-bottom: 20px; }
  .skeleton-container span {
    display: flex;
    width: 100%; }

.react-loading-skeleton {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 6px;
  background-image: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%, #f0f0f0 100%);
  background-size: 800px 104px;
  /* Szerokość i wysokość animacji */
  position: relative;
  overflow: hidden;
  -webkit-animation: shimmer 1.5s infinite linear;
          animation: shimmer 1.5s infinite linear; }

@-webkit-keyframes shimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes shimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative; }

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px; }
  .home-page h1 {
    font-size: 20px; }
  .home-page button {
    width: 200px;
    font-size: 15px;
    margin-top: 25px; }

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8; }
  .login__container {
    font-family: "Lato", sans-serif;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column; }
  .login__header {
    height: 320px;
    position: relative; }
    @media (min-width: 756px) {
      .login__header {
        height: 390px; } }
    @media (max-width: 1665px) {
      .login__header {
        height: 260px; } }
  .login__logo {
    position: absolute;
    margin-top: 41px;
    margin-left: 17px; }
    @media (min-width: 756px) {
      .login__logo {
        margin-left: 45px; } }
    .login__logo img {
      width: 44px;
      height: 45px; }
  .login__logo-entire {
    position: absolute;
    bottom: 00px;
    width: 100%;
    text-align: center;
    display: block; }
    .login__logo-entire img {
      width: 180px;
      height: 59px; }
      @media (max-width: 1665px) {
        .login__logo-entire img {
          width: 180px;
          height: 49px; } }
    .login__logo-entire h1 {
      margin-top: 30px;
      text-transform: uppercase;
      font-size: 17px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.47); }
      @media (min-width: 756px) {
        .login__logo-entire h1 {
          font-size: 15px; } }
  .login__form-container {
    width: 100%;
    padding: 10px 0 0 0;
    display: flex;
    justify-content: center; }
  .login__form {
    width: 310px; }
    .login__form .form__field input {
      height: 44px;
      padding: 5px 10px;
      border-radius: 2px; }
    .login__form .btn {
      margin-top: 30px; }
    @media (max-width: 1665px) {
      .login__form {
        width: 250px; } }
  .login__forget-password {
    float: right;
    margin: -10px 0 50px 0;
    font-size: 15px;
    font-weight: 400;
    color: #222d5c; }

.preloader {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .preloader__logo {
    width: 180px; }
  .preloader__container {
    position: relative;
    width: 60px;
    height: 80px; }
    .preloader__container--knowledge {
      top: 100px; }
    .preloader__container.preloader--inner {
      margin: 0 auto; }
  .preloader__container-inner {
    height: 50px;
    width: 50px;
    -webkit-animation: spin 1.4s infinite linear both;
            animation: spin 1.4s infinite linear both; }
  .preloader__container-inner--lg {
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s infinite linear both;
            animation: spin 1s infinite linear both;
    -webkit-animation-delay: 0.4;
            animation-delay: 0.4; }
  .preloader__container-inner--md {
    width: 30px;
    height: 30px;
    -webkit-animation: spin 0.9s infinite linear both;
            animation: spin 0.9s infinite linear both;
    -webkit-animation-delay: 0.8;
            animation-delay: 0.8; }
  .preloader__container-inner--sm {
    width: 20px;
    height: 20px;
    -webkit-animation: spin 0.6s infinite linear both;
            animation: spin 0.6s infinite linear both;
    -webkit-animation-delay: 1.2;
            animation-delay: 1.2; }
  .preloader__container-inner, .preloader__container-inner--sm, .preloader__container-inner--xs, .preloader__container-inner--md, .preloader__container-inner--lg {
    position: absolute;
    top: 60%;
    left: 50%;
    border-radius: 50%;
    border-left: 3px solid #212748;
    border-top: 3px solid transparent;
    border-right: 3px solid #212748;
    border-bottom: 3px solid transparent; }
    .preloader__container-inner--sm, .preloader__container-inner--sm--sm, .preloader__container-inner--xs--sm, .preloader__container-inner--md--sm, .preloader__container-inner--lg--sm {
      height: 30px;
      width: 30px;
      border-width: 2px; }
  .preloader--sm {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6); }

@-webkit-keyframes spinBackwards {
  from {
    transform: translate(-50%, -50%) rotate(360deg); }
  to {
    transform: translate(-50%, -50%) rotate(0deg); } }

@keyframes spinBackwards {
  from {
    transform: translate(-50%, -50%) rotate(360deg); }
  to {
    transform: translate(-50%, -50%) rotate(0deg); } }

@-webkit-keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg); }
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg); }
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

.dashboard {
  height: 100vh;
  display: flex;
  font-family: "Roboto", sans-serif;
  overflow: hidden; }
  .dashboard__menu {
    width: 115px;
    background-color: #ababab;
    min-height: 100vh;
    position: relative; }
    @media (max-width: 1280px) {
      .dashboard__menu {
        display: none; } }
    @media (max-width: 1665px) {
      .dashboard__menu {
        width: 86px; } }
    .dashboard__menu p {
      margin: 14px 0 0 0;
      font-size: 10px;
      font-weight: 700;
      text-align: center; }
      @media (max-width: 1665px) {
        .dashboard__menu p {
          font-size: 9px; } }
  .dashboard__role-name {
    background-color: black;
    height: 38px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 12px; }
    @media (max-width: 1665px) {
      .dashboard__role-name {
        font-size: 10px; } }
    .dashboard__role-name img {
      margin: 0px 6px; }
    .dashboard__role-name:first-of-type {
      margin-top: 81px; }
      @media (max-width: 1665px) {
        .dashboard__role-name:first-of-type {
          margin-top: 40px; } }
  .dashboard__socials {
    position: absolute;
    bottom: 140px;
    width: 100%;
    display: flex;
    justify-content: space-evenly; }
  .dashboard__socials-icon {
    width: 48px;
    height: 48px;
    display: flex;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    background-color: #989696; }
    @media (max-width: 1665px) {
      .dashboard__socials-icon {
        width: 40px;
        height: 40px; } }
    .dashboard__socials-icon:hover {
      background-color: #888686;
      cursor: pointer; }
  .dashboard__logout {
    position: absolute;
    width: 100%;
    bottom: 70px;
    padding: 10px 0;
    font-size: 14px;
    color: #000;
    background-color: #fff;
    font-weight: 500;
    text-align: center; }
    @media (max-width: 1665px) {
      .dashboard__logout {
        font-size: 12px; } }
    .dashboard__logout:hover {
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer; }
  .dashboard__content {
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto; }
    @media (min-width: 769px) {
      .dashboard__content {
        width: calc(100vw - 115px); } }
    @media (max-width: 1665px) {
      .dashboard__content {
        width: calc(100vw - 89px); } }
    @media (max-width: 1280px) {
      .dashboard__content {
        width: 100%; } }
  .dashboard__page {
    flex: 1 1;
    display: flex;
    flex-direction: column; }
  .dashboard__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #f6f6f6;
    max-width: 100%;
    height: 80px; }
    @media (max-width: 1665px) {
      .dashboard__header {
        height: 70px; } }
    @media (max-width: 1221px) {
      .dashboard__header {
        height: 80px; } }
    @media (max-width: 768px) {
      .dashboard__header {
        height: 70px; } }
    @media (max-width: 376px) {
      .dashboard__header {
        height: 70px; } }
    .dashboard__header-logo {
      padding-right: 15px; }
      .dashboard__header-logo img {
        max-width: unset;
        max-height: unset;
        width: 180px;
        height: 48px; }
    .dashboard__header-items {
      display: flex; }
    .dashboard__header-item {
      height: 100%;
      padding: 18px 15px;
      width: 194px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      text-decoration: none;
      color: #000; }
      .dashboard__header-item:after {
        content: '';
        position: absolute;
        height: 60px;
        width: 1px;
        background-color: #707070;
        right: 0px;
        top: 50%;
        transform: translateY(-50%); }
      .dashboard__header-item p {
        margin-left: 15px;
        font-size: 15px;
        line-height: 18px;
        font-weight: bold; }
        @media (max-width: 1665px) {
          .dashboard__header-item p {
            font-size: 14px; } }
      .dashboard__header-item:hover, .dashboard__header-item--active {
        background-color: #fff; }
        .dashboard__header-item:hover.dashboard__header-item--green, .dashboard__header-item--active.dashboard__header-item--green {
          border-bottom-color: #00d154; }
        .dashboard__header-item:hover.dashboard__header-item--blue, .dashboard__header-item--active.dashboard__header-item--blue {
          border-bottom-color: #0080ff; }
        .dashboard__header-item:hover.dashboard__header-item--yellow, .dashboard__header-item--active.dashboard__header-item--yellow {
          border-bottom-color: #e8e10d; }
        .dashboard__header-item:hover.dashboard__header-item--gray, .dashboard__header-item--active.dashboard__header-item--gray {
          border-bottom-color: #ababab; }
        .dashboard__header-item:hover.dashboard__header-item--orange, .dashboard__header-item--active.dashboard__header-item--orange {
          border-bottom-color: #ffaa00; }
      .dashboard__header-item--forbidden p,
      .dashboard__header-item--forbidden img {
        opacity: 0.31; }
      .dashboard__header-item--forbidden:hover {
        background-color: transparent; }
      .dashboard__header-item .forbidden {
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        font: 600 9px/14px "Open Sans", sans-serif;
        padding: 3px 11px;
        border-radius: 5px;
        background-color: #ff5454;
        color: #fff;
        width: 81px; }
      @media (max-width: 1483px) {
        .dashboard__header-item {
          width: 174px; } }
      @media (max-width: 1665px) {
        .dashboard__header-item {
          width: 160px; }
          .dashboard__header-item:last-child {
            width: 190px; } }
      @media (max-width: 768px) {
        .dashboard__header-item {
          width: 144px;
          padding: 15px 10px; }
          .dashboard__header-item p {
            font-size: 11px;
            line-height: 12px;
            margin-left: 10px; } }
      @media (max-width: 375px) {
        .dashboard__header-item {
          padding-top: 8px;
          padding-bottom: 8px; } }
    .dashboard__header-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: right;
      min-width: 140px; }
      @media (max-width: 1477px) {
        .dashboard__header-logo {
          min-width: 135px; } }
  .dashboard__header-edit-icon {
    position: absolute;
    right: 10px;
    top: 25px;
    cursor: pointer; }

img {
  max-height: 100%;
  max-width: 100%; }

.dictionaryTable--tablet {
  margin: 0 15px; }

.dictionaryTable .expired-date {
  color: #fd3617;
  font-weight: bold; }

.dictionaryTable .status-container {
  display: flex; }

.dictionary__element {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px; }
  .dictionary__element:first-of-type .dictionary__element--letter {
    padding-top: 0; }
  .dictionary__element--letter, .dictionary__element--header, .dictionary__element--content {
    padding: 0 25px; }
  .dictionary__element--letter {
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 20px;
    padding-top: 15px; }
  .dictionary__element--title {
    font-size: 12px;
    line-height: 15px;
    font-weight: 700; }
  .dictionary__element--icon {
    width: 13px; }
  .dictionary__element--content {
    display: flex;
    font-size: 12px;
    line-height: 15px; }
    .dictionary__element--content-r, .dictionary__element--content-l {
      width: 50%; }
    .dictionary__element--content-l {
      padding-right: 17px;
      font-weight: 600; }
    .dictionary__element--content-r {
      padding-left: 17px; }
  .dictionary__element--header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 13px;
    border-bottom: 2px solid #f6f6f6;
    border-radius: 2px; }
    .dictionary__element--header-l {
      display: flex;
      justify-content: space-between;
      padding-right: 17px; }
    .dictionary__element--header-l, .dictionary__element--header-r {
      width: 50%; }
    .dictionary__element--header-r {
      padding-left: 17px;
      display: flex; }
      .dictionary__element--header-r .dictionary__element--icon {
        margin-left: auto; }
  .dictionary__element--phone {
    margin-bottom: 0;
    padding-top: 20px; }
    .dictionary__element--phone .dictionary__element--header {
      margin-bottom: 0;
      align-items: center; }
      .dictionary__element--phone .dictionary__element--header-r, .dictionary__element--phone .dictionary__element--header-l {
        width: auto; }
    .dictionary__element--phone .dictionary__element--icon {
      margin-left: 15px;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border: 1px solid transparent;
      transform: rotate(0deg);
      position: relative; }
      .dictionary__element--phone .dictionary__element--icon__inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 13px; }
      .dictionary__element--phone .dictionary__element--icon.active-ico {
        background-color: #fff; }
        .dictionary__element--phone .dictionary__element--icon.active-ico .dictionary__element--icon__inner {
          transform: rotate(-20deg) translate(-50%, -50%);
          transform-origin: 0 0; }
      .dictionary__element--phone .dictionary__element--icon.bordered {
        border-color: #efefef; }
    .dictionary__element--phone .dictionary__element--content {
      display: block;
      padding-bottom: 10px;
      font-weight: 700; }
    .dictionary__element--phone .dictionary__element--letter {
      background-color: #fff; }
    .dictionary__element--phone .dictionary__element--date {
      margin-top: 10px;
      font-size: 10px;
      line-height: 13px;
      font-weight: 600; }
    .dictionary__element--phone.active {
      background-color: #f6f6f6; }

.notifications {
  padding: 0 150px;
  margin-top: 39px; }
  .notifications__settings {
    display: flex; }
    .notifications__settings .element {
      color: #707070;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center; }
      .notifications__settings .element:first-child {
        margin-right: 80px; }
    .notifications__settings .extendTextToggle {
      margin-right: 0; }
    .notifications__settings .extendTextToggle__switch--active {
      background-color: #252D53; }
    .notifications__settings .extendTextToggle__switch--active .extendTextToggle__switch_inner {
      margin-left: 19px;
      width: 17px;
      height: 16px;
      margin-top: 1px; }
  .notifications .notification-popup__day {
    padding-left: unset; }

.svg {
  display: block; }
  .svg--notification-dot circle {
    fill: #FF002E; }
  .svg--file .a {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round; }
    .active-ico .svg--file .a {
      stroke: #0080ff; }
  .svg--paragraph {
    width: 8px;
    margin: 0 auto; }
    .svg--paragraph .a {
      font-size: 17px;
      font-family: TrebuchetMS, Trebuchet MS; }
      .active-ico .svg--paragraph .a {
        fill: #0080ff; }
  .svg--alert-x {
    width: 10px; }
    .svg--alert-x .a {
      fill: #fd3617; }
  .svg--file {
    min-width: 11px; }
  .svg--star .b {
    -webkit-filter: url(#a);
            filter: url(#a); }
  .svg--chevron .a {
    fill: none;
    stroke: #000;
    stroke-width: 2px; }
  .svg--magnifier .a {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px; }

.grey-face .a {
  fill: none;
  stroke: grey;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px; }

.navigation {
  background-color: #f3efec; }
  .navigation__menu {
    height: 104px;
    display: flex;
    transition: height 400ms ease;
    justify-content: flex-end;
    background-color: #f3efec;
    position: fixed;
    width: 100%;
    z-index: 101; }
    @media (min-width: 1200px) {
      .navigation__menu {
        position: relative;
        width: 380px;
        margin-right: 17px; } }
    .navigation__menu--desktop {
      display: none;
      font-family: "Lato", sans-serif;
      font-size: 17px;
      height: 50px;
      cursor: pointer; }
      .navigation__menu--desktop a {
        color: #222;
        text-decoration: none;
        margin: 0 5px; }
      .navigation__menu--desktop li {
        color: #222;
        padding: 15px;
        height: 50px; }
        .navigation__menu--desktop li:hover {
          background-color: #fbfbfb;
          box-shadow: 0px 1px 5px rgba(156, 156, 156, 0.5);
          border-radius: 5px; }
      @media (min-width: 1200px) {
        .navigation__menu--desktop {
          display: flex; } }
    .navigation__menu--mobile {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100%;
      font-family: "Lato", sans-serif;
      font-size: 17px;
      padding: 120px 20px 20px 20px;
      cursor: pointer; }
      @media (min-width: 1200px) {
        .navigation__menu--mobile {
          padding: 120px 30px 20px 30px; } }
      .navigation__menu--mobile a {
        text-decoration: none;
        width: 100%;
        display: flex;
        align-items: center; }
      .navigation__menu--mobile .logout {
        color: rgba(0, 0, 0, 0.3);
        padding: 15px;
        height: 50px;
        display: flex;
        align-items: center;
        margin-top: 10px; }
        .navigation__menu--mobile .logout img {
          margin-left: 10px; }
        @media (min-width: 1200px) {
          .navigation__menu--mobile .logout {
            display: none; } }
      .navigation__menu--mobile .list {
        position: relative;
        width: 100%;
        border-radius: 6px;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        display: flex;
        color: #222d5c; }
        .navigation__menu--mobile .list:hover {
          font-weight: 900; }
          .navigation__menu--mobile .list:hover:before {
            content: '';
            position: absolute;
            width: 20px;
            right: 0px;
            height: 29px;
            background-image: url(/static/media/active-menu-icon.61040c2d.svg);
            background-repeat: no-repeat;
            background-position: center; }
        @media (min-width: 1200px) {
          .navigation__menu--mobile .list {
            background-color: unset;
            font-weight: 400; } }
        .navigation__menu--mobile .list__icon {
          position: relative;
          margin-right: 10px;
          transform: translateY(-50%);
          top: 50%; }
        .navigation__menu--mobile .list__counter {
          color: #222d5c;
          width: 50px; }
    .navigation__menu--expanded {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden; }
      @media (min-width: 1200px) {
        .navigation__menu--expanded {
          height: calc(100vh - 34px); } }
    .navigation__menu--selected-nav-item .list {
      font-weight: 900; }
      .navigation__menu--selected-nav-item .list:before {
        content: '';
        position: absolute;
        width: 20px;
        right: 0px;
        height: 29px;
        background-image: url(/static/media/active-menu-icon.61040c2d.svg);
        background-repeat: no-repeat;
        background-position: center; }
  .navigation__menu-hamburger {
    --color: #222d5c;
    width: 36px;
    height: 36px;
    padding: 0;
    right: 32px;
    top: 32px;
    outline: none;
    position: absolute;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-appearence: none;
    -webkit-tap-highlight-color: transparent; }
    .navigation__menu-hamburger svg {
      width: 84px;
      height: 64px;
      top: -6px;
      left: -14px;
      stroke: var(--color);
      stroke-width: 1px;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
      display: block;
      position: absolute; }
      .navigation__menu-hamburger svg path {
        transition: stroke-dasharray 0.85s ease 0s, stroke-dashoffset 0.85s ease 0s;
        transition: stroke-dasharray var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s);
        stroke-dasharray: 26px 100px;
        stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
        stroke-dashoffset: 126px;
        stroke-dashoffset: var(--offset, 126px);
        transform: translateZ(0); }
        .navigation__menu-hamburger svg path:nth-child(2) {
          --duration: 0.7s;
          --easing: ease-in;
          --offset: 100px;
          --array-2: 74px; }
        .navigation__menu-hamburger svg path:nth-child(3) {
          --offset: 133px;
          --array-2: 107px; }
    .navigation__menu-hamburger--active svg path {
      --offset: 57px; }
      .navigation__menu-hamburger--active svg path:nth-child(1), .navigation__menu-hamburger--active svg path:nth-child(3) {
        --delay: 0.15s;
        --easing: cubic-bezier(0.2, 0.4, 0.2, 1.1); }
      .navigation__menu-hamburger--active svg path:nth-child(2) {
        --duration: 0.4s;
        --offset: 2px;
        --array-1: 1px; }
      .navigation__menu-hamburger--active svg path:nth-child(3) {
        --offset: 58px; }
    @media (min-width: 1200px) {
      .navigation__menu-hamburger {
        display: none; } }
  .navigation__logout {
    right: 25px;
    top: 32px;
    outline: none;
    position: absolute;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-appearence: none;
    -webkit-tap-highlight-color: transparent;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 3px; }
    .navigation__logout img {
      margin-left: 10px;
      width: 17px;
      height: 15px; }
    @media screen and (max-width: 1200px) {
      .navigation__logout {
        display: none; } }
  .navigation__menu-logo {
    position: absolute;
    top: 39px;
    left: 31px;
    display: flex;
    align-items: center; }
    .navigation__menu-logo img {
      width: 40px;
      height: 41px; }
      .navigation__menu-logo img:hover {
        cursor: pointer; }
    .navigation__menu-logo span {
      margin-left: 18px;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      font-size: 12px;
      color: #222d5c; }
  .navigation__container {
    padding-top: 104px; }
  .navigation__list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid rgba(34, 45, 92, 0.19);
    margin-bottom: 10px; }
    .navigation__list-title .list-title {
      border-radius: 6px;
      display: flex;
      height: 37px;
      line-height: 37px;
      font-size: 13px;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      letter-spacing: 1.3px;
      text-transform: uppercase;
      color: rgba(34, 45, 92, 0.28); }
      .navigation__list-title .list-title__counter {
        color: #222d5c;
        width: 50px; }
  .navigation__form {
    width: 100%;
    margin-bottom: 35px; }
    @media (min-width: 1200px) {
      .navigation__form {
        margin-bottom: 35px; } }
    .navigation__form .btn {
      margin-top: 15px; }

.reminders-list {
  position: absolute;
  width: 100%;
  height: 100%; }
  .reminders-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px; }
  .reminders-list__title h2 {
    font-size: 17px;
    color: #222d5c;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    text-transform: uppercase; }
  .reminders-list__table {
    overflow-x: auto; }
    @media screen and (max-width: 1200px) {
      .reminders-list__table table {
        table-layout: unset; }
        .reminders-list__table table tbody .actions {
          display: flex;
          flex-direction: row;
          align-items: center; }
        .reminders-list__table table tbody tr {
          height: unset; }
        .reminders-list__table table tbody td {
          vertical-align: middle;
          white-space: nowrap;
          padding: 2px 15px 10px 15px; } }

.add-reminder {
  position: relative;
  width: 100%;
  padding: 30px;
  background-color: #f3efec;
  margin: 0 auto; }
  @media (min-width: 501px) {
    .add-reminder {
      width: 380px; } }
  @media (min-width: 1200px) {
    .add-reminder {
      height: calc(100vh - 34px);
      width: 380px;
      margin: unset; } }
  .add-reminder__title {
    display: flex;
    align-items: center; }
    .add-reminder__title img {
      margin-top: 8px;
      margin-right: 15px;
      cursor: pointer; }
    .add-reminder__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .add-reminder__form-container {
    margin-top: 90px;
    border-top: 2px solid #222d5c;
    height: calc(100% - 107px); }
  .add-reminder__form {
    margin-top: 20px;
    position: relative;
    height: calc(100% - 17px); }
    .add-reminder__form .btn {
      margin-top: 60px; }
      @media (min-width: 1200px) {
        .add-reminder__form .btn {
          position: absolute;
          bottom: 10px; } }
    .add-reminder__form .form__field input,
    .add-reminder__form .form__field textarea {
      border-radius: 5px; }

.add-customer {
  position: relative;
  width: 100%;
  padding: 30px;
  background-color: #f3efec;
  margin: 0 auto; }
  @media (min-width: 501px) {
    .add-customer {
      width: 380px; } }
  @media (min-width: 1200px) {
    .add-customer {
      height: calc(100vh - 34px);
      width: 380px;
      margin: unset; } }
  .add-customer__title {
    display: flex;
    align-items: center; }
    .add-customer__title img {
      margin-top: 8px;
      margin-right: 15px;
      cursor: pointer; }
    .add-customer__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .add-customer__form-container {
    margin-top: 90px;
    border-top: 2px solid #222d5c;
    height: calc(100% - 107px); }
  .add-customer__form {
    margin-top: 20px;
    position: relative;
    height: calc(100% - 17px); }
    .add-customer__form .btn {
      margin-top: 60px; }
      @media (min-width: 1200px) {
        .add-customer__form .btn {
          position: absolute;
          bottom: 10px; } }
    .add-customer__form .form__field input,
    .add-customer__form .form__field textarea {
      border-radius: 5px; }

.customers-list {
  position: absolute;
  width: 100%;
  height: 100%; }
  .customers-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px; }
  .customers-list__title {
    display: flex;
    align-items: center; }
    .customers-list__title img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer; }
    .customers-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .customers-list__table {
    overflow-x: auto; }
    .customers-list__table table {
      table-layout: unset; }
      .customers-list__table table tbody .actions {
        display: flex;
        flex-direction: row;
        align-items: center; }
      .customers-list__table table tbody tr {
        height: unset; }
      .customers-list__table table tbody td {
        vertical-align: middle;
        white-space: nowrap;
        padding: 2px 15px 10px 15px; }

.favorite-customers-list {
  position: absolute;
  width: 100%;
  height: 100%; }
  .favorite-customers-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px; }
  .favorite-customers-list__title {
    display: flex;
    align-items: center; }
    .favorite-customers-list__title img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer; }
    .favorite-customers-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .favorite-customers-list__table {
    overflow-x: auto; }
    .favorite-customers-list__table table {
      table-layout: unset; }
      .favorite-customers-list__table table tbody .actions {
        display: flex;
        flex-direction: row;
        align-items: center; }
      .favorite-customers-list__table table tbody tr {
        height: unset; }
      .favorite-customers-list__table table tbody td {
        vertical-align: middle;
        white-space: nowrap;
        padding: 2px 15px 10px 15px; }

.time-trial-list {
  position: absolute;
  width: 100%;
  height: 100%; }
  .time-trial-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px;
    flex-direction: column; }
    @media (min-width: 601px) {
      .time-trial-list__header {
        flex-direction: row; } }
  .time-trial-list__title {
    text-align: left;
    width: 100%; }
    @media (min-width: 601px) {
      .time-trial-list__title {
        width: unset; } }
    .time-trial-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .time-trial-list__right {
    display: flex;
    align-items: center;
    width: 490px; }
    @media (min-width: 601px) {
      .time-trial-list__right {
        flex-direction: row; } }
  .time-trial-list__form-search {
    width: 890px;
    margin-right: 30px; }
    .time-trial-list__form-search .form__field input {
      padding: 5px 10px;
      height: 25px; }
    .time-trial-list__form-search .form__field input::-webkit-input-placeholder {
      color: rgba(46, 46, 46, 0.29); }
    .time-trial-list__form-search .form__field input:-ms-input-placeholder {
      color: rgba(46, 46, 46, 0.29); }
    .time-trial-list__form-search .form__field input::-ms-input-placeholder {
      color: rgba(46, 46, 46, 0.29); }
    .time-trial-list__form-search .form__field input::placeholder {
      color: rgba(46, 46, 46, 0.29); }
  .time-trial-list__table {
    overflow-x: auto; }
    @media screen and (max-width: 1200px) {
      .time-trial-list__table table {
        table-layout: unset; }
        .time-trial-list__table table tbody .actions {
          display: flex;
          flex-direction: row;
          align-items: center; }
        .time-trial-list__table table tbody tr {
          height: unset; }
        .time-trial-list__table table tbody td {
          vertical-align: middle;
          white-space: nowrap;
          padding: 2px 15px 10px 15px; } }

.add-time-trial {
  position: relative;
  width: 100%;
  padding: 30px;
  background-color: #f3efec;
  margin: 0 auto; }
  @media (min-width: 501px) {
    .add-time-trial {
      width: 380px; } }
  @media (min-width: 1200px) {
    .add-time-trial {
      height: calc(100vh - 34px);
      width: 380px;
      margin: unset; } }
  .add-time-trial__title {
    display: flex;
    align-items: center; }
    .add-time-trial__title img {
      margin-top: 8px;
      margin-right: 15px;
      cursor: pointer; }
    .add-time-trial__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .add-time-trial__form-container {
    margin-top: 90px;
    border-top: 2px solid #222d5c;
    height: calc(100% - 107px); }
  .add-time-trial__form {
    margin-top: 20px;
    position: relative;
    height: calc(100% - 17px); }
    .add-time-trial__form .btn {
      margin-top: 60px; }
      @media (min-width: 1200px) {
        .add-time-trial__form .btn {
          position: absolute;
          bottom: 10px; } }
    .add-time-trial__form .form__field input,
    .add-time-trial__form .form__field textarea {
      border-radius: 5px; }

.cases-list {
  height: 100%;
  display: flex; }
  .cases-list__summary {
    position: relative;
    width: 100%;
    padding: 25px;
    background-color: #f3efec;
    margin: 0 auto; }
    @media (min-width: 501px) {
      .cases-list__summary {
        width: 380px; } }
    @media (min-width: 1200px) {
      .cases-list__summary {
        height: calc(100vh - 34px);
        width: 380px;
        margin: unset; } }
    .cases-list__summary__title {
      display: flex;
      align-items: center; }
      .cases-list__summary__title img {
        margin-top: 8px;
        margin-right: 15px;
        cursor: pointer; }
      .cases-list__summary__title h2 {
        font-size: 17px;
        color: #222d5c;
        font-family: "Lato", sans-serif;
        font-weight: bold;
        text-transform: uppercase; }
    .cases-list__summary__form-container {
      margin-top: 90px;
      border-top: 2px solid #222d5c;
      height: calc(100% - 107px); }
    .cases-list__summary__form {
      margin-top: 20px;
      position: relative;
      height: calc(100% - 17px); }
      .cases-list__summary__form .btn {
        margin-top: 60px; }
        @media (min-width: 1200px) {
          .cases-list__summary__form .btn {
            position: absolute;
            bottom: 10px; } }
      .cases-list__summary__form .form__field input,
      .cases-list__summary__form .form__field textarea {
        border-radius: 5px; }
  .cases-list__short-summary {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    line-height: 20px;
    align-items: flex-start; }
    .cases-list__short-summary p {
      color: rgba(0, 0, 0, 0.4);
      font-size: 13px;
      font-weight: 400;
      font-family: "Lato", sans-serif; }
      .cases-list__short-summary p:first-of-type {
        line-height: 8px; }
    .cases-list__short-summary span {
      margin-left: 10px; }
    .cases-list__short-summary--in-progress {
      color: #ffaa00;
      font-size: 25px;
      font-weight: 900; }
    .cases-list__short-summary--done {
      color: #222d5c;
      font-size: 25px;
      font-weight: 900; }
  .cases-list__cases-status {
    display: flex;
    border-bottom: 2px solid rgba(34, 45, 92, 0.19);
    justify-content: flex-end;
    margin-bottom: 10px; }
    .cases-list__cases-status p {
      font-size: 12px;
      font-family: "Lato", sans-serif;
      font-weight: 600;
      margin-left: 12px;
      margin-bottom: 5px; }
    .cases-list__cases-status--in-progress {
      color: #ffaa00; }
    .cases-list__cases-status--all {
      color: black; }
    .cases-list__cases-status--done {
      color: rgba(0, 0, 0, 0.4); }
  .cases-list__cases-types {
    color: #222d5c;
    font-size: 13px;
    font-weight: 400;
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.4px; }
    .cases-list__cases-types ul li {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      position: relative; }
      .cases-list__cases-types ul li:hover {
        border-bottom: 1px solid rgba(34, 45, 92, 0.22);
        font-weight: 900;
        padding-left: 25px;
        cursor: pointer; }
        .cases-list__cases-types ul li:hover:before {
          content: '';
          position: absolute;
          width: 20px;
          left: 0px;
          top: 49%;
          transform: translateY(-50%);
          height: 40px;
          background-image: url(/static/media/active-menu-icon.61040c2d.svg);
          background-repeat: no-repeat;
          background-position: center; }
      .cases-list__cases-types ul li span {
        font-weight: 700; }
    .cases-list__cases-types--selected {
      border-bottom: 1px solid rgba(34, 45, 92, 0.22);
      font-weight: 900;
      padding-left: 25px;
      position: relative; }
      .cases-list__cases-types--selected:before {
        content: '';
        position: absolute;
        width: 20px;
        left: 0px;
        top: 49%;
        transform: translateY(-50%);
        height: 40px;
        background-image: url(/static/media/active-menu-icon.61040c2d.svg);
        background-repeat: no-repeat;
        background-position: center; }
    .cases-list__cases-types .container {
      display: flex; }
    .cases-list__cases-types--in-progress {
      color: #ffaa00; }
    .cases-list__cases-types--all {
      color: #222d5c; }
    .cases-list__cases-types--done {
      color: rgba(34, 45, 92, 0.38); }
    .cases-list__cases-types--in-progress, .cases-list__cases-types--all, .cases-list__cases-types--done {
      width: 50px;
      display: block;
      text-align: right; }
  .cases-list__container {
    width: calc(100% - 350px); }
  .cases-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px; }
  .cases-list__title {
    display: flex;
    align-items: center; }
    .cases-list__title img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer; }
    .cases-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .cases-list__table {
    overflow-x: auto; }
    .cases-list__table table {
      table-layout: unset; }
      .cases-list__table table tbody .actions {
        display: flex;
        flex-direction: row;
        align-items: center; }
      .cases-list__table table tbody tr {
        height: unset; }
      .cases-list__table table tbody td {
        vertical-align: middle;
        white-space: nowrap;
        padding: 2px 15px 10px 15px; }

.cases-list {
  height: 100%;
  display: flex; }
  .cases-list__summary {
    position: relative;
    width: 100%;
    padding: 25px;
    background-color: #f3efec;
    margin: 0 auto; }
    @media (min-width: 501px) {
      .cases-list__summary {
        width: 380px; } }
    @media (min-width: 1200px) {
      .cases-list__summary {
        height: calc(100vh - 34px);
        width: 380px;
        margin: unset; } }
    .cases-list__summary__title {
      display: flex;
      align-items: center; }
      .cases-list__summary__title img {
        margin-top: 8px;
        margin-right: 15px;
        cursor: pointer; }
      .cases-list__summary__title h2 {
        font-size: 17px;
        color: #222d5c;
        font-family: "Lato", sans-serif;
        font-weight: bold;
        text-transform: uppercase; }
    .cases-list__summary__form-container {
      margin-top: 90px;
      border-top: 2px solid #222d5c;
      height: calc(100% - 107px); }
    .cases-list__summary__form {
      margin-top: 20px;
      position: relative;
      height: calc(100% - 17px); }
      .cases-list__summary__form .btn {
        margin-top: 60px; }
        @media (min-width: 1200px) {
          .cases-list__summary__form .btn {
            position: absolute;
            bottom: 10px; } }
      .cases-list__summary__form .form__field input,
      .cases-list__summary__form .form__field textarea {
        border-radius: 5px; }
  .cases-list__short-summary {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    line-height: 20px;
    align-items: flex-start; }
    .cases-list__short-summary p {
      color: rgba(0, 0, 0, 0.4);
      font-size: 13px;
      font-weight: 400;
      font-family: "Lato", sans-serif; }
      .cases-list__short-summary p:first-of-type {
        line-height: 8px; }
    .cases-list__short-summary span {
      margin-left: 10px; }
    .cases-list__short-summary--in-progress {
      color: #ffaa00;
      font-size: 25px;
      font-weight: 900; }
    .cases-list__short-summary--done {
      color: #222d5c;
      font-size: 25px;
      font-weight: 900; }
  .cases-list__cases-status {
    display: flex;
    border-bottom: 2px solid rgba(34, 45, 92, 0.19);
    justify-content: flex-end;
    margin-bottom: 10px; }
    .cases-list__cases-status p {
      font-size: 12px;
      font-family: "Lato", sans-serif;
      font-weight: 600;
      margin-left: 12px;
      margin-bottom: 5px; }
    .cases-list__cases-status--in-progress {
      color: #ffaa00; }
    .cases-list__cases-status--all {
      color: black; }
    .cases-list__cases-status--done {
      color: rgba(0, 0, 0, 0.4); }
  .cases-list__cases-types {
    color: #222d5c;
    font-size: 13px;
    font-weight: 400;
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.4px; }
    .cases-list__cases-types ul li {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      position: relative; }
      .cases-list__cases-types ul li:hover {
        border-bottom: 1px solid rgba(34, 45, 92, 0.22);
        font-weight: 900;
        padding-left: 25px;
        cursor: pointer; }
        .cases-list__cases-types ul li:hover:before {
          content: '';
          position: absolute;
          width: 20px;
          left: 0px;
          top: 49%;
          transform: translateY(-50%);
          height: 40px;
          background-image: url(/static/media/active-menu-icon.61040c2d.svg);
          background-repeat: no-repeat;
          background-position: center; }
      .cases-list__cases-types ul li span {
        font-weight: 700; }
    .cases-list__cases-types--selected {
      border-bottom: 1px solid rgba(34, 45, 92, 0.22);
      font-weight: 900;
      padding-left: 25px;
      position: relative; }
      .cases-list__cases-types--selected:before {
        content: '';
        position: absolute;
        width: 20px;
        left: 0px;
        top: 49%;
        transform: translateY(-50%);
        height: 40px;
        background-image: url(/static/media/active-menu-icon.61040c2d.svg);
        background-repeat: no-repeat;
        background-position: center; }
    .cases-list__cases-types .container {
      display: flex; }
    .cases-list__cases-types--in-progress {
      color: #ffaa00; }
    .cases-list__cases-types--all {
      color: #222d5c; }
    .cases-list__cases-types--done {
      color: rgba(34, 45, 92, 0.38); }
    .cases-list__cases-types--in-progress, .cases-list__cases-types--all, .cases-list__cases-types--done {
      width: 50px;
      display: block;
      text-align: right; }
  .cases-list__container {
    width: calc(100% - 350px); }
  .cases-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px; }
  .cases-list__title {
    display: flex;
    align-items: center; }
    .cases-list__title img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer; }
    .cases-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .cases-list__table {
    overflow-x: auto; }
    .cases-list__table table {
      table-layout: unset; }
      .cases-list__table table tbody .actions {
        display: flex;
        flex-direction: row;
        align-items: center; }
      .cases-list__table table tbody tr {
        height: unset; }
      .cases-list__table table tbody td {
        vertical-align: middle;
        white-space: nowrap;
        padding: 2px 15px 10px 15px; }

@media (min-width: 376px) {
  .dashboardDictionary__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 15px 15px; } }

@media (max-width: 1215px) {
  .dashboardDictionary__header {
    flex-direction: column;
    align-items: start; } }

.dashboardDictionary__buttons {
  display: flex;
  align-items: center;
  padding-bottom: 10px; }

.dashboardDictionary__submenu {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  max-width: 100%;
  overflow: auto; }
  @media (max-width: 1215px) {
    .dashboardDictionary__submenu {
      margin-bottom: 20px; } }
  .dashboardDictionary__submenu-item {
    border-bottom: 4px solid transparent;
    padding: 0 6px;
    font: 700 16px/19px "Montserrat", sans-serif;
    color: #000;
    opacity: 0.55;
    cursor: pointer;
    margin: 0 9px;
    white-space: nowrap; }
    .dashboardDictionary__submenu-item:hover {
      opacity: 1;
      border-bottom: 2px solid transparent; }
      .dashboardDictionary__header--bhp .dashboardDictionary__submenu-item:hover {
        border-bottom-color: #0080ff; }
      .dashboardDictionary__header--energy .dashboardDictionary__submenu-item:hover {
        border-bottom-color: #ffaa00; }
      .dashboardDictionary__header--environment .dashboardDictionary__submenu-item:hover {
        border-bottom-color: #32c832; }
    .dashboardDictionary__submenu-item:first-of-type {
      margin-left: 0; }
    .dashboardDictionary__submenu-item.active {
      font-size: 27px;
      opacity: 1; }
      .dashboardDictionary__submenu-item.active:hover {
        border-bottom: 4px solid transparent; }
      .dashboardDictionary__header--bhp .dashboardDictionary__submenu-item.active {
        border-bottom-color: #0080ff; }
      .dashboardDictionary__header--energy .dashboardDictionary__submenu-item.active {
        border-bottom-color: #ffaa00; }
      .dashboardDictionary__header--environment .dashboardDictionary__submenu-item.active {
        border-bottom-color: #32c832; }
      @media (max-width: 1665px) {
        .dashboardDictionary__submenu-item.active {
          font-size: 22px; } }
    @media (max-width: 1665px) {
      .dashboardDictionary__submenu-item {
        font-size: 14px; } }

.dashboardDictionary__searchBox {
  display: flex; }
  @media (max-width: 1215px) {
    .dashboardDictionary__searchBox .search__form {
      display: flex;
      grid-gap: 20px;
      gap: 20px;
      align-items: center; }
    .dashboardDictionary__searchBox .compendiumCounters {
      margin-top: unset; } }
  @media (max-width: 768px) {
    .dashboardDictionary__searchBox {
      margin-top: 15px;
      width: 100%;
      min-width: 0; } }
  @media (max-width: 375px) {
    .dashboardDictionary__searchBox {
      padding: 0 15px;
      margin-bottom: 20px; } }

.dashboardDictionary--bhp .text-highlighted {
  background-color: #0080ff; }

.dashboardDictionary--bhp .ReactTable .rt-thead .rt-th.-sort-asc,
.dashboardDictionary--bhp .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #0080ff; }

.dashboardDictionary--bhp .ReactTable .rt-thead .rt-th.-sort-desc,
.dashboardDictionary--bhp .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #0080ff; }

.dashboardDictionary--energy .text-highlighted {
  background-color: #ffaa00; }

.dashboardDictionary--energy .ReactTable .rt-thead .rt-th.-sort-asc,
.dashboardDictionary--energy .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #ffaa00; }

.dashboardDictionary--energy .ReactTable .rt-thead .rt-th.-sort-desc,
.dashboardDictionary--energy .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #ffaa00; }

.dashboardDictionary--environment .text-highlighted {
  background-color: #32c832; }

.dashboardDictionary--environment .ReactTable .rt-thead .rt-th.-sort-asc,
.dashboardDictionary--environment .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #32c832; }

.dashboardDictionary--environment .ReactTable .rt-thead .rt-th.-sort-desc,
.dashboardDictionary--environment .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #32c832; }

.dashboardUsers .ReactTable .rt-td {
  word-break: break-word; }

@media (min-width: 376px) {
  .dashboardUsers__header {
    display: flex;
    justify-content: space-between;
    margin: 72px 15px 30px; } }

@media (max-width: 768px) {
  .dashboardUsers__header {
    flex-wrap: wrap; } }

@media (max-width: 1665px) {
  .dashboardUsers__header {
    margin-top: 64px; } }

.dashboardUsers__submenu {
  display: flex;
  align-items: flex-end;
  max-width: 100%;
  overflow: auto; }
  .dashboardUsers__submenu-item {
    border-bottom: 4px solid transparent;
    padding: 0 6px;
    font: 700 18px/22px "Montserrat", sans-serif;
    color: #000;
    opacity: 0.55;
    cursor: pointer;
    margin: 0 9px;
    white-space: nowrap; }
    @media (max-width: 1665px) {
      .dashboardUsers__submenu-item {
        font-size: 16px; } }
    .dashboardUsers__submenu-item:first-of-type {
      margin-left: 0; }
    .dashboardUsers__submenu-item.active {
      border-bottom-color: #000;
      opacity: 1;
      font-size: 27px; }
      @media (max-width: 1665px) {
        .dashboardUsers__submenu-item.active {
          font-size: 22px; } }

.dashboardUsers__addClient {
  background-color: #212748;
  border: none;
  height: 35px;
  width: 170px;
  font: 600 16px/22px "Montserrat", sans-serif;
  color: #fff;
  border-radius: 5px;
  margin-right: 25px;
  cursor: pointer;
  outline: none; }
  @media (max-width: 1665px) {
    .dashboardUsers__addClient {
      font-size: 14px;
      height: 36px;
      width: 168px; } }
  .dashboardUsers__addClient--bhp {
    background-color: #0080ff; }
  .dashboardUsers__addClient--energy {
    background-color: #ffaa00; }
  .dashboardUsers__addClient--environment {
    background-color: #32c832; }

.dashboardUsers__searchBox {
  display: flex; }
  @media (max-width: 768px) {
    .dashboardUsers__searchBox {
      margin-top: 15px;
      width: 100%;
      min-width: 0; } }
  @media (max-width: 375px) {
    .dashboardUsers__searchBox {
      padding: 0 15px;
      margin-bottom: 20px; } }

.SingleDatePicker {
  width: 100%; }

.SingleDatePickerInput__withBorder {
  background-color: transparent !important;
  border: none !important; }

.DateInput {
  background: transparent !important;
  width: 100px !important; }
  .DateInput_input {
    background: transparent !important;
    padding: 3px 5px !important;
    font-family: "Roboto", sans-serif;
    font-weight: 600 !important;
    font-size: 18px;
    line-height: 24px !important; }
  .DateInput_input__focused {
    line-height: 24px !important;
    border-bottom: none !important;
    border-bottom: 1px solid #2b2b2b !important; }

.date-picker-empty {
  pointer-events: none;
  cursor: auto; }

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover,
.CalendarDay__default,
.CalendarMonth {
  background-color: #f1f1f1 !important; }

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #00d154 !important;
  border: 1px double #00d154 !important; }

.DayPickerKeyboardShortcuts_show__topRight {
  display: none; }

.DayPickerNavigation_button__verticalDefault {
  background-color: #f1f1f1 !important; }

.DateInput_fangStroke {
  fill: #f1f1f1 !important;
  stroke: #f1f1f1 !important; }

.mobileSubmenu {
  width: 100%; }
  .mobileSubmenu__current {
    position: relative;
    cursor: pointer;
    font-size: 17px;
    line-height: 20px;
    font-weight: 700;
    border-bottom: 1px solid #f6f6f6;
    padding: 20px 15px 20px 35px; }
    .mobileSubmenu__current:before {
      content: '';
      border-radius: 3px;
      width: 8px;
      height: 8px;
      border-bottom: 3px solid #000;
      border-left: 3px solid #000;
      position: absolute;
      left: 15px;
      top: 45%;
      transform: translateY(-50%) rotate(-45deg); }
  .mobileSubmenu__item {
    padding: 10px 15px 10px 35px;
    font-size: 12px;
    line-height: 15px; }
    .mobileSubmenu__item.active {
      font-weight: bold; }

.knowledgeBase {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: flex-end;
  overflow: auto;
  margin-bottom: 60px;
  width: 100%; }
  .knowledgeBase--news {
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 70px !important; }
  @media (min-width: 769px) {
    .knowledgeBase {
      padding: 80px 55px 20px;
      max-width: 1140px;
      margin: 0 auto; } }
  @media (min-width: 1200px) {
    .knowledgeBase {
      max-width: 100%;
      padding: 80px 150px 20px 150px;
      margin: unset; } }
  @media (max-width: 1665px) {
    .knowledgeBase {
      padding-top: 54px; } }
  @media (min-width: 769px) {
    .knowledgeBase--r {
      margin-bottom: 48px; } }
  @media (max-width: 768px) and (min-width: 376px) {
    .knowledgeBase {
      padding: 35px 12px; }
      .knowledgeBase--l, .knowledgeBase--r {
        width: 50%;
        height: 100%; }
      .knowledgeBase--r {
        padding-left: 30px; } }
  @media (max-width: 375px) {
    .knowledgeBase {
      flex-wrap: wrap;
      padding: 24px 8px; }
      .knowledgeBase--l, .knowledgeBase--r {
        width: 100%; } }
  .knowledgeBase__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 33px;
    position: relative;
    font-family: "Inter", sans-serif !important; }
    .knowledgeBase__title p {
      color: #072AC8;
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0.32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: "Inter", sans-serif !important; }
    .knowledgeBase__title .actions {
      display: flex;
      justify-content: center;
      align-content: center;
      grid-gap: 20px;
      gap: 20px; }
    .knowledgeBase__title .goBack {
      cursor: pointer;
      position: absolute;
      top: 7px;
      left: -80px;
      display: flex;
      align-items: center;
      font-family: "Inter", sans-serif;
      font-size: 14px; }
      @media (max-width: 1200px) {
        .knowledgeBase__title .goBack {
          left: 0px;
          top: 35px; } }
  .knowledgeBase__addTopicality {
    background-color: #212748;
    border: none;
    height: 36px;
    width: 100px;
    font: 700 17px/22px "Montserrat", sans-serif;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    flex-wrap: wrap;
    outline: none; }
    @media (max-width: 1665px) {
      .knowledgeBase__addTopicality {
        font-size: 14px;
        height: 36px;
        width: 90px; } }
  @media (max-width: 375px) {
    .knowledgeBase--l .title {
      padding-bottom: 15px;
      margin-bottom: 20px; } }

.title--main, .knowledgeBase .title {
  font: 700 30px/37px "Montserrat", sans-serif; }
  @media (max-width: 1665px) {
    .title--main, .knowledgeBase .title {
      font-size: 24px; } }
  @media (max-width: 375px) {
    .title--main, .knowledgeBase .title {
      font-size: 17px;
      line-height: 20px; } }

.knowledgePosts {
  position: relative;
  overflow: hidden; }
  .knowledgePosts__inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 100%; }
    @media (max-width: 375px) {
      .knowledgePosts__inner {
        flex-wrap: nowrap;
        overflow-x: auto; } }
  @media (min-width: 769px) {
    .knowledgePosts {
      margin: 0 -10px; }
      .knowledgePosts__top {
        display: flex; } }
  .knowledgePosts__latest .knowledgePost, .knowledgePosts__column .knowledgePost {
    max-width: 100%; }
    .knowledgePosts__latest .knowledgePost img, .knowledgePosts__column .knowledgePost img {
      width: 100%; }
  @media (min-width: 769px) {
    .knowledgePosts__latest {
      width: 66.66667%; }
    .knowledgePosts__column {
      width: calc(100% - 66.66667%); } }

.knowledgePost {
  margin-bottom: 30px; }
  @media (min-width: 769px) {
    .knowledgePost {
      width: calc(100% / 4);
      padding: 0 10px; } }
  @media (max-width: 375px) {
    .knowledgePost {
      max-width: 90%;
      min-width: 90%;
      margin-right: 15px; } }
  .knowledgePost a {
    text-transform: none;
    text-decoration: none;
    color: #000;
    height: 100%;
    display: flex;
    flex-direction: column; }
  .knowledgePost__image img {
    min-height: 190px;
    max-height: 190px;
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
    width: 100%; }
  .knowledgePost__image--big {
    max-height: unset !important; }
  .knowledgePost__title {
    font: bold 18px/23px "Inter", sans-serif;
    margin: 8px 0;
    margin-bottom: 12px; }
    @media (max-width: 1665px) {
      .knowledgePost__title {
        line-height: 27px;
        font-size: 20px; } }
  .knowledgePost__date {
    margin-top: 20px;
    color: #3A405D;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.28px; }
  .knowledgePost__readMore {
    color: #072AC8;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.32px;
    display: flex;
    align-items: center;
    grid-gap: 7px;
    gap: 7px;
    margin-top: auto; }
  .knowledgePost--infinite {
    margin-bottom: 0;
    padding-top: 50px;
    margin-top: 50px; }
    .knowledgePost--infinite:before {
      content: '';
      border-top: 1px solid #CCCCCC;
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      margin-top: -50px; }
  .knowledgePost__vertical {
    display: flex !important;
    flex-direction: row !important;
    grid-gap: 20px;
    gap: 20px; }
    .knowledgePost__vertical .knowledgePost__image {
      width: 330px; }
      @media (max-width: 1400px) {
        .knowledgePost__vertical .knowledgePost__image {
          width: 200px; } }
    .knowledgePost__vertical .knowledgePost__date {
      margin-top: unset; }
    @media (max-width: 1400px) {
      .knowledgePost__vertical .knowledgePost__left {
        width: 200px; } }
  .knowledgePost__content {
    color: #3A405D;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    margin-top: -10px; }

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Dwie kolumny o równych szerokościach */
  grid-gap: 20px;
  gap: 20px;
  /* Odstępy między kolumnami */ }

.column {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  /* Odstępy między elementami w kolumnie */ }

.item {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  /* Możesz dodać inne style, np. różne wysokości */ }

.columnCount {
  -webkit-column-count: 2;
          column-count: 2; }

.columnCount > * {
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid; }

@media (max-width: 375px) {
  .currentNews {
    margin-top: 22px; } }

.currentNews .pagination {
  display: none; }

.currentNews .currentPost {
  padding: 20px 80px 20px 0;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: relative; }
  .currentNews .currentPost__title {
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: column; }
    .currentNews .currentPost__title p {
      font-family: "Inter", sans-serif;
      font-size: 11px;
      margin-left: 5px;
      color: #989797; }
  .currentNews .currentPost .link {
    display: flex;
    text-transform: none;
    text-decoration: none;
    color: #000; }
    .currentNews .currentPost .link--active {
      cursor: pointer; }
  .currentNews .currentPost__actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 15px; }
  .currentNews .currentPost__editPost {
    border: none;
    outline: none;
    height: 20px;
    background-color: #fff; }
    .currentNews .currentPost__editPost:hover {
      cursor: pointer; }
  .currentNews .currentPost__deletePost {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px; }
    .currentNews .currentPost__deletePost:hover {
      background-color: #e6e6e6; }
      .currentNews .currentPost__deletePost:hover svg {
        fill: #ff5454;
        cursor: pointer; }
    .currentNews .currentPost__deletePost svg {
      fill: #afafaf; }
  .currentNews .currentPost__title {
    font: 400 20px/24px "Inter", sans-serif;
    color: #000;
    padding-left: 10px; }
    @media (max-width: 1665px) {
      .currentNews .currentPost__title {
        font-size: 14px; } }
  .currentNews .currentPost__tag {
    padding: 4px 7px;
    display: inline-block;
    border-radius: 5px;
    color: #fff;
    font: 600 14px/19px "Open Sans", sans-serif; }
    .currentNews .currentPost__tag--blue {
      background-color: #0080ff; }
    .currentNews .currentPost__tag--orange {
      background-color: #ffaa00; }
    .currentNews .currentPost__tag--green {
      background-color: #00d154; }
    .currentNews .currentPost__tag--gray {
      background-color: #c9c9c9; }
    .currentNews .currentPost__tag_wrapper {
      min-width: 45px;
      text-align: center; }
    @media (max-width: 1665px) {
      .currentNews .currentPost__tag {
        padding: 2px 4px; } }

.currentPost__textarea .form__field textarea {
  min-height: 150px; }

.knowledgePostsTop {
  min-height: 702px;
  display: flex; }
  @media (max-width: 1400px) {
    .knowledgePostsTop {
      min-height: 500px; } }
  .knowledgePostsTop__first .knowledgePost {
    width: 100%; }
  .knowledgePostsTop__left {
    width: 50%; }
  .knowledgePostsTop__right {
    width: 50%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column; }
    .knowledgePostsTop__right .knowledgePost {
      width: 100%;
      margin-bottom: 0 !important; }
      .knowledgePostsTop__right .knowledgePost--infinite {
        padding-top: 24px !important;
        margin-top: 24px !important; }
        .knowledgePostsTop__right .knowledgePost--infinite:before {
          margin-top: -24px; }

.coupled {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  gap: 16px; }
  .coupled .column {
    display: flex;
    flex-direction: column;
    width: 100%; }
  .coupled .currentPost {
    width: unset; }

.area {
  padding: 32px 0 32px 58px; }
  .area--forbidden {
    background-color: #3a3a3a;
    min-height: calc(100vh - 99px);
    height: 100%;
    display: flex; }
    @media (max-width: 1665px) {
      .area--forbidden {
        min-height: calc(100vh - 80px); } }
    @media (max-width: 1221px) {
      .area--forbidden {
        min-height: calc(100vh - 92px); } }
    @media (max-width: 768px) {
      .area--forbidden {
        min-height: calc(100vh - 82px); } }
    @media (max-width: 376px) {
      .area--forbidden {
        min-height: calc(100vh - 70px); } }
  .area__content {
    max-width: 550px;
    margin-top: 35px; }
  .area__image {
    width: calc(100% - 550px);
    padding-left: 70px; }

.learnMore {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 18px; }
  .learnMore svg {
    margin-bottom: 25px;
    width: 100px;
    height: 100px; }
    .learnMore svg g {
      fill: #fff; }
  .learnMore__icon--addition svg path {
    fill: #fff; }
  .learnMore__title {
    font: bold 49px/59px "Montserrat", sans-serif;
    margin-bottom: 20px; }
  .learnMore__badge {
    font: bold 14px/19px "Open Sans", sans-serif;
    background-color: #ff5454;
    display: inline-block;
    padding: 4px 12px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 25px; }
    .learnMore__badge--blue {
      background-color: #0080ff; }
    .learnMore__badge--green {
      background-color: #00d154; }
    .learnMore__badge--orange {
      background-color: #ffaa00; }
  .learnMore__badges {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column; }
    .learnMore__badges .learnMore__badge {
      text-align: left;
      font-size: 16px; }
      .learnMore__badges .learnMore__badge div {
        padding: 4px 2px; }
  .learnMore__heading {
    margin-bottom: 25px;
    font-weight: 500; }
    .learnMore__heading strong {
      font-weight: bold; }
  .learnMore__description {
    font-weight: 300;
    margin-bottom: 30px; }
  .learnMore__ctaDescription {
    font-size: 25px;
    margin-bottom: 25px; }

.imageWrapper {
  background-size: cover;
  background-position-x: 50%;
  width: 100%;
  height: 100%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; }

.userManagement {
  display: flex; }
  .userManagement--l {
    flex: 1 0 495px;
    min-height: calc(100vh - 110px);
    padding: 80px 60px;
    background-color: #f1f1f1;
    overflow: auto;
    max-height: calc(100vh - 99px); }
    @media (max-width: 1665px) {
      .userManagement--l {
        max-height: calc(100vh - 80px); } }
    .userManagement--l .form__field {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px; }
      .userManagement--l .form__field label,
      .userManagement--l .form__field input {
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        font-size: 17px;
        line-height: 1.25; }
        @media (max-width: 1665px) {
          .userManagement--l .form__field label,
          .userManagement--l .form__field input {
            font-size: 15px; } }
      .userManagement--l .form__field label {
        order: 2;
        margin-top: 0;
        opacity: 0.29;
        margin: 4px 0 0; }
      .userManagement--l .form__field input {
        order: 1;
        padding: 3px;
        background-color: transparent;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        border-bottom: 1px solid #2b2b2b; }
      .userManagement--l .form__field--zipCode {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start; }
        .userManagement--l .form__field--zipCode span.zipCode--spacer {
          order: 2;
          white-space: nowrap;
          width: 30px;
          min-width: 30px;
          height: 100%;
          position: relative; }
          .userManagement--l .form__field--zipCode span.zipCode--spacer:before {
            position: absolute;
            content: '';
            left: 2px;
            right: 2px;
            top: 14.5px;
            width: 26px;
            height: 1px;
            background-color: #2b2b2b; }
        .userManagement--l .form__field--zipCode input {
          text-align: center; }
          .userManagement--l .form__field--zipCode input.first {
            order: 1;
            width: 35px; }
          .userManagement--l .form__field--zipCode input.second {
            order: 3;
            width: 50px; }
  .userManagement--r {
    flex: 1 1 1400px;
    padding: 80px 30px 0 45px;
    overflow: hidden; }
    .userManagement--r .ReactTable .rt-table {
      overflow: scroll; }
  .userManagement .btn--blank {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    line-height: 22px;
    font-weight: 600;
    color: #000;
    opacity: 0.55; }
    @media (max-width: 1665px) {
      .userManagement .btn--blank {
        font-size: 15px; } }

.manageUser__form {
  position: relative;
  z-index: 2; }
  .manageUser__form--city_zip {
    display: flex;
    align-items: flex-start; }
  .manageUser__form--address {
    display: flex; }
    .manageUser__form--address .form__field--house_number, .manageUser__form--address .form__field--flat_number {
      flex: 22 1;
      padding-left: 10px; }
    .manageUser__form--address .form__field--street {
      flex: 56 1; }
  .manageUser__form .zipCode {
    padding-left: 35px; }
  .manageUser__form--contactPerson {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin: 50px 0 30px 0; }

.manageUser__header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px; }
  .manageUser__header .btn {
    white-space: nowrap;
    margin-left: -33px; }

.manageUser__companyName {
  flex-grow: 1;
  padding: 0 5px;
  text-align: center; }

.associatedUsersHeader {
  margin-bottom: 28px; }
  .associatedUsersHeader__inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .associatedUsersHeader__title {
    display: flex;
    align-items: center;
    font-size: 27px; }
    @media (max-width: 1665px) {
      .associatedUsersHeader__title {
        font-size: 24px; } }
    .associatedUsersHeader__title--logins-amount {
      font-size: 30px;
      margin-left: 20px;
      margin-right: 10px;
      font-weight: 700;
      color: #c9c9c9; }
      @media (max-width: 1665px) {
        .associatedUsersHeader__title--logins-amount {
          font-size: 28px; } }

.associatedUsersTable .ReactTable .rt-tbody {
  overflow-y: auto;
  max-height: calc(100vh - 36vh);
  padding-bottom: 90px; }
  .associatedUsersTable .ReactTable .rt-tbody .rt-tr-group:first-of-type .rt-tr {
    order: 2; }

.associatedUsersTable .ReactTable .rt-thead.-header {
  border-bottom: 0px;
  margin-bottom: 7px; }

.associateUserRow {
  order: 1;
  padding-bottom: 8px;
  background-color: #fff; }
  .associateUserRow__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cecece;
    border-radius: 9px;
    padding: 4px 5px; }
  .associateUserRow .form__field {
    margin: 0; }
    .associateUserRow .form__field input {
      font-family: "Montserrat", sans-serif;
      padding: 5px 4px;
      border: 0px;
      background-color: transparent; }
    .associateUserRow .form__field:first-of-type {
      flex: 280 1; }
      @media (max-width: 1100px) {
        .associateUserRow .form__field:first-of-type {
          flex: 100 1; } }
    .associateUserRow .form__field:nth-of-type(2), .associateUserRow .form__field:nth-of-type(3), .associateUserRow .form__field:nth-of-type(4), .associateUserRow .form__field:nth-of-type(5) {
      flex: 100 1; }
  .associateUserRow__permissions {
    align-items: center;
    display: flex; }
  .associateUserRow--l {
    width: 56%;
    display: flex; }
    @media (max-width: 1050px) {
      .associateUserRow--l {
        width: 50%; } }
  .associateUserRow--r {
    width: 43%;
    margin-left: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1050px) {
      .associateUserRow--r {
        width: 50%; } }

.badge {
  padding: 4px 6px;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  border-radius: 5px;
  margin: 0 2px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #efefef; }
  .badge--editor, .badge--admin, .badge--superadmin {
    border: 1px solid #a2a2a2;
    background-color: transparent;
    color: rgba(170, 170, 170, 0.28); }
  .badge--disabled {
    cursor: not-allowed;
    opacity: 0.35; }
  .badge--active {
    color: #fff; }
    .badge--active.badge--green {
      background-color: #00d154;
      flex-shrink: 0; }
    .badge--active.badge--blue {
      background-color: #0080ff; }
    .badge--active.badge--gray {
      background-color: #c9c9c9; }
    .badge--active.badge--orange {
      background-color: #ffaa00; }
    .badge--active.badge--red {
      background-color: #ff5454; }
    .badge--active.badge--editor {
      background-color: #41c2be; }
    .badge--active.badge--admin {
      background-color: #6a6a6a; }
    .badge--active.badge--superadmin {
      background-color: #000000; }

.tableSelect {
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .tableSelect__value {
    border-radius: 5px;
    padding: 4px 7px;
    background-color: #efefef;
    color: #000; }
    .tableSelect__value--basic {
      color: rgba(0, 0, 0, 0.28); }
    .tableSelect__value--detailed {
      background-color: #8a35de;
      color: #fff; }
    .tableSelect__value--managing {
      background-color: #3579de;
      color: #fff; }
  .tableSelect__options {
    padding: 4px 7px;
    border-radius: 5px;
    z-index: 100;
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    background-color: #efefef;
    min-width: 100px; }
  .tableSelect__single {
    margin-bottom: 4px; }
    .tableSelect__single:last-of-type {
      margin-bottom: 0; }

.permissionsCell {
  display: flex;
  position: relative; }
  .permissionsCell__edit, .permissionsCell__delete {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px; }
    .permissionsCell__edit:hover, .permissionsCell__delete:hover {
      background-color: #e6e6e6; }
      .permissionsCell__edit:hover svg, .permissionsCell__delete:hover svg {
        fill: #ff5454;
        cursor: pointer; }
    .permissionsCell__edit svg, .permissionsCell__delete svg {
      fill: #afafaf; }
  .permissionsCell__edit {
    right: 30px; }

.areaManagement {
  padding: 35px 25px;
  max-height: calc(100vh - 110px);
  overflow: auto;
  background-color: #f8f8f8;
  margin: 12px; }
  .areaManagement__inner {
    padding: 25px; }
    @media (min-width: 768px) {
      .areaManagement__inner {
        padding: 25px 75px; } }
  .areaManagement__content {
    display: flex;
    max-width: 1260px;
    flex-wrap: wrap; }
    .areaManagement__content .infoBox {
      width: 100%; }
    @media (min-width: 768px) {
      .areaManagement__content .infoBox {
        width: 50%; }
        .areaManagement__content .infoBox:nth-of-type(even) {
          padding-left: 10px; }
        .areaManagement__content .infoBox:nth-of-type(odd) {
          padding-right: 10px; } }
  .areaManagement__header {
    display: flex;
    margin-bottom: 25px; }
    .areaManagement__header--backButton button {
      font-family: "Montserrat", sans-serif;
      opacity: 0.55;
      padding-left: 0px;
      padding-right: 0px; }
    .areaManagement__header--title {
      margin-left: 25px; }
      .areaManagement__header--title h1 {
        font-family: "Montserrat", sans-serif;
        margin-bottom: 5px;
        font-size: 27px; }
      .areaManagement__header--title h3 {
        font-family: "Montserrat", sans-serif; }
  .areaManagement__form {
    max-width: 1200px;
    width: 100%; }
    .areaManagement__form .form__field {
      margin-bottom: 15px; }
      .areaManagement__form .form__field textarea {
        border: none;
        min-height: 100px; }
      .areaManagement__form .form__field input {
        border: none; }
      .areaManagement__form .form__field input::-webkit-input-placeholder, .areaManagement__form .form__field textarea::-webkit-input-placeholder {
        color: #c9c9c9; }
      .areaManagement__form .form__field input:-ms-input-placeholder, .areaManagement__form .form__field textarea:-ms-input-placeholder {
        color: #c9c9c9; }
      .areaManagement__form .form__field input::-ms-input-placeholder, .areaManagement__form .form__field textarea::-ms-input-placeholder {
        color: #c9c9c9; }
      .areaManagement__form .form__field input::placeholder,
      .areaManagement__form .form__field textarea::placeholder {
        color: #c9c9c9; }
      .areaManagement__form .form__field--checkbox label {
        order: 2; }
    .areaManagement__form--save {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      float: right; }
      .areaManagement__form--save .btn--green, .areaManagement__form--save .btn--orange, .areaManagement__form--save .btn--blue, .areaManagement__form--save .btn--navy-blue, .areaManagement__form--save .btn--navy-blue-stroke, .areaManagement__form--save .btn--red, .areaManagement__form--save .btn--cancel {
        width: auto; }
  .areaManagement__label {
    display: flex;
    align-items: center;
    height: 30px;
    font-family: "Montserrat", sans-serif;
    border-radius: 15px;
    font-size: 13px;
    background-color: #efefef;
    font-weight: 600;
    padding: 5px 15px;
    margin-bottom: 10px;
    position: relative;
    text-transform: uppercase; }
  .areaManagement__star {
    color: #0080ff;
    font-size: 16px;
    margin-right: 5px; }
  .areaManagement__source {
    right: 20px;
    position: absolute;
    display: flex;
    align-items: center;
    font-weight: 400; }
    .areaManagement__source svg {
      margin-left: 5px; }
    .areaManagement__source:hover {
      cursor: pointer; }

.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  font-family: "Montserrat", sans-serif; }
  .modal__title {
    color: rgba(0, 0, 0, 0.38);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 35px; }
    .modal__title--with-icon {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .modal__title--with-icon svg {
        width: 35px;
        height: 40px;
        margin-bottom: 30px; }
  .modal__date-picker {
    display: flex;
    justify-content: center;
    padding: 0 0 45px 0; }
  .modal__info {
    background-color: #f1f1f1;
    padding: 20px 0; }
    .modal__info h2 {
      padding: 5px 27px;
      color: #000;
      font-weight: 700;
      font-size: 13px; }
    .modal__info p {
      padding: 0px 27px 20px 27px;
      font-size: 12px; }
    .modal__info ul li {
      padding: 15px 27px;
      display: flex;
      justify-content: space-between;
      color: #000;
      font-weight: 700;
      font-size: 13px; }
      @media (max-width: 1665px) {
        .modal__info ul li {
          font-size: 12px; } }
      .modal__info ul li .info-value {
        font-weight: 400; }
        .modal__info ul li .info-value .expired-date {
          color: #fd3617;
          font-weight: bold; }
      .modal__info ul li .info-areas {
        display: flex; }
  .modal__inner {
    max-height: 100%;
    overflow: auto;
    padding: 15px 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 40px #00000029; }
    @media (min-width: 768px) {
      .modal__inner {
        padding: 40px 50px;
        min-width: 500px; } }
    .modal--big .modal__inner {
      max-width: 700px;
      width: 100%; }
      @media (max-width: 767px) {
        .modal--big .modal__inner {
          max-width: calc(100% - 30px); } }
    .modal__inner--edit {
      max-width: 1400px !important; }
    .modal__inner--overflow-unset {
      overflow: unset; }
    .modal__inner--taskBox {
      min-width: 1080px; }
  .modal__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px; }
    .modal__actions .btn--green, .modal__actions .btn--orange, .modal__actions .btn--blue, .modal__actions .btn--navy-blue, .modal__actions .btn--navy-blue-stroke, .modal__actions .btn--red, .modal__actions .btn--cancel {
      width: auto; }

.compendiumElement {
  padding: 5px 25px;
  background-color: #f6f6f6;
  margin-bottom: 5px;
  border-radius: 2px;
  font-family: "Montserrat", sans-serif; }
  .compendiumElement--term {
    margin-bottom: 5px;
    font-weight: 700; }

.compendiums {
  position: relative; }

.compendiumsMobile {
  max-height: calc(100vh - 182px);
  overflow: auto; }
  .compendiumsMobile .compendiumLetters {
    position: absolute;
    z-index: 9;
    right: 0;
    top: 10px; }
    .compendiumsMobile .compendiumLetters__single {
      padding: 4px 5px;
      text-align: right;
      font-family: "Montserrat", sans-serif;
      font-weight: 600; }
      .compendiumsMobile .compendiumLetters__single.compendiumLetters__single--active {
        font-weight: 700; }
  .compendiumsMobile--preview {
    max-height: calc(100vh - 70px); }

.compendiumPreview__term {
  margin-bottom: 5px; }

.compendiumPreview__department {
  margin-bottom: 25px; }

.compendiumPreview__extendedView {
  display: inline-block;
  padding: 7px 20px;
  border-radius: 15px; }
  .compendiumPreview__extendedView .star {
    padding-right: 7px; }
  .compendiumPreview__extendedView .arrow {
    padding-left: 7px; }

.compendiumPreview__options {
  text-align: center; }

.compendiumPreview__heading__inner {
  padding: 5px 35px 25px;
  background-color: #f8f8f8;
  margin-bottom: 25px; }

.compendiumPreview__goBack {
  padding: 15px 20px;
  border-bottom: 1px solid #f5f5f5; }

.companies-select {
  width: 200px;
  margin: 0 0 0px 20px; }
  .companies-select .react-select__control {
    border-radius: 2px !important; }
  .companies-select .react-select__indicator {
    padding-right: 7px !important; }
  .companies-select .react-select__placeholder, .companies-select .react-select__single-value {
    font-size: 14px !important; }
  .companies-select .react-select__menu {
    font-size: 13px;
    z-index: 9; }

.work-plan-date {
  margin-bottom: 10px; }
  .work-plan-date--text {
    color: #acacac;
    font-weight: 500; }
  .work-plan-date--red {
    color: #ff0000; }
  .work-plan-date--yellow {
    color: #ffaa00; }

.compendiumSingle {
  padding: 35px 25px;
  max-height: calc(100vh - 110px);
  overflow: auto; }
  .compendiumSingle__inner {
    padding: 25px;
    background-color: #f8f8f8; }
    @media (min-width: 768px) {
      .compendiumSingle__inner {
        padding: 25px 75px; } }
  .compendiumSingle__content {
    display: flex;
    flex-wrap: wrap; }
    .compendiumSingle__content .infoBox {
      width: 100%; }
  .compendiumSingle__header {
    display: flex;
    margin-bottom: 25px; }
    .compendiumSingle__header--backButton button {
      font-family: "Montserrat", sans-serif;
      opacity: 0.55;
      padding-left: 0px;
      padding-right: 0px; }
    .compendiumSingle__header--title {
      margin-left: 25px; }
      .compendiumSingle__header--title h1 {
        font-family: "Montserrat", sans-serif;
        margin-bottom: 5px; }
      .compendiumSingle__header--title h3 {
        font-family: "Montserrat", sans-serif; }
  .compendiumSingle .infoBox__star {
    color: #fff; }
  .compendiumSingle .infoBox__badge {
    background-color: #fff; }
    .compendiumSingle .infoBox__badge a {
      color: #000; }
    .compendiumSingle .infoBox__badge svg {
      fill: #000; }
  .compendiumSingle .infoBox__title {
    color: #fff; }
  .compendiumSingle--bhp .infoBox__header,
  .compendiumSingle--bhp .ratingBox__header {
    background-color: #0080ff; }
  .compendiumSingle--energy .infoBox__header,
  .compendiumSingle--energy .ratingBox__header {
    background-color: #ffaa00; }
  .compendiumSingle--environment .infoBox__header,
  .compendiumSingle--environment .ratingBox__header {
    background-color: #32c832; }

.infoBox {
  margin-bottom: 40px; }
  .infoBox__header {
    background-color: #efefef;
    display: flex;
    align-items: center;
    padding: 5px 7px;
    border-radius: 15px;
    margin-bottom: 25px;
    position: relative; }
  .infoBox__title {
    font-family: "Montserrat", sans-serif; }
  .infoBox__badge {
    margin-left: auto;
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    background-color: #0080ff;
    color: #fff;
    padding: 0px 15px 0 20px;
    border-radius: 15px;
    font-family: "Montserrat", sans-serif;
    line-height: 26px; }
    .infoBox__badge:hover {
      cursor: pointer;
      background-color: #f5f5f5; }
    .infoBox__badge a {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff; }
      .infoBox__badge a svg {
        fill: #fff;
        margin-left: 10px;
        margin-top: -2px;
        display: block; }
  .infoBox__star, .infoBox__title {
    margin-left: 7px; }
  .infoBox__star {
    color: #0080ff;
    font-size: 18px;
    line-height: 1; }
  .infoBox__content {
    white-space: pre-line;
    padding: 0 14px;
    font-family: "Montserrat", sans-serif;
    text-align: justify; }
  .infoBox--mobile .infoBox__content {
    padding: 10px 20px;
    background-color: #f8f8f8; }
  .infoBox--mobile .infoBox__header {
    margin: 0 15px 25px; }
  .infoBox--mobile .ratingBox__wrapper {
    padding: 0 20px; }

.fulfillment-proof {
  font-family: "Montserrat", sans-serif;
  background-color: #efefef;
  padding: 15px; }
  .fulfillment-proof__header {
    display: flex;
    margin-bottom: 10px;
    font-weight: bold; }
    .fulfillment-proof__header svg {
      width: 11px;
      height: 14px;
      margin-right: 10px; }
  .fulfillment-proof--input {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 !important;
    width: 16px !important;
    height: 16px;
    z-index: 99999;
    opacity: 0;
    cursor: pointer; }
  .fulfillment-proof--left {
    display: flex; }
  .fulfillment-proof--right {
    display: flex;
    margin-left: auto; }
    .fulfillment-proof--right--disabled {
      pointer-events: none;
      opacity: 0.4; }
  .fulfillment-proof--file {
    display: flex; }
  .fulfillment-proof--icon-file {
    cursor: pointer;
    position: relative;
    z-index: 9; }
  .fulfillment-proof--file-name {
    font-size: 12px;
    margin-right: 10px;
    max-width: 110px;
    cursor: pointer;
    transition: 0.3s; }
    .fulfillment-proof--file-name p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis ellipsis; }
    .fulfillment-proof--file-name div {
      display: none; }
    .fulfillment-proof--file-name:hover {
      transition: 0.3s; }
      .fulfillment-proof--file-name:hover p {
        display: none; }
      .fulfillment-proof--file-name:hover div {
        display: flex; }
  .fulfillment-proof__content {
    font-weight: 300; }
  .fulfillment-proof__text {
    background-color: #efefef;
    padding: 10px;
    width: 100%;
    word-break: break-word; }

.rating-select__control {
  font-family: "Montserrat", sans-serif;
  background-color: #efefef !important;
  border: none !important;
  outline: none !important; }

.rating-select__single-value {
  color: #000;
  font-weight: bold; }

.rating-select--disabled {
  pointer-events: none; }

.row-action {
  display: flex;
  align-items: center; }
  .row-action--close {
    display: flex;
    align-items: center;
    margin-left: 7px;
    height: 16px;
    width: 16px;
    cursor: pointer; }
    .row-action--close svg path {
      transition: 0.3s;
      fill: grey; }
    .row-action--close:hover svg path {
      transition: 0.3s;
      fill: #292929; }

.ratingBox {
  padding: 7px 14px 7px;
  background-color: #efefef;
  border-radius: 2px; }
  .ratingBox__blockedToday {
    background-color: #FFD5D5;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 11px;
    display: flex;
    align-items: center;
    grid-gap: 19px;
    gap: 19px;
    margin: 0 5px;
    visibility: hidden;
    transition: 0.2s;
    max-height: 0px;
    color: transparent; }
    .ratingBox__blockedToday .svg {
      visibility: hidden;
      height: 0;
      width: 0; }
    .ratingBox__blockedToday.active {
      color: #000;
      visibility: visible;
      transition: 0.2s;
      max-height: 100px; }
      .ratingBox__blockedToday.active .svg {
        visibility: visible;
        width: 25px;
        height: 25px; }
  .ratingBox__left, .ratingBox__right {
    width: calc(100% - 5px); }
    @media (min-width: 1200px) {
      .ratingBox__left, .ratingBox__right {
        width: calc(50% - 5px); } }
  .ratingBox__right--disabled {
    pointer-events: none; }
  .ratingBox__left {
    margin-right: 7px; }
  .ratingBox__header {
    height: 30px;
    margin-top: 20px;
    border-radius: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #fff;
    padding-left: 13px;
    font-weight: 400;
    display: flex;
    margin-bottom: 10px;
    align-items: center; }
  .ratingBox__container {
    display: flex; }
    .ratingBox__container .ratingBox__content {
      width: calc(50% - 5px);
      margin-right: 5px; }
    .ratingBox__container .ratingBox__organizationalUnit {
      width: calc(50% - 5px); }
      .ratingBox__container .ratingBox__organizationalUnit .ratingBox__content {
        width: 100%; }
        .ratingBox__container .ratingBox__organizationalUnit .ratingBox__content input {
          border: 1px solid #e3e3e3; }
    .ratingBox__container .ratingBox__header {
      width: 100%; }
  .ratingBox__content {
    display: flex;
    flex-wrap: wrap; }
    .ratingBox__content--left, .ratingBox__content--right {
      width: calc(100% - 5px); }
    .ratingBox__content--left {
      margin-right: 7px;
      margin-bottom: 20px; }
      @media (min-width: 650px) {
        .ratingBox__content--left {
          margin-bottom: 0px; } }
    .ratingBox__content--right {
      margin-top: 10px; }
  .ratingBox__other-input {
    border: none;
    outline: none;
    height: 42px;
    background-color: #efefef;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    border-radius: 3px;
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    font-weight: 400; }
  .ratingBox__date-picker {
    display: flex; }
    .ratingBox__date-picker .form__field {
      margin: 0 auto 0 0; }
    .ratingBox__date-picker--full {
      width: 100%;
      display: flex; }
    .ratingBox__date-picker--disabled {
      cursor: auto; }
  .ratingBox__raw-select {
    width: 100%;
    height: 42px;
    background-color: #efefef;
    display: flex;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold; }
    .ratingBox__raw-select svg {
      margin: 0 15px; }
  .ratingBox__face-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px; }
    .ratingBox__face-container--hover:hover .ratingBox__face-label,
    .ratingBox__face-container--hover:hover .ratingBox__face-date {
      opacity: 1;
      cursor: pointer; }
  .ratingBox__face-label, .ratingBox__face-date {
    width: 50%;
    margin: 10px 5px;
    height: 42px;
    display: flex;
    align-items: center;
    background-color: #efefef;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    opacity: 0.4;
    padding: 0 15px; }
    .ratingBox__face-label img, .ratingBox__face-date img {
      margin-right: 15px; }
    .ratingBox__face-label--active, .ratingBox__face-date--active {
      opacity: 1; }
    .ratingBox__face-label--periodicity, .ratingBox__face-date--periodicity {
      height: 40px !important; }
  .ratingBox__face-label--hover:hover {
    opacity: 1;
    cursor: pointer; }
  .ratingBox__face-label {
    cursor: pointer; }
  .ratingBox:nth-of-type(even) {
    margin-left: 5px; }
  .ratingBox:nth-of-type(odd) {
    margin-right: 5px; }
  .ratingBox__wrapper {
    display: flex;
    flex-direction: column; }
    @media (min-width: 1200px) {
      .ratingBox__wrapper {
        align-items: flex-start;
        flex-direction: row;
        margin: 50px 0;
        min-height: 310px; } }
  .ratingBox__title {
    font-family: "Montserrat", sans-serif;
    width: 100%;
    font-weight: 400; }
    .ratingBox__title .rating--stars {
      padding: 2px 0 0 0; }
    .ratingBox__title .rating--label {
      font-size: 12px; }
  .ratingBox__risk {
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #707070; }
    .ratingBox__risk span {
      cursor: pointer; }
    .ratingBox__risk--switch {
      cursor: pointer;
      width: 40px;
      height: 20px;
      margin-right: 13px;
      display: inline-block;
      border: 1px solid #c9c9c9;
      border-radius: 10px; }
      .ratingBox__risk--switch_inner {
        width: 20px;
        height: 18px;
        border: 1px solid #c9c9c9;
        background-color: #efefef;
        border-radius: 10px;
        transition: 0.1s linear margin-left, background-color; }
      .ratingBox__risk--switch--active .ratingBox__risk--switch_inner {
        margin-left: 18px;
        background-color: #ff5454; }
  .ratingBox .svg--file {
    width: 11px; }
  .ratingBox__note--blank, .ratingBox__content--blank {
    opacity: 0.5; }
  .ratingBox__note {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    margin-top: 10px; }
    .ratingBox__note-title {
      font-family: "Montserrat", sans-serif;
      margin-left: 7px;
      font-weight: 600; }
  .ratingBox__content {
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    padding-bottom: 3px; }
    .ratingBox__content textarea {
      font-family: "Montserrat", sans-serif;
      font-size: 13px;
      width: 100% !important;
      min-height: 100px;
      resize: vertical;
      border: none;
      border-radius: 2px;
      color: #595959;
      outline: none;
      padding: 10px 10px;
      max-height: 500px; }
    .ratingBox__content textarea::-webkit-input-placeholder {
      color: #afafaf; }
    .ratingBox__content textarea:-ms-input-placeholder {
      color: #afafaf; }
    .ratingBox__content textarea::-ms-input-placeholder {
      color: #afafaf; }
    .ratingBox__content textarea::placeholder {
      color: #afafaf; }
    .ratingBox__content input {
      font-family: "Montserrat", sans-serif;
      font-size: 13px;
      width: 100% !important;
      border: none;
      border-radius: 2px;
      color: #595959;
      outline: none;
      padding: 10px 10px; }
    .ratingBox__content input:disabled {
      background-color: #efefef; }

.periodicity {
  cursor: pointer;
  height: 30px; }
  .periodicity .form__field .react-select__control {
    margin: 0; }
  .periodicity .css-2b097c-container {
    margin-top: -3px; }
  .periodicity .rating-select__control--is-focused {
    box-shadow: none; }
  .periodicity .rating-select {
    width: 100%; }

.changelog {
  padding: 80px 0 0 10px; }
  .changelog__search {
    width: 100%;
    display: flex;
    justify-content: flex-end; }
  .changelog__form {
    margin-right: 40px; }
  .changelog__title {
    border-bottom: 4px solid transparent;
    padding: 10px 0px;
    font: 700 30px/22px "Montserrat", sans-serif;
    color: #000;
    opacity: 0.55;
    cursor: pointer;
    margin: 0 9px 15px 9px;
    white-space: nowrap;
    border-bottom: 3px solid #2b2b2b;
    opacity: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  @media (max-width: 1665px) {
    .changelog {
      padding-top: 54px; }
      .changelog__title {
        font-size: 22px; } }

.editModal--form label {
  font-weight: 400;
  color: #1a1a1a;
  font-size: 12px;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 5px 2px; }

.editModal--form .areaManagement__label {
  margin: 20px 0 10px; }

.editModal--form .form__field--checkbox label {
  order: 2; }

.editModal--file-name {
  font-size: 12px;
  margin: 0 0 10px 5px; }

.editModal__topicality-categories {
  align-items: center;
  display: flex; }

.editModal__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px; }
  .editModal__actions .btn--green, .editModal__actions .btn--orange, .editModal__actions .btn--blue, .editModal__actions .btn--navy-blue, .editModal__actions .btn--navy-blue-stroke, .editModal__actions .btn--red, .editModal__actions .btn--cancel {
    width: auto; }

.hiddenRowsToggle {
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #707070; }
  .hiddenRowsToggle span {
    cursor: pointer; }
  .hiddenRowsToggle__switch {
    cursor: pointer;
    width: 40px;
    height: 20px;
    margin-left: 10px;
    display: inline-block;
    border: 1px solid #c9c9c9;
    border-radius: 10px; }
    .hiddenRowsToggle__switch_inner {
      width: 20px;
      height: 18px;
      border: 1px solid #fff;
      background-color: #efefef;
      border-radius: 10px;
      transition: 0.1s linear margin-left, background-color; }
    .hiddenRowsToggle__switch--active .hiddenRowsToggle__switch_inner {
      margin-left: 18px; }
      .dashboardDictionary--bhp .hiddenRowsToggle__switch--active .hiddenRowsToggle__switch_inner {
        background-color: #0080ff; }
      .dashboardDictionary--energy .hiddenRowsToggle__switch--active .hiddenRowsToggle__switch_inner {
        background-color: #ffaa00; }
      .dashboardDictionary--environment .hiddenRowsToggle__switch--active .hiddenRowsToggle__switch_inner {
        background-color: #32c832; }

.extendTextToggle {
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #707070; }
  .extendTextToggle span {
    cursor: pointer; }
  .extendTextToggle__switch {
    cursor: pointer;
    width: 40px;
    height: 20px;
    margin-left: 10px;
    display: inline-block;
    border: 1px solid #c9c9c9;
    border-radius: 10px; }
    .extendTextToggle__switch_inner {
      width: 20px;
      height: 18px;
      border: 1px solid #fff;
      background-color: #efefef;
      border-radius: 10px;
      transition: 0.1s linear margin-left, background-color; }
    .extendTextToggle__switch--active .extendTextToggle__switch_inner {
      margin-left: 18px; }
      .dashboardDictionary--bhp .extendTextToggle__switch--active .extendTextToggle__switch_inner {
        background-color: #0080ff; }
      .dashboardDictionary--energy .extendTextToggle__switch--active .extendTextToggle__switch_inner {
        background-color: #ffaa00; }
      .dashboardDictionary--environment .extendTextToggle__switch--active .extendTextToggle__switch_inner {
        background-color: #32c832; }

.instutitionElement,
.normElement {
  padding: 15px 25px;
  border-bottom: 2px solid #f6f6f6;
  font-family: "Montserrat", sans-serif; }
  .instutitionElement__name,
  .normElement__name {
    display: flex; }
    .instutitionElement__name span:first-of-type,
    .normElement__name span:first-of-type {
      padding-right: 5px; }
  .instutitionElement__block,
  .normElement__block {
    margin-bottom: 15px; }
    .instutitionElement__block:first-of-type,
    .normElement__block:first-of-type {
      margin-top: 15px; }
    .instutitionElement__block .label,
    .normElement__block .label {
      display: block;
      margin-bottom: 5px; }
    .instutitionElement__block--edit,
    .normElement__block--edit {
      text-align: right; }
      .instutitionElement__block--edit .btn,
      .normElement__block--edit .btn {
        display: inline-block; }

.device-management {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f8f8; }
  .device-management__container {
    font-family: "Open Sans", sans-serif;
    width: 100%;
    display: flex;
    flex-direction: column; }
  .device-management__header {
    height: 320px;
    position: relative; }
    @media (min-width: 756px) {
      .device-management__header {
        height: 320px; } }
    @media (max-width: 1665px) {
      .device-management__header {
        height: 280px; } }
  .device-management__logo-entire {
    position: absolute;
    bottom: 00px;
    width: 100%;
    text-align: center;
    display: block; }
    .device-management__logo-entire img {
      width: 180px;
      height: 59px; }
      @media (max-width: 1665px) {
        .device-management__logo-entire img {
          width: 180px;
          height: 49px; } }
    .device-management__logo-entire h1 {
      margin-top: 80px;
      font-size: 18px;
      font-weight: 900;
      color: rgba(0, 0, 0, 0.47);
      line-height: 24px; }
      @media (min-width: 756px) {
        .device-management__logo-entire h1 {
          font-size: 15px; } }
    .device-management__logo-entire p {
      font-family: "Montserrat", sans-serif;
      color: #838383;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      margin-top: 23px; }
  .device-management__cards-container {
    display: flex;
    margin: 40px auto 20px auto; }
    @media (max-width: 775px) {
      .device-management__cards-container {
        flex-direction: column; } }
  .device-management__card {
    position: relative;
    width: 285px;
    height: 308px;
    font-family: "Montserrat", sans-serif;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    padding: 85px 15px 0 15px; }
    .device-management__card:nth-of-type(odd) {
      margin: 0 16px; }
    @media (max-width: 775px) {
      .device-management__card {
        width: 280px;
        margin: 20px 0px; }
        .device-management__card:nth-of-type(odd) {
          margin: 0px; } }
    .device-management__card--add {
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.06); }
      .device-management__card--add svg {
        fill: #212748; }
    .device-management__card--selected {
      background-color: #efefef; }
    .device-management__card--disabled {
      background-color: #efefef; }
      .device-management__card--disabled svg {
        fill: #c1c1c1; }
    .device-management__card--limit-info {
      position: absolute;
      bottom: 15px;
      margin: 20px auto; }
    .device-management__card--input {
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #e2e2e2;
      height: 44px;
      text-align: center;
      display: flex;
      justify-content: center;
      justify-items: center; }
      .device-management__card--input input {
        border: none;
        outline: none;
        width: 255px;
        margin: 0 auto;
        color: rgba(0, 0, 0, 0.5);
        background-color: transparent; }
      .device-management__card--input input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500; }
      .device-management__card--input input:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500; }
      .device-management__card--input input::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500; }
      .device-management__card--input input::placeholder {
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500; }
    .device-management__card--os-name, .device-management__card--limit-info, .device-management__card--browser-name, .device-management__card--delete-info {
      color: rgba(0, 0, 0, 0.39);
      margin-top: 28px;
      font-size: 11px;
      font-weight: 400; }
    .device-management__card--device-name {
      color: #000;
      margin-top: 5px;
      font-size: 14px;
      line-height: 18px;
      font-weight: bold; }
    .device-management__card--browser-name {
      margin-top: 11px; }
    .device-management__card--delete-info {
      color: rgba(0, 0, 0, 0.77);
      font-size: 10px;
      margin-top: 20px; }
  .device-management__buttons {
    display: flex;
    flex-direction: column;
    width: 274px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center; }
    .device-management__buttons .btn--login {
      height: 44px;
      line-height: 44px;
      font-size: 14px; }
    .device-management__buttons .btn--cancel {
      color: #535353;
      font-size: 14px;
      font-weight: 400;
      margin-top: 10px; }
    .device-management__buttons .btn--disabled {
      background-color: #cccccc;
      color: #000; }
  .device-management__activations-limit, .device-management__activations-info, .device-management__activations-problems {
    color: #000;
    font-size: 11px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; }
    .device-management__activations-limit--disabled {
      color: #ff5454; }
  .device-management__activations-info {
    font-weight: 300;
    line-height: 14px;
    margin: 13px 0; }
  .device-management__activations-problems {
    font-weight: 300;
    line-height: 14px;
    margin-bottom: 15px; }
    .device-management__activations-problems span {
      font-weight: 600; }

.settings {
  padding: 50px 0 0 20px; }
  .settings__title {
    border-bottom: 4px solid transparent;
    padding: 10px 0px;
    margin-bottom: 20px;
    font: 700 30px/22px "Montserrat", sans-serif;
    color: #000;
    opacity: 0.55;
    cursor: pointer;
    margin: 0 9px 15px 9px;
    white-space: nowrap;
    border-bottom: 3px solid #2b2b2b;
    opacity: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .settings .areaManagement__form {
    margin-top: 40px; }
    .settings .areaManagement__form input,
    .settings .areaManagement__form textarea {
      border: 1px solid #efefef;
      border-radius: 3px; }

.regulations {
  padding: 35px 25px;
  overflow: auto;
  text-align: center; }
  .regulations__header {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px; }
  .regulations__paragraph {
    font-weight: 700;
    margin-bottom: 10px; }
  .regulations__title {
    font-weight: 700;
    margin-bottom: 10px; }
  .regulations__content {
    margin: 0 auto;
    max-width: 600px;
    text-align: left; }
    .regulations__content p {
      margin-bottom: 10px; }
    .regulations__content span {
      font-weight: 700; }
  .regulations__indent {
    margin-left: 25px; }

.pagination {
  font-family: Arial, Helvetica, sans-serif;
  margin: 5px 0 15px 0; }
  .pagination__list {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 0; }
  .pagination__list-element {
    width: 30px;
    height: 28px;
    color: #949494;
    display: flex;
    margin: 0 5px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer; }
    .pagination__list-element--active, .pagination__list-element:hover {
      background-color: #f6f6f6;
      color: #222; }
    .pagination__list-element--disabled {
      color: #e2e2e2; }
      .pagination__list-element--disabled:hover {
        background-color: #fff;
        color: #e2e2e2; }

.radioButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #707070;
  cursor: pointer; }
  .radioButton:hover input,
  .radioButton:hover label {
    cursor: pointer; }
  .radioButton input {
    margin: 0 8px 0; }

.new-row {
  background-color: #32c832;
  width: 30px;
  height: 16px;
  font-size: 9px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px; }
  .new-row--bhp {
    background-color: #0080ff; }
  .new-row--energy {
    background-color: #ffaa00; }
  .new-row--environment {
    background-color: #32c832; }

.demo-row {
  background-color: #ff5454;
  width: 36px;
  height: 16px;
  font-size: 9px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center; }

.compendiumSingle--environment .taskBox__heading-item {
  background-color: #32c832; }

.compendiumSingle--bhp .taskBox__heading-item {
  background-color: #0080ff; }

.compendiumSingle--energy .taskBox__heading-item {
  background-color: #ffaa00; }

.taskBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px; }
  .taskBox .tooltip {
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.01);
    color: #000;
    font-size: 12px;
    font-weight: 500; }
    .taskBox .tooltip:before, .taskBox .tooltip:after {
      display: none;
      background: #FFF; }
  .taskBox__add-evidence {
    display: flex;
    grid-gap: 20px;
    gap: 20px; }
    .taskBox__add-evidence .rating-select, .taskBox__add-evidence .form__field {
      width: 50%;
      background-color: #efefef;
      border-radius: 4px; }
    .taskBox__add-evidence .rating-select__menu {
      z-index: 9999999; }
    .taskBox__add-evidence .SingleDatePicker {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 19px; }
    .taskBox__add-evidence .form__field input {
      border: unset; }
    .taskBox__add-evidence .rating-select__single-value {
      font-size: 14px !important;
      font-weight: 500; }
  .taskBox__add-evidence-container {
    margin-top: 20px; }
  .taskBox__heading {
    display: flex;
    width: 100%;
    grid-gap: 8px;
    gap: 8px;
    margin-bottom: 14px; }
  .taskBox__heading-item {
    flex: 1 1;
    padding: 6px 20px;
    border-radius: 50px;
    color: #FFF;
    font-size: 14px;
    font-weight: 700; }
    .taskBox__heading-item--lp {
      flex: 0 0 64px; }
    .taskBox__heading-item--plan {
      flex: 2 1; }
    .taskBox__heading-item--responsibility {
      flex: 0.5 1;
      max-width: 200px;
      max-height: 56px; }
    .taskBox__heading-item--realization {
      flex: 0.5 1;
      max-width: 160px;
      max-height: 56px; }
    .taskBox__heading-item--action {
      flex: 0 0 170px;
      max-height: 56px; }
    .taskBox__heading-item--evidence {
      flex: 0 0 108px;
      max-height: 56px; }
    .taskBox__heading-item--status {
      flex: 0 0 171px;
      max-height: 56px; }
  .taskBox__addTask-container {
    display: flex;
    width: 100%;
    padding-top: 25px; }
    .taskBox__addTask-container .btn {
      max-width: 226px;
      border-radius: 50px;
      padding: 10px 21px; }
  .taskBox__addTask-form {
    display: flex;
    flex-direction: column;
    max-width: 707px;
    margin: 64px auto 0 auto; }
    .taskBox__addTask-form--top {
      display: flex;
      margin-bottom: 36px;
      grid-gap: 20px;
      gap: 20px; }
    .taskBox__addTask-form .DateInput {
      width: 150px !important; }
  .taskBox__addTask-form-field .css-1wa3eu0-placeholder {
    font-size: 14px !important;
    color: #626262; }
  .taskBox__addTask-form-field .form__field--datepicker {
    background-color: #efefef;
    padding: 4px 15px;
    border-radius: 4px !important; }
    .taskBox__addTask-form-field .form__field--datepicker input {
      font-size: 14px !important;
      border-bottom: 0px !important;
      color: #626262 !important; }
  .taskBox__addTask-form-field--files {
    display: flex;
    align-items: center; }
    .taskBox__addTask-form-field--files .fulfillment-proof--icon-file {
      margin-left: 6px; }
      .taskBox__addTask-form-field--files .fulfillment-proof--icon-file:first-child {
        margin-left: 0; }
  .taskBox__addTask-form-field--files-item {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    display: flex; }
    .taskBox__addTask-form-field--files-item svg {
      cursor: pointer;
      margin-left: 5px; }
  .taskBox__addTask-form-field .SingleDatePickerInput_clearDate {
    margin-right: 0 !important; }
  .taskBox__addTask-form-field--top {
    width: 100%; }
  .taskBox__addTask-form-field textarea {
    background-color: #efefef !important;
    height: 326px;
    border-radius: 4px !important;
    resize: none !important; }
  .taskBox__addTask-form-field--add-files {
    margin-top: 25px; }
  .taskBox__addTask-form-buttons {
    display: flex;
    margin-top: 60px; }
    .taskBox__addTask-form-buttons .btn {
      width: 50%; }
  .taskBox__modal-heading {
    text-align: center;
    color: #000;
    font-size: 18px;
    font-weight: 700; }
  .taskBox__modal-label {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px; }
  .taskBox__modal-description {
    color: #313131;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    max-width: 400px;
    margin: 58px 0 90px 0; }
  .taskBox__task {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    border-radius: 4px;
    background: #EFEFEF;
    padding: 9px 0;
    margin-top: 12px; }
    .taskBox__task svg {
      width: 15px; }
    .taskBox__task--done {
      color: #70AD47;
      display: flex;
      padding-top: 9px; }
      .taskBox__task--done svg {
        margin-left: 5px; }
  .taskBox__task-item {
    padding: 8px 20px;
    font-size: 14px;
    display: flex; }
    .taskBox__task-item svg {
      flex-shrink: 0; }
    .taskBox__task-item .css-2b097c-container {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    .taskBox__task-item .css-1hb7zxy-IndicatorsContainer {
      margin-right: 20px; }
    .taskBox__task-item .react-select__control {
      background-color: transparent !important;
      border: 0px !important; }
    .taskBox__task-item--lp {
      flex: 0 0 64px;
      max-height: 38px;
      display: flex;
      align-items: center; }
      .taskBox__task-item--lp svg {
        margin-left: 22px;
        padding-right: 0; }
    .taskBox__task-item--plan {
      flex: 2 1;
      padding-left: 0;
      font-weight: 500;
      flex-direction: column; }
    .taskBox__task-item--plan-item {
      cursor: pointer;
      padding: 0;
      word-break: break-all; }
    .taskBox__task-item--evidence-pdf {
      cursor: pointer;
      display: flex;
      margin-top: 20px; }
      .taskBox__task-item--evidence-pdf:nth-child(n + 1) {
        margin-left: 5px; }
      .taskBox__task-item--evidence-pdf svg {
        margin-right: 5px;
        width: 12px; }
    .taskBox__task-item--evidence-pdfs {
      display: flex; }
    .taskBox__task-item--evidence-comment {
      border-top: 1px solid #CECECE;
      margin-top: 16px;
      padding-top: 16px;
      display: flex;
      position: relative; }
      .taskBox__task-item--evidence-comment svg {
        position: absolute;
        left: -45px;
        width: 18px;
        height: 18px; }
    .taskBox__task-item--plan-collapse {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      height: 32px; }
      .taskBox__task-item--plan-collapse .taskBox__task-item--plan-item {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        height: 32px;
        word-break: unset; }
    .taskBox__task-item--responsibility {
      flex: 0.5 1;
      max-width: 200px;
      display: flex;
      border-right: 1px solid #CECECE;
      border-left: 1px solid #CECECE;
      align-items: center;
      font-weight: 500;
      max-height: 38px; }
      .taskBox__task-item--responsibility svg {
        margin-right: 9px; }
    .taskBox__task-item--realization {
      flex: 0.5 1;
      max-width: 160px;
      justify-content: center;
      border-right: 1px solid #CECECE;
      font-weight: 500;
      max-height: 38px;
      align-items: center; }
      .taskBox__task-item--realization .SingleDatePickerInput_clearDate {
        display: none !important; }
      .taskBox__task-item--realization .SingleDatePickerInput {
        padding-right: 0; }
      .taskBox__task-item--realization .form__field--date-picker-blank input {
        font-size: 14px !important; }
      .taskBox__task-item--realization .DateInput {
        width: 85px !important; }
      .taskBox__task-item--realization svg {
        margin-right: 10px;
        flex-shrink: 0;
        width: 12px; }
    .taskBox__task-item--action {
      flex: 0 0 170px;
      padding: 0;
      display: flex;
      justify-content: center;
      height: 40px; }
      .taskBox__task-item--action .btn {
        font-size: 14px;
        padding: 0 10px;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        border-radius: 0; }
        .taskBox__task-item--action .btn:first-child {
          border-right: 1px solid #CECECE;
          color: #707070; }
        .taskBox__task-item--action .btn:last-child {
          color: #F40000; }
    .taskBox__task-item--evidence {
      flex: 0 0 108px;
      display: flex;
      justify-content: space-around;
      border-right: 1px solid #CECECE;
      max-height: 38px;
      align-items: center; }
    .taskBox__task-item--status {
      flex: 0 0 171px;
      padding-top: 0;
      padding-bottom: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      padding-left: 0; }
      .taskBox__task-item--status .taskBox__task-item--action {
        flex: unset;
        margin-top: 30px; }

.notification-header {
  position: relative;
  margin-left: auto;
  height: auto;
  display: flex;
  align-items: center; }
  .notification-header__icon {
    padding: 6px 9px;
    margin-right: 44px;
    cursor: pointer;
    transition: 0.3s; }
    .notification-header__icon--active {
      transition: 0.3s;
      background-color: #fff; }
      .notification-header__icon--active .notification-popup__list {
        max-height: 100%; }
    .notification-header__icon svg {
      width: 24px;
      height: 24px; }
  .notification-header .tooltip {
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.15);
    color: #000;
    font-size: 12px;
    font-weight: 500; }
    .notification-header .tooltip:before, .notification-header .tooltip:after {
      display: none;
      background: #FFF; }

.notification-popup {
  background-color: #fff;
  position: absolute;
  top: 52px;
  right: 44px;
  padding: 0 16px;
  z-index: 9999999999;
  background: #FFF;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
  visibility: hidden;
  transition: 0.1s;
  font-size: 0;
  width: 590px; }
  .notification-popup--active {
    font-size: 16px;
    transition: 0.3s;
    visibility: visible;
    padding: 16px; }
  .notification-popup__header {
    padding-left: 18px;
    display: flex;
    justify-content: space-between; }
    .notification-popup__header span {
      color: #252D53;
      font-size: 20px;
      font-weight: 700; }
    .notification-popup__header .element {
      cursor: pointer; }
    .notification-popup__header svg {
      width: 20px;
      height: 20px; }
  .notification-popup__list {
    margin-top: 20px; }
  .notification-popup__day {
    color: #707070;
    font-size: 16px;
    font-weight: 600;
    padding-left: 18px;
    margin-bottom: 8px; }
  .notification-popup__item {
    padding: 16px 18px;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 8px; }
    .notification-popup__item:hover {
      background-color: #F4F4F4;
      transition: 0.3s; }
  .notification-popup__item-label {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding-right: 60px;
    position: relative; }
    .notification-popup__item-label--ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block; }
  .notification-popup__item-time {
    color: #707070;
    font-size: 16px;
    font-weight: 500; }
  .notification-popup__item-noti {
    position: absolute;
    width: 10px;
    border-radius: 50%;
    height: 10px;
    background-color: #FF002E;
    right: 0;
    top: 5px;
    z-index: 9; }

.new-tab {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 0px; }
  .new-tab .dashboard__header-item {
    width: 180px; }
  .new-tab__item {
    position: relative; }
  .new-tab .btn {
    margin-left: 24px; }
  .new-tab__modal h1 {
    text-align: center;
    color: #000;
    font-size: 18px;
    font-weight: 700; }
  .new-tab__modal .field-label {
    color: #000;
    font-family: 'Montserrat';
    font-size: 14px !important;
    font-weight: 700 !important; }
  .new-tab__modal .form__field input {
    background-color: #EFEFEF;
    border: 1px solid #EFEFEF; }
  .new-tab__modal .editModal__actions {
    justify-content: center !important;
    grid-gap: 20px;
    gap: 20px; }
  .new-tab__modal .btn {
    min-width: 262px; }

.icons-field__icons-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px; }

.icons-field__icon-row {
  width: calc(100% / 10);
  margin-bottom: 20px; }

.icons-field__icon {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer; }
  .icons-field__icon--active {
    border-radius: 4px;
    background: #EFEFEF; }

.icons-field__icon-check {
  top: -7px;
  right: -7px;
  position: absolute;
  width: 17px;
  height: 17px;
  background-color: #32C832;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }

.place-top {
  background-color: #fff !important; }
  .place-top:after {
    border-top-color: #fff !important; }

.tooltipLastActivity {
  display: flex;
  align-items: center;
  position: relative; }
  .tooltipLastActivity__light {
    color: #505050;
    font-size: 12px;
    font-weight: 400; }
  .tooltipLastActivity__bold {
    color: #505050;
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px; }
  .tooltipLastActivity__icon {
    left: 0;
    width: 10px;
    height: 10px;
    background-color: #32C832;
    border-radius: 50%;
    margin-left: -10px;
    margin-right: 6px; }

.compendiumCounters {
  display: flex;
  border-radius: 50px;
  background: #ECECEC;
  grid-gap: 5px;
  gap: 5px;
  padding: 5px;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px; }
  .compendiumCounters__item {
    display: flex;
    width: 61px;
    justify-content: space-evenly;
    border-radius: 20px;
    background-color: #fff;
    align-items: center; }
  .compendiumCounters__number {
    color: #000;
    font-size: 14px;
    font-weight: 600; }
  .compendiumCounters__icon {
    display: flex;
    align-items: center; }

.form__field {
  position: relative;
  margin: 0 auto 0 auto;
  font-family: "Lato", sans-serif; }
  .form__field input,
  .form__field select,
  .form__field textarea {
    font: 500 14px/18px "Montserrat", sans-serif;
    width: 100%;
    border: none;
    font-size: 14px;
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    background-color: #fff;
    outline: none; }
    @media (max-width: 1665px) {
      .form__field input,
      .form__field select,
      .form__field textarea {
        font-size: 12px; } }
    .form__field input option,
    .form__field select option,
    .form__field textarea option {
      color: #444; }
  .form__field textarea {
    resize: vertical;
    text-align: justify; }
  .form__field input,
  .form__field textarea {
    padding: 13px 15px;
    border-radius: 2px; }
    @media (max-width: 1665px) {
      .form__field input,
      .form__field textarea {
        padding: 8px 14px; } }
  .form__field--file {
    margin: 5px 0 10px; }
  .form__field .field-label {
    font-weight: 400;
    color: #1a1a1a;
    font-size: 12px;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 5px 2px; }
  .form__field .react-select__control {
    border-style: unset;
    height: 33px;
    background-color: #fff;
    margin: 5px 0 20px 0;
    border-radius: 2px;
    font-size: 12px !important;
    padding: 0 0 0 10px;
    line-height: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: rgba(26, 26, 26, 0.65); }
    .form__field .react-select__control:hover {
      border-color: #e9e6e6; }
    .form__field .react-select__control--is-focused {
      outline: none;
      box-shadow: none; }
  .form__field .react-select--is-multi__control {
    border-style: unset;
    border-bottom: 1px solid #e9e6e6;
    border-radius: 0;
    min-height: 24px;
    background-color: transparent; }
    .form__field .react-select--is-multi__control:hover {
      border-color: #e9e6e6; }
    .form__field .react-select--is-multi__control--is-focused {
      outline: none;
      box-shadow: none;
      min-height: 24px; }
  .form__field .react-select--is-multi__value-container {
    padding: 0;
    min-height: 24px;
    top: -7px; }
  .form__field .react-select__menu {
    text-align: left; }
  .form__field .react-select__value-container {
    padding: 0;
    min-height: 24px; }
  .form__field .react-select__indicators {
    position: relative; }
    .form__field .react-select__indicators img {
      height: 15px;
      margin-right: 3px; }
  .form__field .react-select__single-value {
    color: #212020;
    font-weight: 300;
    margin-left: 0;
    overflow: visible; }
  .form__field--date-picker-blank input {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #2b2b2b !important;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 17px; }
    @media (max-width: 1665px) {
      .form__field--date-picker-blank input {
        font-size: 15px; } }
  .form__field--default input,
  .form__field--default select,
  .form__field--default textarea {
    background-color: #fff !important;
    border-radius: 2px;
    border: none;
    height: 33px;
    width: 100%;
    font-size: 12px !important;
    padding: 19px 10px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    color: rgba(26, 26, 26, 0.65); }
    @media (max-width: 1665px) {
      .form__field--default input,
      .form__field--default select,
      .form__field--default textarea {
        font-size: 10px !important; } }
  .form__field--default textarea {
    height: 100px;
    padding: 10px 10px; }
  .form__field--gray input {
    background-color: #efefef;
    color: #000; }
  .form__field--error input,
  .form__field--error textarea,
  .form__field--error .react-select__control {
    border: 1px solid #e20d0d !important; }
  .form__field--error .react-select__input input {
    border: none !important; }
  .form__field--checkbox {
    height: 20px;
    display: flex;
    align-items: center;
    margin: 0px auto 25px auto;
    font-size: 14px;
    color: #969090; }
    .form__field--checkbox label {
      margin: 0 0 0 10px !important; }
  .form__field--disabled input[type='text']::-webkit-input-placeholder {
    color: #dcdbdb; }
  .form__field--disabled input[type='text']:-ms-input-placeholder {
    color: #dcdbdb; }
  .form__field--disabled input[type='text']::-ms-input-placeholder {
    color: #dcdbdb; }
  .form__field--disabled input[type='text']::placeholder {
    color: #dcdbdb; }
  .form__field .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%); }
  .form__field input[type='checkbox'] {
    width: 20px;
    height: 17px; }
  .form__field--label-error {
    font-family: "Lato", sans-serif;
    color: #e20d0d;
    margin-left: 3px;
    position: relative;
    -webkit-animation: show 0.25s ease-in;
            animation: show 0.25s ease-in; }

@-webkit-keyframes show {
  0% {
    right: -40px; }
  50% {
    right: 0px; }
  80% {
    right: -10px; }
  100% {
    right: 0px; } }

@keyframes show {
  0% {
    right: -40px; }
  50% {
    right: 0px; }
  80% {
    right: -10px; }
  100% {
    right: 0px; } }

.search__form input {
  height: 35px;
  border-radius: 5px;
  font-weight: 600; }
  @media (min-width: 768px) {
    .search__form input {
      min-width: 446px; } }
  @media (max-width: 1665px) {
    .search__form input {
      min-width: 356px; } }
  @media (max-width: 950px) {
    .search__form input {
      min-width: 180px; } }
  .search__form input::-webkit-input-placeholder {
    color: #000; }
  .search__form input:-ms-input-placeholder {
    color: #000; }
  .search__form input::-ms-input-placeholder {
    color: #000; }
  .search__form input::placeholder {
    color: #000; }
  @media (min-width: 375px) and (max-width: 767px) {
    .search__form input {
      font-size: 11px;
      line-height: 14px;
      padding-top: 10px;
      padding-bottom: 10px; }
      .search__form input::-webkit-input-placeholder {
        font-weight: 600;
        opacity: 0.23; }
      .search__form input:-ms-input-placeholder {
        font-weight: 600;
        opacity: 0.23; }
      .search__form input::-ms-input-placeholder {
        font-weight: 600;
        opacity: 0.23; }
      .search__form input::placeholder {
        font-weight: 600;
        opacity: 0.23; } }

.rating-option {
  display: flex;
  align-items: center; }
  .rating-option img, .rating-option svg {
    margin-right: 10px; }
  .rating-option svg {
    max-width: 16px;
    margin-top: -1px; }

.multi-filter.react-select__indicators {
  align-items: flex-start; }

.field-label {
  font-weight: 400;
  color: #1a1a1a;
  font-size: 12px;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 5px 2px; }

