.device-management {
  display: flex;
  // position: fixed;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f8f8;
  &__container {
    font-family: $fontOpenSans;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__header {
    height: 320px;
    position: relative;
    @include md {
      height: 320px;
    }
    @media (max-width: 1665px) {
      height: 280px;
    }
  }
  &__logo-entire {
    position: absolute;
    bottom: 00px;
    width: 100%;
    text-align: center;
    display: block;
    img {
      width: 180px;
      height: 59px;
      @media (max-width: 1665px) {
        width: 180px;
        height: 49px;
      }
    }
    h1 {
      margin-top: 80px;
      font-size: 18px;
      font-weight: 900;
      color: rgba(0, 0, 0, 0.47);
      line-height: 24px;
      @include md {
        font-size: 15px;
      }
    }
    p {
      font-family: $fontMontserrat;
      color: #838383;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      margin-top: 23px;
    }
  }
  &__cards-container {
    display: flex;
    margin: 40px auto 20px auto;
    @media (max-width: 775px) {
      // flex-wrap: wrap;
      flex-direction: column;
    }
  }
  &__card {
    position: relative;
    width: 285px;
    height: 308px;
    font-family: $fontMontserrat;
    background-color: $white;
    display: flex;
    flex-direction: column;

    align-items: center;
    border-radius: 4px;
    padding: 85px 15px 0 15px;

    &:nth-of-type(odd) {
      margin: 0 16px;
    }
    @media (max-width: 775px) {
      width: 280px;
      margin: 20px 0px;

      &:nth-of-type(odd) {
        margin: 0px;
      }
    }
    &--add {
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.06);
      svg {
        fill: $mianNavyBlue;
      }
    }
    &--selected {
      background-color: #efefef;
    }
    &--disabled {
      background-color: #efefef;
      svg {
        fill: #c1c1c1;
      }
    }
    &--limit-info {
      position: absolute;
      @extend .device-management__card--os-name;
      bottom: 15px;
      margin: 20px auto;
    }
    &--input {
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #e2e2e2;
      height: 44px;
      text-align: center;
      display: flex;
      justify-content: center;
      justify-items: center;

      input {
        border: none;
        outline: none;
        width: 255px;
        margin: 0 auto;
        color: rgba(0, 0, 0, 0.5);
        background-color: transparent;
      }
      input::placeholder {
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;
      }
    }
    &--os-name {
      color: rgba(0, 0, 0, 0.39);
      margin-top: 28px;
      font-size: 11px;
      font-weight: 400;
    }
    &--device-name {
      color: $black;
      margin-top: 5px;
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
    }
    &--browser-name {
      @extend .device-management__card--os-name;
      margin-top: 11px;
    }
    &--delete-info {
      @extend .device-management__card--os-name;
      color: rgba(0, 0, 0, 0.77);
      font-size: 10px;
      margin-top: 20px;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    width: 274px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    .btn {
      &--login {
        height: 44px;
        line-height: 44px;
        font-size: 14px;
      }
      &--cancel {
        color: #535353;
        font-size: 14px;
        font-weight: 400;
        margin-top: 10px;
      }
      &--disabled {
        background-color: rgba(204, 204, 204, 1);
        color: $black;
      }
    }
  }
  &__activations-limit {
    color: $black;
    font-size: 11px;
    font-family: $fontMontserrat;
    font-weight: 600;
    &--disabled {
      color: $red;
    }
  }
  &__activations-info {
    @extend .device-management__activations-limit;
    font-weight: 300;
    line-height: 14px;
    margin: 13px 0;
  }
  &__activations-problems {
    @extend .device-management__activations-limit;
    font-weight: 300;
    line-height: 14px;
    margin-bottom: 15px;
    span {
      font-weight: 600;
    }
  }
}
