.knowledgeBase {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: flex-end;
  overflow: auto;
  margin-bottom: 60px;
  width: 100%;

  &--news {
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 70px !important;
  }

  @media (min-width: 769px) {
    padding: 80px 55px 20px;
    max-width: 1140px;
    margin: 0 auto;
  }
  @media (min-width: 1200px) { 
    max-width: 100%;
    padding: 80px 150px 20px 150px;
    margin: unset;
  }
  @media (max-width: 1665px) {
    padding-top: 54px;
  }

  .title {
    @extend .title--main;
  }
  @media (min-width: 769px) {
    &--r {
      margin-bottom: 48px;
    }
  }
  @media (max-width: 768px) and (min-width: 376px) {
    padding: 35px 12px;
    &--l,
    &--r {
      width: 50%;
      height: 100%;
    }
    &--r {
      padding-left: 30px;
    }
  }
  @media (max-width: 375px) {
    flex-wrap: wrap;
    padding: 24px 8px;
    &--l,
    &--r {
      width: 100%;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 33px;
    position: relative;
    font-family: $fontInter !important;
    p {
      color: #072AC8;
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0.32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: $fontInter !important;
    }
    .actions {
      display: flex;
      justify-content: center;
      align-content: center;
      gap: 20px;
    }
    .goBack {
      cursor: pointer;
      position: absolute;
      top: 7px;
      left: -80px;
      display: flex;
      align-items: center;
      font-family: $fontInter;
      font-size: 14px;
      @media (max-width: 1200px) {
        left: 0px;
        top: 35px;
      }
    }
  }
  &__addTopicality {
    background-color: $mianNavyBlue;
    border: none;
    height: 36px;
    width: 100px;
    font: 700 17px/22px $fontMontserrat;
    color: $white;
    border-radius: 5px;
    cursor: pointer;
    flex-wrap: wrap;
    outline: none;
    @media (max-width: 1665px) {
      font-size: 14px;
      height: 36px;
      width: 90px;
    }
  }
  &--l {
    .title {
      @media (max-width: 375px) {
        padding-bottom: 15px;
        margin-bottom: 20px;
      }
      // border-bottom: 1px solid rgba($black, 0.13);
    }
  }
}

.title--main {
  font: 700 30px/37px $fontMontserrat;
  @media (max-width: 1665px) {
    font-size: 24px;
  }
  @media (max-width: 375px) {
    font-size: 17px;
    line-height: 20px;
  }
}

.knowledgePosts {
  position: relative;
  overflow: hidden;
  &__inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 100%;
    @media (max-width: 375px) {
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }
  @media (min-width: 769px) {
    margin: 0 -10px;
    &__top {
      display: flex;
    }
  }
  &__latest,
  &__column {
    .knowledgePost {
      max-width: 100%;
      img {
        width: 100%;
      }
    }
  }
  @media (min-width: 769px) {
    &__latest {
      width: 66.66667%;
    }
    &__column {
      width: calc(100% - 66.66667%);
    }
  }
}
.knowledgePost {
  margin-bottom: 30px;
  @media (min-width: 769px) {
    width: calc(100% / 4);
    padding: 0 10px;
  }
  @media (max-width: 375px) {
    max-width: 90%;
    min-width: 90%;
    margin-right: 15px;
  }
  a {
    text-transform: none;
    text-decoration: none;
    color: $black;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__image  {
    img {
      min-height: 190px;
      max-height: 190px;
      border-radius: 10px;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    &--big {
      max-height: unset !important;
    }
  }
  &__title {
    font: bold 18px/23px $fontInter;
    margin: 8px 0;
    margin-bottom: 12px;
    @media (max-width: 1665px) {
      line-height: 27px;
      font-size: 20px;
    }

  }
  &__date {
    margin-top: 20px;
    color: #3A405D;
    font-family: $fontInter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.28px;
  }
  &__readMore {
    color: #072AC8;
    font-family: $fontInter;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.32px;
    display: flex;
    align-items: center;
    gap:7px;
    margin-top: auto;
  }
  &--infinite {
    margin-bottom: 0;
    padding-top: 50px;
    margin-top: 50px;
    &:before {
      content: '';
      border-top: 1px solid #CCCCCC;
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      margin-top: -50px;
    }
  }
  &__vertical {
    display: flex !important;
    flex-direction: row !important;
    gap: 20px;
    .knowledgePost__image {
      width: 330px;
      @media (max-width: 1400px) { 
        width: 200px;
      }
    }
    .knowledgePost__date {
      margin-top: unset;
    }
    .knowledgePost__left {
      @media (max-width: 1400px) { 
        width: 200px;
      }
    }
  }
  &__content {
    color: #3A405D;
    font-size: 16px;
    font-family: $fontInter;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.32px;
    margin-bottom: 16px;
    margin-top: -10px;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dwie kolumny o równych szerokościach */
  gap: 20px; /* Odstępy między kolumnami */
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Odstępy między elementami w kolumnie */
}

.item {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  /* Możesz dodać inne style, np. różne wysokości */
}

.columnCount {
  column-count: 2;
}
.columnCount > * {
  break-inside: avoid;
}
.currentNews {
  @media (max-width: 375px) {
    margin-top: 22px;
  }
  .pagination {
    display: none;
  }
  .currentPost {
    padding: 20px 80px 20px 0;
    // width: calc(50% - 8px);
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    &__title {
      font-family: $fontInter;
      display: flex;
      flex-direction: column;
      p {
        font-family: $fontInter;
        font-size: 11px;
        margin-left: 5px;
        color: #989797;
      }
    }
    .link {
      display: flex;
      text-transform: none;
      text-decoration: none;
      color: $black;
      &--active {
        cursor: pointer;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 15px;
    }
    &__editPost {
      border: none;
      outline: none;
      height: 20px;
      background-color: $white;
      &:hover {
        cursor: pointer;
      }
    }
    &__deletePost {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      &:hover {
        background-color: #e6e6e6;
        svg {
          fill: $red;
          cursor: pointer;
        }
      }
      svg {
        fill: $sharpGray;
      }
    }
    &__title {
      font: 400 20px/24px $fontInter;
      color: $black;
      padding-left: 10px;
      @media (max-width: 1665px) {
        font-size: 14px;
      }
    }
    &__tag {
      padding: 4px 7px;
      display: inline-block;
      border-radius: 5px;
      color: #fff;
      font: 600 14px/19px $fontOpenSans;
      &--blue {
        background-color: $blue;
      }
      &--orange {
        background-color: $orange;
      }
      &--green {
        background-color: $green;
      }
      &--gray {
        background-color: $gray100;
      }
      &_wrapper {
        min-width: 45px;
        text-align: center;
      }
      @media (max-width: 1665px) {
        padding: 2px 4px;
      }
    }
  }
}

.currentPost {
  &__textarea .form__field textarea{
    min-height: 150px;
  }
}


.knowledgePostsTop {
  min-height: 702px;
  display: flex;
  @media(max-width: 1400px) {
    min-height: 500px;
  }
  &__first .knowledgePost {
    width: 100%;
  }
  &__left {
    width: 50%;
  }
  &__right {
    width: 50%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .knowledgePost {
      width: 100%;
      margin-bottom: 0 !important;
      &--infinite {
        padding-top: 24px !important;
        margin-top: 24px !important;
        &:before {
          margin-top: -24px;
        }
      }
    }
  }
}

.coupled {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 16px;
  .column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .currentPost {
    width: unset;
  }
}