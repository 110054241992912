table {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  width: 100%;
  padding: 0 15px;
  table-layout: fixed;
  font-family: $fontLato;
  //   @media screen and (max-width: 1200px) {
  //     table-layout: unset;
  //   }
  thead {
    height: 35px;
    line-height: 20px;
    border-radius: 3px;
    tr {
      th {
        border-bottom: 1px solid #707070;
        font-size: 12px;
        font-weight: 600;
        color: #2e2e2e;
        padding: 0 3px 13px 10px;
        // white-space: nowrap;
        text-align: left;
        // word-wrap: break-word;
        white-space: nowrap;
        input {
          background-color: #f3f3f3;
          border-radius: 6px;
          border: none;
          height: 22px;
          width: 100%;
          margin-top: 10px;
          padding: 0 7px;
          line-height: 22px;
          outline: none;
        }
      }
      .actions {
        width: 130px;
      }
      .remind {
        width: 235px;
      }
    }
  }
  tbody {
    &:before {
      content: '';
      margin-top: 5px;
      display: block;
    }
    // .hoverable {
    //   &:hover {
    //     background-color: #f1f1f1bf;
    //     cursor: pointer;
    //   }
    // }
    tr {
      // &:hover {
      //   background-color: #f0f0f0d6;
      // }
      //   box-shadow: -1px 2px 1px rgba(60, 60, 60, 0.24), 1px 1px 0 rgba(60, 60, 60, 0.24);
      height: 40px;
      line-height: 15px;
      border-radius: 4px;
      &:last-of-type {
        td {
          border: none;
        }
      }
      .action-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        .fa-headphones-alt {
          font-size: 1.5rem;
          color: #bab7b7;
          margin-right: 10px;
          &:hover {
            color: #727ce5;
          }
        }
        .fa-heart {
          font-size: 1.5rem;
          color: #bab7b7;
          margin-right: 10px;
        }
        .fa-download {
          font-size: 1.2rem;
          color: #bab7b7;
        }
      }
    }

    td {
      //   vertical-align: middle;
      padding: 2px 15px 25px 15px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.22);
      //   border-right: 1px solid rgba(112, 112, 112, 0.22);
      position: relative;
      font-size: 12px;
      font-weight: bold;
      color: #2e2e2e;
      //   @media screen and (max-width: 1200px) {
      //     white-space: nowrap;
      //   }
      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          right: 0;
          z-index: 100;
          top: 0;
          // transform: translateY(-50%);
          width: 1px;
          height: 85%; /* or 100px */
          background: rgba(112, 112, 112, 0.22);
          &:last-of-type {
            display: none;
          }
        }
      }
    }
    .actions {
      vertical-align: -webkit-baseline-middle;
    }
    .case {
      color: $darkBlue;
    }
    .remind {
      font-weight: 400;
      line-height: 19px;
    }
  }
}

.ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  // border: 1px solid rgba(0, 0, 0, 0.1);
}
.ReactTable * {
  box-sizing: border-box;
  font-family: $fontMontserrat;
  font-size: 13px;
  line-height: 18px;
  // color: $black;
  @media (max-width: 1665px) {
    font-size: 11px;
  }
}
.ReactTable .rt-table {
  -webkit-box-flex: 1;
  -ms-flex: auto 1;
  flex: auto 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: visible;
}
.ReactTable .rt-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
}
// .ReactTable .rt-thead.-filters .rt-th {
//   // border-right: 1px solid rgba(0, 0, 0, 0.02);
// }
.ReactTable .rt-thead.-header {
  // box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid rgba($black, 0.13);
  // margin-bottom: 12px;
  font-weight: 700;
  .rt-th {
    text-align: left;
  }
}
.ReactTable .rt-thead .rt-tr {
  text-align: center;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  // border-right: 1px solid rgba(0, 0, 0, 0.05);
  // transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  // box-shadow: inset 0 0 0 0 transparent;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}
.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-thead .rt-th:focus {
  outline-width: 0;
}
.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}
.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}
.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}
.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}
.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}
.ReactTable .rt-tbody .rt-tr-group {
  // border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 4px;
  background-color: $grayLight;
  border-radius: 2px;

  //nasza zmiana
  position: relative;
}
.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
// .ReactTable .rt-tbody .rt-td {
//   // border-right: 1px solid rgba(0, 0, 0, 0.02);
// }
.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}
.ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;

  &--new-post,
  &--new-post-editable {
    &::before {
      position: absolute;
      content: 'N';
      text-align: center;
      font-size: 13px;
      bottom: 1px;
      left: 15px;
      width: 30px;
      height: 20px;
      z-index: 10;
      // background-color: #ff000073;
      border-radius: 5px;
      border: 1px solid #000;
    }
  }

  &--new-post-editable {
    &::before {
      left: 45px;
    }
  }
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px 15px;
  line-height: 26px;
  overflow: visible;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
  .rating__container {
    display: flex;
    align-items: center;
    height: 40px;
    &__icons {
      display: flex;
      align-items: center;
    }
    &:nth-child(n + 2) {
      display: none;
    }
    .grey-face {
      margin:0 15px;
      width: 25px;
      height: 25px;
    }
    img {
      width: 25px;
    }
    svg {
      margin: 0 -10px;
    }
  }
}
.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}
.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}
.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}
.ReactTable .rt-tfoot {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}
.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}
.ReactTable .-pagination {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
}
.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline-width: 0;
}
.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}
.ReactTable .-pagination .-center {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.ReactTable .-pagination .-pageJump {
  display: inline-block;
}
.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}
.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}
.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 7px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}
.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}
.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ///
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cell--centered {
  text-align: center;
  font-size: 21px;
  line-height: 25px;
}

.dictionaryTable {
  // margin: 0 15px;
  // .cell--centered {
  //   text-align: center;
  //   font-size: 21px;
  // }
  .rt-tbody {
    max-height: calc(100vh - 308px);
    min-width: 50px;
  }
}

.ReactTable {
  .react-select__menu {
    z-index: 99999;
  }
  &.extensionRatingIcons {
    .rating__container {
      background-color: unset;
    }
    .rating__container__icons {
      min-width: 100px;
    }
  }
  &.bhpTable {
    .css-2b097c-container,  .css-2b097c-container {
      position: absolute;
      width: calc(100% - 10px);
    }
    &--dictionaries,
    &--institutions,
    &--norms,
    &--compendiums {
      margin: 0 20px;
      min-height: 250px;
    }
    .rt-thead {
      .rt-th {
        // padding-bottom: 9px;
        padding-left: 0;
        padding-right: 10px;
      }
      .rt-resizable-header-content {
        padding: 6px 15px;
        border-radius: 15px;
        background-color: $inputGray;
      }
    }
    .rt-td {
      white-space: pre-line;
      padding: 9px 15px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.cell {
        &--noPadding {
          padding-left: 0;
          padding-right: 0;
          text-align: center;
        }
        &--white {
          background-color: #fff;
        }
      }
    }
    .sticky {
      position: sticky !important;
      left: 0;
      top: 0;
      z-index: 1;
      &--hidden {
        background-color: #fff;
        .rt-resizable-header-content {
          background-color: #fff;
          cursor: none;
        }
      }
      &--0 {
        left: 0px;
      }
      &--20 {
        left: 30px;
      }
      &--200 {
        left: 200px;
      }
      &--220 {
        left: 230px;
      }
      &--cell {
        background-color: $grayLight;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &--header {
        background-color: #fff;
        // &_last {
        //   box-shadow: 5px 0px 5px 0px rgb(255, 255, 255);
        // }
      }
      &--last {
        // box-shadow: 5px 0px 5px 0px rgb(255, 255, 255);
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          // width: 12px;
          width: 4px;
          background-color: #fff;
          height: 100%;
        }
      }
    }
    .rt-tbody {
      max-height: calc(100vh - 280px);
    }
    &--compendiums {
      .rt-tbody {
        overflow: visible !important;
        max-height: calc(100vh - 300px);
        @media (max-width: 1665px) {
          max-height: calc(100vh - 300px);
        }
      }
      .rt-thead {
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 99;
        &:first-of-type {
          top: 0;
          margin-bottom: 0;
        }
        &:nth-of-type(2) {
          // padding-top: 12px;
          top: 42px;
        }
      }
      .rt-table {
        overflow: auto;
      }
    }
  }
}

.extendText {
  white-space: pre-line;
  text-align: justify;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &--btn {
    font-family: $fontMontserrat;
    color: $gray100;
    cursor: pointer;
    @extend .font-xs;
    margin-top: 10px;
    font-weight: 700;
  }
}
.bigText {
  @extend .font-lg;
  font-family: $fontMontserrat;
  font-weight: 700;
  text-align: center;
}

.extendedView {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 170px;
  &:first-child {
    &--new-post,
    &--new-post-editable {
      &::before {
        position: absolute;
        content: 'N';
        text-align: center;
        font-size: 13px;
        bottom: 1px;
        left: 15px;
        width: 30px;
        height: 20px;
        z-index: 10;
        // background-color: #ff000073;
        border-radius: 5px;
        border: 1px solid #000;
      }
    }
  }

  &--identifier {
    font-size: 10px;
    font-weight: 600;
  }
  &--btn {
    margin-top: 15px;
    margin-bottom: 5px;
    @extend .font-xs;
    cursor: pointer;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    justify-content: center;
    background-color: $inputGray;
    border-radius: 7px;
    height: 30px;
    width: 135px;
    span {
      margin-right: 10px;
    }
    svg {
      fill: $blue;
      width: 30px;
      height: 15px;
      margin-left: -3px;
    }
    &:hover {
      background-color: #eae9e9;
    }
  }
  &--bhp {
    border: 2px solid $blue;
    svg {
      fill: $blue;
    }
  }
  &--energy {
    border: 2px solid $orange;
    svg {
      fill: $orange;
    }
  }
  &--environment {
    border: 2px solid $green;
    svg {
      fill: $mainGreen;
    }
  }
}
.rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__container {
    border-radius: 15px;
    background-color: $inputGray;
    height: 30px;
    display: flex;
    align-items: center;
    // width: 160px;
    padding-right: 15px;
    font-weight: 600;
    // justify-content: center;
    img {
      margin: 0 15px;
    }
  }
  &--files {
    width: 14px;
    margin-right: auto;
    margin-left: 10px;
  }
  &--stars {
    background-color: $inputGray;
    border-radius: 15px;
    padding: 6px 18px;
    display: inline-block;
    span {
      cursor: pointer;
      font-size: 20px;
    }
    &--red {
      span.active-hover,
      span.active-rating {
        color: $red;
      }
    }
    &--orange {
      span.active-hover,
      span.active-rating {
        color: $orange;
      }
    }
    &--green {
      span.active-hover,
      span.active-rating {
        color: $green;
      }
    }
  }
  &--document {
    display: flex;
    margin-left: 5px;
  }
  &--alerts {
    margin-left: 30px;
    display: flex;
    align-items: center;
    &__single {
      width: 30px;
      line-height: 30px;
      text-align: center;
      background-color: $inputGray;
      border-radius: 15px;
      svg {
        display: inline-block;
      }
    }
  }
  &--i {
    margin: 0 5px;
  }
}
.logins-amount {
  font-weight: 700;
  height: 27px;
  width: fit-content;
  display: flex;
  align-items: center;
  svg {
    margin: 1px 14px 0 0;
  }
  &--expired-date {
    color: $gray100;
    svg {
      fill: $gray100;
    }
  }
}

.edit--cell {
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.table--actions {
  position: relative;
}

.detailedHeader {
  &__star {
    color: $blue;
    font-size: 16px;
    margin-right: 2px;
  }
}
.uppercase {
  text-transform: uppercase;
}
.react-select {
  &__control {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 3px !important;
    min-height: 30px !important;
    margin-left: 0px;
    &--is-focused {
      box-shadow: none !important;
    }
  }
  &__value-container {
    padding: 1px 8px !important;
  }
  &__indicator {
    padding: 1px !important;
  }
  &__indicator-separator {
    display: none;
  }
  &__clear-indicator {
    padding: 4px !important;
  }
  &__menu {
    z-index: 1000 !important;
    position: sticky !important;
    text-align: left;
  }
}
.multi-filter.react-select__control {
  overflow: auto;
  max-height: 50px;
}

.rt-thead .rt-resizable-header-content {
  text-transform: uppercase;
  text-align: center;
}

.ReactTable {
  .rt-td {
    white-space: pre-line;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  z-index: 105;
  background-color: $gray100;
  height: 60px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 0.3;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
  .svg {
    width: 25px;
    height: 20px;
  }
  // &__left {
  //   left: 0;
  // }
  &__right {
    right: 0;
    transform: rotate(180deg);
  }
}
.scrollable {
  .rt-table {
    overflow: auto !important;
  }
}
.cell--visibility {
  .btn {
    color: inherit;
  }
}

.table__noData {
  margin-top: 40px;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  color: #707070;
}

.badge-wrapper {
  display: flex;
}
