.settings {
  padding: 50px 0 0 20px;
  &__title {
    border-bottom: 4px solid transparent;
    padding: 10px 0px;
    margin-bottom: 20px;
    font: 700 30px/22px $fontMontserrat;
    color: $black;
    opacity: 0.55;
    cursor: pointer;
    margin: 0 9px 15px 9px;
    white-space: nowrap;
    @extend .font-big;
    border-bottom: 3px solid #2b2b2b;
    opacity: 1;
    width: fit-content;
  }
  .areaManagement__form {
    margin-top: 40px;
    input,
    textarea {
      border: 1px solid $inputGray;
      border-radius: 3px;
    }
  }
}
