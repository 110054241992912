.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
  &__container {
    font-family: $fontLato;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  &__header {
    height: 320px;
    position: relative;
    @include md {
      height: 390px;
    }
    @media (max-width: 1665px) {
      height: 260px;
    }
  }
  &__logo {
    position: absolute;
    margin-top: 41px;
    margin-left: 17px;
    @include md {
      margin-left: 45px;
    }
    img {
      width: 44px;
      height: 45px;
    }
  }
  &__logo-entire {
    position: absolute;
    bottom: 00px;
    width: 100%;
    text-align: center;
    display: block;
    img {
      width: 180px;
      height: 59px;
      @media (max-width: 1665px) {
        width: 180px;
        height: 49px;
      }
    }
    h1 {
      margin-top: 30px;
      text-transform: uppercase;
      font-size: 17px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.47);
      @include md {
        font-size: 15px;
      }
    }
  }
  &__form-container {
    width: 100%;
    padding: 10px 0 0 0;
    display: flex;
    justify-content: center;
  }
  &__form {
    width: 310px;
    .form__field {
      input {
        height: 44px;
        padding: 5px 10px;
        // margin: 12px 0 20px 0;
        border-radius: 2px;
      }
    }
    .btn {
      margin-top: 30px;
    }
    @media (max-width: 1665px) {
      width: 250px;
    }
  }
  &__forget-password {
    float: right;
    margin: -10px 0 50px 0;
    font-size: 15px;
    font-weight: 400;
    color: $darkBlue;
  }
}
