.compendiumSingle {
  padding: 35px 25px;
  max-height: calc(100vh - 110px);
  overflow: auto;
  &__inner {
    padding: 25px;
    @media (min-width: 768px) {
      padding: 25px 75px;
    }
    background-color: $gray300;
  }
  &__content {
    display: flex;
    // max-width: 1260px;
    flex-wrap: wrap;
    .infoBox {
      width: 100%;
    }
  }
  &__header {
    display: flex;
    margin-bottom: 25px;
    &--backButton {
      button {
        font-family: $fontMontserrat;
        @extend .font-md;
        @extend .bold-700;
        opacity: 0.55;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    &--title {
      margin-left: 25px;
      h1 {
        font-family: $fontMontserrat;
        @extend .font-big;
        margin-bottom: 5px;
      }
      h3 {
        font-family: $fontMontserrat;
        @extend .bold-700;
        @extend .font-sm;
      }
    }
  }
  .infoBox {
    &__star {
      color: #fff;
    }
    &__badge {
      background-color: #fff;
      a {
        color: $black;
      }
      svg {
        fill: $black;
      }
    }
    &__title {
      color: #fff;
    }
  }
  &--bhp {
    .infoBox__header,
    .ratingBox__header {
      background-color: $blue;
    }
  }
  &--energy {
    .infoBox__header,
    .ratingBox__header {
      background-color: $orange;
    }
  }
  &--environment {
    .infoBox__header,
    .ratingBox__header {
      background-color: $mainGreen;
    }
  }
}

.infoBox {
  margin-bottom: 40px;
  &__header {
    background-color: $inputGray;
    display: flex;
    align-items: center;
    padding: 5px 7px;
    border-radius: 15px;
    margin-bottom: 25px;
    position: relative;
  }
  &__title {
    @extend .font-sm;
    font-family: $fontMontserrat;
  }
  &__badge {
    margin-left: auto;
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    background-color: $blue;
    color: #fff;
    padding: 0px 15px 0 20px;
    border-radius: 15px;
    @extend .font-sm;
    font-family: $fontMontserrat;
    line-height: 26px;
    &:hover {
      cursor: pointer;
      background-color: $gray200;
    }
    a {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      svg {
        fill: #fff;
        margin-left: 10px;
        margin-top: -2px;
        display: block;
      }
    }
  }
  &__star,
  &__title {
    margin-left: 7px;
  }
  &__star {
    color: $blue;
    font-size: 18px;
    line-height: 1;
  }
  &__content {
    white-space: pre-line;
    padding: 0 14px;
    font-family: $fontMontserrat;
    text-align: justify;
    @extend .font-sm;
    @extend .bold-500;
    &--bolded {
      @extend .bold-700;
    }
  }
  &--mobile {
    .infoBox {
      &__content {
        padding: 10px 20px;
        @extend .font-xs;
        background-color: $gray300;
      }
      &__header {
        margin: 0 15px 25px;
      }
    }
    .ratingBox__wrapper {
      padding: 0 20px;
    }
  }
}

.fulfillment-proof {
  font-family: $fontMontserrat;
  background-color: $inputGray;
  padding: 15px;
  // max-height: 500px;
  &__header {
    display: flex;
    margin-bottom: 10px;
    font-weight: bold;
    svg {
      width: 11px;
      height: 14px;
      margin-right: 10px;
    }
  }
  &--input {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 !important;
    width: 16px !important;
    height: 16px;
    z-index: 99999;
    opacity: 0;
    cursor: pointer;
  }
  &--left {
    display: flex;
  }
  &--right {
    display: flex;
    margin-left: auto;
    &--disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  &--file {
    display: flex;
  }
  &--icon-file {
    cursor: pointer;
    position: relative;
    z-index: 9;
  }
  &--file-name {
    font-size: 12px;
    margin-right: 10px;
    max-width: 110px;
    cursor: pointer;
    transition: 0.3s;
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis ellipsis;
    }
    div {
      display: none;
    }
    &:hover {
      transition: 0.3s;
      p {
        display: none;
      }
      div {
        display: flex;
      }
    }
  }
  &__content {
    font-weight: 300;
  }
  &__text {
    background-color: $inputGray;
    padding: 10px;
    width: 100%;
    word-break: break-word;
  }
}

.rating-select {
  &__control {
    font-family: $fontMontserrat;
    background-color: $inputGray !important;
    border: none !important;
    outline: none !important;
  }
  &__single-value {
    color: $black;
    font-weight: bold;
  }
  &--disabled {
    pointer-events: none;
  }
}

.row-action {
  display: flex;
  align-items: center;
  &--close {
    display: flex;
    align-items: center;
    margin-left: 7px;
    height: 16px;
    width: 16px;
    cursor: pointer;
    svg path {
      transition: 0.3s;
      fill: grey;
    }
    &:hover {
      svg path {
        transition: 0.3s;
        fill: rgb(41, 41, 41);
      }
    }
  }
}

.ratingBox {
  padding: 7px 14px 7px;
  background-color: $inputGray;

  border-radius: 2px;
  &__blockedToday {
    background-color: #FFD5D5;
    font-family: $fontMontserrat;
    font-size: 14px;
    font-weight: 600;
    padding: 11px;
    display: flex;
    align-items: center;
    gap: 19px;
    margin: 0 5px;
    visibility: hidden;
    transition: 0.2s;
    max-height: 0px;
    color: transparent;
    .svg {
      visibility: hidden;
      height: 0;
      width: 0;
    }
    &.active {
      color: #000;
      visibility: visible;
      transition: 0.2s;
      max-height: 100px;
      .svg {
        visibility: visible;
        width: 25px;
        height: 25px;
      }
    }
  }
  &__left,
  &__right {
    width: calc(100% - 5px);
    @include rwd(1200px) {
      width: calc(50% - 5px);
    }

  }
  &__right--disabled {
    pointer-events: none;
  }
  &__left {
    margin-right: 7px;
  }
  &__header {
    height: 30px;
    margin-top: 20px;
    border-radius: 10px;
    font-family: $fontMontserrat;
    font-size: 14px;
    color: $white;
    padding-left: 13px;
    font-weight: 400;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }
  &__container {
    display: flex;
    .ratingBox__content {
      width: calc(50% - 5px);
      margin-right: 5px;
    }
    .ratingBox__organizationalUnit {
      width: calc(50% - 5px);
      .ratingBox__content {
        width: 100%;
        input {
          border: 1px solid #e3e3e3;
        }
      }
    }
    .ratingBox__header {
      width: 100%;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    // .left,
    &--left,
    &--right {
      width: calc(100% - 5px);
      // @include rwd(650px) {
      //   width: calc(50% - 5px);
      // }
    }
    &--left {
      margin-right: 7px;
      margin-bottom: 20px;
      @include rwd(650px) {
        margin-bottom: 0px;
      }
    }
    &--right {
      margin-top: 10px;
    }
  }
  // &__organizationalUnit {
  //   margin-bottom: 20px;
  //   .ratingBox__header {
  //     margin-top: 10px;
  //   }
  // }
  &__other-input {
    border: none;
    outline: none;
    height: 42px;
    background-color: $inputGray;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
    border-radius: 3px;
    font-family: $fontMontserrat;
    font-size: 11px;
    font-weight: 400;
  }
  &__date-picker {
    display: flex;
    .form__field {
      margin: 0 auto 0 0;
    }
    &--full {
      width: 100%;
      display: flex;
    }
    &--disabled {
      cursor: auto;
    }
    // justify-content: center;
    // padding: 20px 0 45px 0;
  }
  &__raw-select {
    width: 100%;
    height: 42px;
    background-color: $inputGray;
    display: flex;
    align-items: center;
    font-family: $fontMontserrat;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
    svg {
      margin: 0 15px;
    }
  }
  &__face-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    &--hover {
      &:hover {
        .ratingBox__face-label,
        .ratingBox__face-date {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
  &__face-label,
  &__face-date {
    width: 50%;
    margin: 10px 5px;
    height: 42px;
    display: flex;
    align-items: center;
    background-color: $inputGray;
    font-family: $fontMontserrat;
    font-size: 12px;
    font-weight: 700;
    opacity: 0.4;
    padding: 0 15px;
    img {
      margin-right: 15px;
    }
    &--active {
      opacity: 1;
    }
    &--periodicity {
      height: 40px !important;
    }
  }
  &__face-label--hover {
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  &__face-label {
    cursor: pointer;
  }

  ///////
  &:nth-of-type(even) {
    margin-left: 5px;
  }
  &:nth-of-type(odd) {
    margin-right: 5px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    @include rwd(1200px) {
      align-items: flex-start;
      flex-direction: row;
      margin: 50px 0;
      min-height: 310px;
    }
  }
  // &__header {
  //   display: flex;
  //   align-items: center;
  //   height: 30px;
  //   // margin-bottom: 15px;
  // }
  &__title {
    font-family: $fontMontserrat;
    width: 100%;
    font-weight: 400;
    @extend .font-sm;
    // margin-left: 7px;
    .rating--stars {
      padding: 2px 0 0 0;
    }
    .rating--label {
      font-size: 12px;
    }
  }
  &__risk {
    @extend .font-md;
    font-family: $fontMontserrat;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: $borderGray;
    span {
      cursor: pointer;
    }
    &--switch {
      cursor: pointer;
      width: 40px;
      height: 20px;
      margin-right: 13px;
      display: inline-block;
      border: 1px solid $gray100;
      border-radius: 10px;
      &_inner {
        width: 20px;
        height: 18px;
        border: 1px solid $gray100;
        background-color: $inputGray;
        border-radius: 10px;
        transition: 0.1s linear margin-left, background-color;
      }
      &--active {
        .ratingBox__risk--switch_inner {
          margin-left: 18px;
          background-color: $red;
        }
      }
    }
  }
  .svg--file {
    width: 11px;
  }
  &__note--blank,
  &__content--blank {
    opacity: 0.5;
  }
  &__note {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    &-title {
      font-family: $fontMontserrat;
      margin-left: 7px;
      @extend .font-xs;
      font-weight: 600;
    }
  }
  &__content {
    @extend .font-xs;
    font-family: $fontMontserrat;
    font-size: 11px;
    padding-bottom: 3px;
    textarea {
      font-family: $fontMontserrat;
      font-size: 13px;
      width: 100% !important;
      min-height: 100px;
      resize: vertical;
      border: none;
      border-radius: 2px;
      color: #595959;
      outline: none;
      padding: 10px 10px;
      // resize: none;
      max-height: 500px;
    }
    textarea::placeholder {
      color: $sharpGray;
    }
    input {
      font-family: $fontMontserrat;
      font-size: 13px;
      width: 100% !important;
      border: none;
      border-radius: 2px;
      color: #595959;
      outline: none;
      padding: 10px 10px;
    }
    input:disabled {
      background-color: $inputGray;
    }
  }
}


.periodicity {
  cursor: pointer;
  height: 30px;
  .form__field .react-select__control {
    margin: 0;
  }
  .css-2b097c-container {
    margin-top: -3px;
  }
  .rating-select__control--is-focused {
    box-shadow: none;
  }
  .rating-select {
    width: 100%;
  }
}