.place-top {
  background-color: #fff !important;
  &:after {
    border-top-color: #fff !important;
  }
}

.tooltipLastActivity {
  display: flex;
  align-items: center;
  position: relative;
  &__light {
    color: #505050;
    font-size: 12px;
    font-weight: 400;
  }
  &__bold {
    color: #505050;
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
  }
  &__icon {
    left: 0;
    width: 10px;
    height: 10px;
    background-color: #32C832;
    border-radius: 50%;
    margin-left: -10px;
    margin-right: 6px;
  }
}