.notification-header {
  position: relative;
  margin-left: auto;
  height: auto;
  display: flex;
  align-items: center;
  &__icon {
    padding: 6px 9px;
    margin-right: 44px;
    cursor: pointer;
    transition: 0.3s;
    &--active {
      transition: 0.3s;
      background-color: #fff;
      .notification-popup__list {
        max-height: 100%;
      }
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .tooltip{
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.15);
    color: #000;
    font-size: 12px;
    font-weight: 500;
    &:before, &:after {
      display: none;
      background: #FFF;
    }
  }
}

.notification-popup {
  background-color: #fff;
  position: absolute;
  top: 52px;
  right: 44px;
  padding: 0 16px;
  z-index: 9999999999;
  background: #FFF;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
  visibility: hidden;
  transition: 0.1s;
  font-size: 0;
  width: 590px;
  &--active {
    font-size: 16px;
    transition: 0.3s;
    visibility: visible;
    padding: 16px;
  }
  &__header {
    padding-left: 18px;
    display: flex;
    justify-content: space-between;
    span {
      color: #252D53;
      font-size: 20px;
      font-weight: 700;
    }
    .element {
      cursor: pointer;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &__list {
    margin-top: 20px;
  }
  &__day {
    color: #707070;
    font-size: 16px;
    font-weight: 600;
    padding-left: 18px;
    margin-bottom: 8px;
  }
  &__item {
    padding: 16px 18px;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 8px;
    &:hover {
      background-color: #F4F4F4;
      transition: 0.3s;
    }
  }
  &__item-label {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding-right: 60px;
    position: relative;
    &--ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }
  &__item-time {
    color: #707070;
    font-size: 16px;
    font-weight: 500;
  }
  &__item-noti {
    position: absolute;
    width: 10px;
    border-radius: 50%;
    height: 10px;
    background-color: #FF002E;
    right: 0;
    top: 5px;
    z-index: 9;
  }
}