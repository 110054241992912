.areaManagement {
  padding: 35px 25px;
  max-height: calc(100vh - 110px);
  overflow: auto;
  background-color: $gray300;
  margin: 12px;
  &__inner {
    padding: 25px;
    @media (min-width: 768px) {
      padding: 25px 75px;
    }
  }
  &__content {
    display: flex;
    max-width: 1260px;
    flex-wrap: wrap;
    .infoBox {
      width: 100%;
    }
    @media (min-width: 768px) {
      .infoBox {
        width: 50%;
        &:nth-of-type(even) {
          padding-left: 10px;
        }
        &:nth-of-type(odd) {
          padding-right: 10px;
        }
      }
    }
  }
  &__header {
    display: flex;
    margin-bottom: 25px;
    &--backButton {
      button {
        font-family: $fontMontserrat;
        @extend .font-md;
        @extend .bold-700;
        opacity: 0.55;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    &--title {
      margin-left: 25px;
      h1 {
        font-family: $fontMontserrat;
        @extend .font-big;
        margin-bottom: 5px;
        font-size: 27px;
      }
      h3 {
        font-family: $fontMontserrat;
        @extend .bold-700;
        @extend .font-sm;
      }
    }
  }
  &__form {
    max-width: 1200px;
    // max-width: 620px;
    width: 100%;
    .form {
      &__field {
        margin-bottom: 15px;
        textarea {
          border: none;
          min-height: 100px;
        }
        input {
          border: none;
        }
        input::placeholder,
        textarea::placeholder {
          color: $gray100;
        }
        &--checkbox {
          label {
            order: 2;
          }
        }
      }
    }
    &--save {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      float: right;
      .btn {
        &--green {
          width: auto;
        }
      }
    }
  }
  &__label {
    display: flex;
    align-items: center;
    height: 30px;
    font-family: $fontMontserrat;
    border-radius: 15px;
    font-size: 13px;
    background-color: $inputGray;
    font-weight: 600;
    padding: 5px 15px;
    margin-bottom: 10px;
    position: relative;
    text-transform: uppercase;
  }
  &__star {
    color: $blue;
    font-size: 16px;
    margin-right: 5px;
  }
  &__source {
    right: 20px;
    // text-transform: lowercase;
    position: absolute;
    display: flex;
    align-items: center;
    font-weight: 400;
    svg {
      margin-left: 5px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
