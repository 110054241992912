.favorite-customers-list {
  position: absolute;
  width: 100%;
  height: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px;
  }
  &__title {
    display: flex;
    align-items: center;
    img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer;
    }
    h2 {
      font-size: 17px;
      color: $darkBlue;
      font-family: $fontLato;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__table {
    overflow-x: auto;
    table {
      table-layout: unset;
      tbody {
        .actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          //   height: -webkit-fill-available;
        }
        tr {
          height: unset;
        }
        td {
          vertical-align: middle;
          white-space: nowrap;
          padding: 2px 15px 10px 15px;
        }
      }
    }
  }
}
