.userManagement {
  display: flex;
  &--l {
    flex: 1 0 495px;
    min-height: calc(100vh - 110px);
    padding: 80px 60px;
    background-color: $grayDarker;
    overflow: auto;
    max-height: calc(100vh - 99px);
    @media (max-width: 1665px) {
      max-height: calc(100vh - 80px);
    }
    .form__field {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      label,
      input {
        font-family: $fontRoboto;
        font-weight: 600;
        font-size: 17px;
        line-height: 1.25;
        @media (max-width: 1665px) {
          font-size: 15px;
        }
      }
      label {
        order: 2;
        margin-top: 0;
        opacity: 0.29;
        margin: 4px 0 0;
      }
      input {
        order: 1;
        padding: 3px;
        background-color: transparent;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        border-bottom: 1px solid $inputBorderBottom;
      }

      &--zipCode {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        span {
          &.zipCode--spacer {
            order: 2;
            white-space: nowrap;
            width: 30px;
            min-width: 30px;
            height: 100%;
            position: relative;
            &:before {
              position: absolute;
              content: '';
              left: 2px;
              right: 2px;
              top: 14.5px;
              width: 26px;
              height: 1px;
              background-color: $inputBorderBottom;
            }
          }
        }
        input {
          text-align: center;
          &.first {
            order: 1;
            width: 35px;
          }
          &.second {
            order: 3;
            width: 50px;
          }
        }
      }
    }
  }
  &--r {
    flex: 1 1 1400px;
    // padding: 80px 30px 80px 45px;
    padding: 80px 30px 0 45px;
    // width: 100%;
    overflow: hidden;
    .ReactTable {
      .rt-table {
        overflow: scroll;
      }
    }
  }
  .btn {
    &--blank {
      font-family: $fontMontserrat;
      font-size: 17px;
      line-height: 22px;
      font-weight: 600;
      color: $black;
      opacity: 0.55;
      @media (max-width: 1665px) {
        font-size: 15px;
      }
    }
  }
}
.manageUser {
  &__form {
    position: relative;
    z-index: 2;
    // overflow: hidden;
    &--city_zip {
      display: flex;
      align-items: flex-start;
    }
    &--address {
      display: flex;
      .form__field {
        &--house_number,
        &--flat_number {
          flex: 22 1 0;
          padding-left: 10px;
        }
        &--street {
          flex: 56 1 0;
        }
      }
    }
    .zipCode {
      padding-left: 35px;
    }
    &--contactPerson {
      font-family: $fontMontserrat;
      font-weight: 400;
      // color: $sharpGray;
      margin: 50px 0 30px 0;
    }
  }
  &__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
    .btn {
      white-space: nowrap;
      margin-left: -33px;
    }
  }
  &__companyName {
    flex-grow: 1;
    padding: 0 5px;
    text-align: center;
    @extend .font-big;
  }
}

.associatedUsersHeader {
  margin-bottom: 28px;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    @extend .font-big;
    display: flex;
    align-items: center;
    font-size: 27px;
    @media (max-width: 1665px) {
      font-size: 24px;
    }
    &--logins-amount {
      font-size: 30px;
      margin-left: 20px;
      margin-right: 10px;
      font-weight: 700;
      color: $gray100;
      @media (max-width: 1665px) {
        font-size: 28px;
      }
    }
  }
}
.associatedUsersTable {
  .ReactTable {
    .rt-tbody {
      overflow-y: auto;
      // max-height: calc(100vh - 426px);
      max-height: calc(100vh - 36vh);
      padding-bottom: 90px;
      .rt-tr-group {
        &:first-of-type {
          .rt-tr {
            order: 2;
          }
        }
      }
    }
    .rt-thead.-header {
      border-bottom: 0px;
      margin-bottom: 7px;
    }
  }
}

.associateUserRow {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cecece;
    border-radius: 9px;
    padding: 4px 5px;
    // overflow: scroll;
  }
  order: 1;
  padding-bottom: 8px;
  background-color: #fff;
  .form__field {
    margin: 0;
    input {
      font-family: $fontMontserrat;
      padding: 5px 4px;
      @extend .font-sm;
      border: 0px;
      background-color: transparent;
    }
    &:first-of-type {
      flex: 280 1 0;
      @media (max-width: 1100px) {
        flex: 100 1 0;
      }
    }
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5) {
      flex: 100 1 0;
    }
  }
  &__permissions {
    align-items: center;
    display: flex;
  }
  &--l {
    width: 56%;
    display: flex;
    @media (max-width: 1050px) {
      width: 50%;
    }
  }
  &--r {
    width: 43%;
    margin-left: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1050px) {
      width: 50%;
    }
  }
}

.badge {
  padding: 4px 6px;
  @extend .font-sm;
  font-family: $fontOpenSans;
  color: #fff;
  border-radius: 5px;
  margin: 0 2px;
  cursor: pointer;
  user-select: none;
  background-color: $inputGray;
  &--editor,
  &--admin,
  &--superadmin {
    border: 1px solid #a2a2a2;
    background-color: transparent;
    color: rgba(170, 170, 170, 0.28);
  }
  &--disabled {
    cursor: not-allowed;
    opacity: 0.35;
  }
  &--active {
    color: #fff;
    &.badge {
      &--green {
        background-color: $green;
        flex-shrink: 0;
      }
      &--blue {
        background-color: $blue;
      }
      &--gray {
        background-color: $gray100;
      }
      &--orange {
        background-color: $orange;
      }
      &--red {
        background-color: $red;
      }
      &--editor {
        background-color: #41c2be;
      }
      &--admin {
        background-color: #6a6a6a;
      }
      &--superadmin {
        background-color: #000000;
      }
    }
  }
}

.tableSelect {
  cursor: pointer;
  font-family: $fontOpenSans;
  position: relative;
  user-select: none;

  &__value {
    border-radius: 5px;
    padding: 4px 7px;
    @extend .font-sm;
    background-color: $inputGray;
    color: $black;
    &--basic {
      color: rgba($black, 0.28);
    }
    &--detailed {
      background-color: #8a35de;
      color: #fff;
    }
    &--managing {
      background-color: #3579de;
      color: #fff;
    }
  }
  &__options {
    padding: 4px 7px;
    border-radius: 5px;
    z-index: 100;
    position: absolute;
    @extend .font-sm;
    left: 0;
    top: 100%;
    right: 0;
    background-color: $inputGray;
    min-width: 100px;
  }
  &__single {
    margin-bottom: 4px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.permissionsCell {
  display: flex;
  position: relative;
  &__edit,
  &__delete {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    &:hover {
      background-color: #e6e6e6;
      svg {
        fill: $red;
        cursor: pointer;
      }
    }
    svg {
      fill: $sharpGray;
    }
  }

  &__edit {
    right: 30px;
  }
}
