@import './_reset';
@import './_variables';
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,800;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700;800&display=swap');

html {
  scroll-behavior: smooth;
}
body {
  line-height: 1.5;
  font-size: 16px;
  // font-family: 'Montserrat', sans-serif;
}
.btn {
  background: none;
  outline: none;
  
  border: 0;
  border-radius: 5px;
  display: block;
  font-weight: 400;
  font-size: 17px;
  // padding: 0 30px;
  // margin: 20px;
  text-decoration: none;
  font-family: $fontLato;
  text-align: center;
  cursor: pointer;
  &--default {
    height: 48px;
    line-height: 48px;
    background-color: $mianNavyBlue;
    font-weight: black;
    color: #fff;
    border-radius: 3px;
    width: 100%;
    text-transform: uppercase;
    font-size: 13px;
    &:hover {
      // background-color: #f5f4f4;
      background-color: #212748cb;
    }
  }
  &--extendTextManage {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    background-color: $inputGray;
    font-weight: black;
    color: $black;
    border-radius: 3px;
    display: inline-block;
    // margin: 0 0 15px 20px;
    margin-left: 20px;
    font-size: 13px;
  }
  &--extendActive {
    background-color: #a8a8a8;
    color: $white;
  }
  &--login {
    font-size: 17px;
  }
  &--navigation {
    height: 37px;
    // width: 85px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 13px;
    margin: 0;
    line-height: 37px;
    padding: 0 5px;
    box-sizing: content-box;
    span {
      margin-right: 7px;
    }
    background-color: unset;
    color: $darkBlue;
    font-weight: bold;
    &:hover {
      font-weight: 900;
    }
  }
  &--center {
    margin-left: auto;
    margin-right: auto;
  }
  &--delete {
    background-color: #e80000;
    color: #fff;
    &:hover {
      background-color: #d80202;
    }
  }
  &--disabled {
    color: #e4e4e4;
    cursor: not-allowed;
    &:hover {
      background-color: #fff;
      box-shadow: 0px 1px 5px rgba(156, 156, 156, 0.32);
    }
  }
  &--blank {
    padding: 0;
  }
  &--edit-client {
    background-color: #f0f0f0;
    border-radius: 5px;
    font-family: $fontOpenSans;
    font-size: 14px;
    font-weight: 400;
    height: 27px;
    width: 85px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #4d4d4d;
    &:hover {
      background-color: #e9e9e9;
    }
  }
  &--status-expired {
    background-color: #f0f0f0;
    border-radius: 5px;
    height: 27px;
    width: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  &--status-active {
    @extend .btn--status-expired;
    width: 30px;
  }
  &--status-extend {
    background-color: #9e9e9e;
    color: #fff;
    border-radius: 5px;
    font-family: $fontOpenSans;
    font-size: 14px;
    font-weight: 300;
    width: 90px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    &:hover {
      background-color: #979797;
    }
  }
  &--expires-month {
    @extend .btn--status-active;
    width: fit-content;
    justify-content: space-evenly;
    padding: 0 5px;
    img {
      margin-right: 8px;
    }
  }
}
.btn-action {
  background: none;
  outline: none;
  border: 0;

  border-radius: 6px;
  display: block;
  background-color: #f3f3f3;
  width: 25px;
  height: 25px;
  float: left;
  margin-right: 8px;
  cursor: pointer;
  &--reply {
    background-image: url(../Img/Icons/ButtonIcons/reply-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/reply-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }
  &--date {
    background-image: url(../Img/Icons/ButtonIcons/date-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/date-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }
  &--cancel {
    background-image: url(../Img/Icons/ButtonIcons/cancel-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/cancel-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $orange;
    }
  }
  &--edit {
    background-image: url(../Img/Icons/ButtonIcons/edit-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/edit-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }
  &--search {
    background-image: url(../Img/Icons/ButtonIcons/search-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/search-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }
  &--open {
    background-image: url(../Img/Icons/ButtonIcons/open-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/open-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }
  &--favorite {
    background-image: url(../Img/Icons/ButtonIcons/favorite-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/favorite-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: 15px;
  // -webkit-text-fill-color: #969090;
  caret-color: #fff;
  // box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
  background-color: $lightCream;
}
:-internal-autofill-previewed {
  font-size: 15px !important;
}

.Toastify {
  &__close-button {
    color: #717171 !important;
  }
  &__toast {
    color: rgb(68, 67, 67);
    font-family: $fontLato;
    text-align: center;
    @include rwd(481px) {
      border-radius: 5px !important;
    }
    &--error {
      background: $lightCream !important;
      border-left: 8px solid #e74c3c;
    }
    &--success {
      background: $lightCream !important;
      border-left: 8px solid #07bc0c;
    }
  }
}

.btn {
  &--green {
    background-color: $green;
    padding: 12px;
    color: #fff;
    width: 100%;
    text-align: center;
    font-weight: 700;
    border-radius: 5px;
    font-size: 17px;
    line-height: 22px;
    font-family: $fontMontserrat;
    @media (max-width: 1665px) {
      font-size: 15px;
    }
  }
  &--orange {
    @extend .btn--green;
    background-color: $orange;
  }
  &--blue {
    @extend .btn--green;
    background-color: $blue;
  }
  &--navy-blue {
    @extend .btn--green;
    background-color: $mianNavyBlue;
  }
  &--navy-blue-stroke {
    @extend .btn--green;
    border: 1px solid $mianNavyBlue;
    background-color: unset;
    color: #252D53;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    max-height: 40px;
    display: flex;
    align-items: center
  }
  &--red {
    @extend .btn--green;
    background-color: $deepRed;
    min-width: 150px;
    font-weight: 700;
  }
  &--cancel {
    @extend .btn--green;
    background-color: transparent;
    color: #000;
    min-width: 150px;
    font-weight: 700;
    &:hover {
      background-color: $transparentHover;
    }
  }
  &--small {
    padding: 4px 23px;
    width: auto;
    @extend .font-sm;
    font-family: $fontOpenSans;
  }
  &--xs {
    @extend .font-xs;
    @extend .bold-600;
    padding: 4px 15px;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &--transparent {
    font-family: $fontMontserrat;
    padding: 12px;
  }
  &--blue {
    background-color: $blue;
    color: #fff;
  }
  &--loader {
    margin-right: 5px;
    &__inner {
      width: 17px;
      height: 17px;
      border: 2px solid #fff;
      border-radius: 50%;
      border-bottom-color: transparent;
      animation: spinx 1s infinite linear both;
    }
  }
  &.is-loading {
    padding-left: 22px;
    display: flex;
    align-items: center;
  }
  &--bordered {
    border: 1px solid $inputGray;
    &:hover {
      background-color: $grayDarker;
    }
  }
}

.font-big {
  font-size: 30px;
  line-height: 37px;
  font-weight: 700;
  font-family: $fontMontserrat;
}
.font-lg {
  font-size: 21px;
  line-height: 25px;
}
.font-sm {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
}
.font-xs {
  font-size: 12px;
  line-height: 15px;
}
.font-md {
  font-size: 17px;
  line-height: 20px;
}

.family-m {
  font-family: $fontMontserrat;
}

.bold-500 {
  font-weight: 500;
}
.bold-600 {
  font-weight: 600;
}
.bold-700 {
  font-weight: 700;
}

@keyframes spinx {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tooltip > .tooltip-inner {
  background-color: #fff;
}
.tooltip {
  font-weight: 500 !important;
  border-radius: 10px !important;
  letter-spacing: 1px;
  font-family: $fontMontserrat;
  background-color: #fff !important;
  color: #000 !important;
  padding: 5px 10px !important;
  opacity: 0.9 !important;
  max-width: 250px;
  // margin: 0 5px;
  &--5 {
    border: 2px solid grey !important;
  }
  &--4 {
    border: 2px solid #0080ff !important;
  }
  &--3 {
    border: 2px solid #70ad47 !important;
  }
  &--2 {
    border: 2px solid #ffc000 !important;
  }
  &--1 {
    border: 2px solid #ff4f00 !important;
  }
}
.tooltip.place-left {
  &:after {
    border-left-color: transparent !important;
    border-left-width: 8px !important;
  }
}
.tooltip.place-right {
  &:after {
    border-right-color: #fff !important;
    border-right-width: 6px !important;
  }
}

.tooltip > .tooltip-arrow {
  background-color: #fff;
}


.pdf-file {
  display: flex;
  margin: 5px 0;
  &__item {
    border: 1px solid #e2e2e2;
    padding: 8px 10px;
    display: flex;
    align-items: center;
  }
  &--delete {
    cursor: pointer;
  }
  a {
    text-decoration: none;
    margin: 0;
  }
  svg {
    margin-left: 10px;
  }
}
.select-error {
  border: 1px solid red;
}



.skeleton-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  margin-bottom: 20px;
  span {
    display: flex;
    width: 100%;
  }
}

.react-loading-skeleton {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 6px;
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #e0e0e0 20%,
    #f0f0f0 40%,
    #f0f0f0 100%
  );
  background-size: 800px 104px; /* Szerokość i wysokość animacji */
  position: relative;
  overflow: hidden;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}