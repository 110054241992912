.cases-list {
  //   position: absolute;
  //   width: 100%;
  height: 100%;
  display: flex;
  &__summary {
    position: relative;
    width: 100%;
    //   margin-right: 17px;
    padding: 25px;
    background-color: #f3efec;
    margin: 0 auto;
    @include rwd(501px) {
      width: 380px;
    }
    @include rwd(1200px) {
      height: calc(100vh - 34px);
      width: 380px;
      margin: unset;
    }
    &__title {
      display: flex;
      align-items: center;
      img {
        margin-top: 8px;
        margin-right: 15px;
        cursor: pointer;
      }
      h2 {
        font-size: 17px;
        color: $darkBlue;
        font-family: $fontLato;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    &__form-container {
      margin-top: 90px;
      border-top: 2px solid $darkBlue;
      height: calc(100% - 107px);
    }
    &__form {
      margin-top: 20px;
      position: relative;
      height: calc(100% - 17px);
      .btn {
        margin-top: 60px;
        @include rwd(1200px) {
          position: absolute;
          bottom: 10px;
        }
      }
      .form__field {
        input,
        textarea {
          // height: 50px;
          // padding: 5px 10px;
          // margin: 5px 0 20px 0;
          border-radius: 5px;
        }
      }
    }
  }
  &__short-summary {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    // line-height: 13px;
    line-height: 20px;
    align-items: flex-start;
    p {
      color: rgba(0, 0, 0, 0.4);
      font-size: 13px;
      font-weight: 400;
      font-family: $fontLato;
      &:first-of-type {
        line-height: 8px;
      }
    }
    span {
      margin-left: 10px;
    }
    &--in-progress {
      color: $orange;
      font-size: 25px;
      font-weight: 900;
    }
    &--done {
      color: $darkBlue;
      font-size: 25px;
      font-weight: 900;
    }
  }
  &__cases-status {
    display: flex;
    border-bottom: 2px solid rgba(34, 45, 92, 0.19);
    justify-content: flex-end;
    margin-bottom: 10px;
    p {
      font-size: 12px;
      font-family: $fontLato;
      font-weight: 600;
      margin-left: 12px;
      margin-bottom: 5px;
    }
    &--in-progress {
      color: $orange;
    }
    &--all {
      color: rgba(0, 0, 0, 1);
    }
    &--done {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  &__cases-types {
    color: $darkBlue;
    font-size: 13px;
    font-weight: 400;
    font-family: $fontLato;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    ul {
      li {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        position: relative;
        &:hover {
          border-bottom: 1px solid rgba(34, 45, 92, 0.22);
          font-weight: 900;
          padding-left: 25px;
          cursor: pointer;
          &:before {
            content: '';
            position: absolute;
            width: 20px;
            left: 0px;
            top: 49%;
            transform: translateY(-50%);
            height: 40px;
            background-image: url(../../../../Img/Icons/active-menu-icon.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        span {
          //   margin-left: 15px;
          font-weight: 700;
        }
      }
    }
    &--selected {
      border-bottom: 1px solid rgba(34, 45, 92, 0.22);
      font-weight: 900;
      padding-left: 25px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 20px;
        left: 0px;
        top: 49%;
        transform: translateY(-50%);
        height: 40px;
        background-image: url(../../../../Img/Icons/active-menu-icon.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .container {
      display: flex;
    }
    &--in-progress {
      color: $orange;
    }
    &--all {
      color: $darkBlue;
    }
    &--done {
      color: rgba(34, 45, 92, 0.38);
    }
    &--in-progress,
    &--all,
    &--done {
      width: 50px;
      display: block;
      text-align: right;
    }
  }

  &__container {
    width: calc(100% - 350px);
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px;
  }
  &__title {
    display: flex;
    align-items: center;
    img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer;
    }
    h2 {
      font-size: 17px;
      color: $darkBlue;
      font-family: $fontLato;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__table {
    overflow-x: auto;
    table {
      table-layout: unset;
      tbody {
        .actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          //   height: -webkit-fill-available;
        }
        tr {
          height: unset;
        }
        td {
          vertical-align: middle;
          white-space: nowrap;
          padding: 2px 15px 10px 15px;
        }
      }
    }
  }
}
