.compendiumSingle--environment .taskBox__heading-item {
  background-color:  #32c832;
}
.compendiumSingle--bhp .taskBox__heading-item {
  background-color:  #0080ff;
}
.compendiumSingle--energy .taskBox__heading-item {
  background-color:  #ffaa00;
}

.taskBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  .tooltip {
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.01);
    color: #000;
    font-size: 12px;
    font-weight: 500;
    &:before, &:after {
      display: none;
      background: #FFF;
    }
  }

  &__add-evidence {
    display: flex;
    gap: 20px;
    .rating-select , .form__field {
      width: 50%;
      background-color: #efefef;
      border-radius: 4px;
    }
    .rating-select__menu { 
      z-index: 9999999;
    }

    .SingleDatePicker {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 19px;
    }
    .form__field input {
      border: unset;
    }
    .rating-select__single-value {
      font-size: 14px !important;
      font-weight: 500;
    }
  }
  &__add-evidence-container {
    margin-top: 20px;
  }
  &__heading {
    display: flex;
    width: 100%;
    gap: 8px;
    margin-bottom: 14px;
  }

  &__heading-item {
    flex: 1; 
    padding: 6px 20px;
    border-radius: 50px;
    // background: #FA0;
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    &--lp {
      flex: 0 0 64px;
    }
    &--plan {
      flex: 2; 
    }
    
    &--responsibility {
      flex: 0.5;
      max-width: 200px; 
      max-height: 56px;
    }
    &--realization {
      flex: 0.5;
      max-width: 160px;
      max-height: 56px;
      
    }
    &--action {
      flex: 0 0 170px; 
      max-height: 56px;
    }
    &--evidence {
      flex: 0 0 108px;
      max-height: 56px;
    }
    &--status {
      flex: 0 0 171px;
      max-height: 56px;
    }
  }
  &__addTask-container {
    display: flex;
    width: 100%;
    padding-top: 25px;
    .btn {
      max-width: 226px;
      border-radius: 50px;
      padding: 10px 21px;
    }
  }
  &__addTask-form {
    display: flex;
    flex-direction: column;
    max-width: 707px;
    margin: 64px auto 0 auto;
    &--top {
      display: flex;
      margin-bottom: 36px;
      gap: 20px;
    }
    .DateInput {
      width: 150px !important;
    }
  }
  &__addTask-form-field {
    .css-1wa3eu0-placeholder {
      font-size: 14px !important;
      color: #626262;
    }
    .form__field--datepicker {
      background-color: #efefef;
      padding: 4px 15px;
      border-radius: 4px !important;
      input {
        font-size: 14px !important;
        border-bottom: 0px !important;
        color: #626262 !important;
      }
    }
    &--files {
      display: flex;
      align-items: center;
      .fulfillment-proof--icon-file  {
        margin-left: 6px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &--files-item {
      font-size: 14px;
      font-weight: 600;
      margin-left: 10px;
      display: flex;
      svg {
        cursor: pointer;
        margin-left: 5px;
      }
    }
    .SingleDatePickerInput_clearDate {
      margin-right: 0 !important;
    }
    &--top {
      width: 100%;
    }
    textarea {
      background-color: #efefef !important;
      height: 326px;
      border-radius: 4px !important;
      resize: none !important;
    }
    &--add-files {
      margin-top: 25px;
    }
  }
  &__addTask-form-buttons {
    display: flex;
    margin-top: 60px;
    .btn {
      width: 50%;
    }
  }
  &__modal-heading {
    text-align: center;
    color: #000;
    font-size: 18px;
    font-weight: 700;
  }
  &__modal-label {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  &__modal-description {
    color: #313131;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    max-width: 400px;
    margin: 58px 0 90px 0;
  }
  &__task {
    display: flex;
    gap: 8px;
    border-radius: 4px;
    background: #EFEFEF; 
    padding: 9px 0;
    margin-top: 12px;
    svg {
      width: 15px;
    }
    &--done {
      color: #70AD47;
      display: flex;
      padding-top: 9px;
      svg {
        margin-left: 5px;
      }
    }
  }
  &__task-item {
    padding: 8px 20px;
    font-size: 14px;
    display: flex;
    svg {
      flex-shrink: 0;
    }
    .css-2b097c-container {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .css-1hb7zxy-IndicatorsContainer {
      margin-right: 20px;
    }
    .react-select__control {
      background-color: transparent !important;
      border: 0px !important;
    }
    &--lp {
      flex: 0 0 64px;
      max-height: 38px;
      display: flex;
      align-items: center;
      svg {
        margin-left: 22px;
        padding-right: 0;
      }
    }
    &--plan {
      flex: 2;
      padding-left: 0;
      font-weight: 500;
      flex-direction: column;
    }
    &--plan-item {
      cursor: pointer;
      padding: 0;
      word-break: break-all;
    }
    &--evidence-pdf {
      cursor: pointer;
      display: flex;
      margin-top: 20px;
      &:nth-child(n + 1) {
        margin-left: 5px;
      }
      svg {
        margin-right: 5px;
        width: 12px;
      }
    }
    &--evidence-pdfs {
      display: flex;
    }
    &--evidence-comment {
      border-top: 1px solid #CECECE;
      margin-top: 16px;
      padding-top: 16px;
      display: flex;
      position: relative;
      svg {
        position: absolute;
        left: -45px;
        width: 18px;
        height: 18px;
      }
    }
    &--plan-collapse {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      height: 32px;

      .taskBox__task-item--plan-item {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        height: 32px;
        word-break: unset;
      }
    }
    &--responsibility {
      flex: 0.5;
      max-width: 200px; 
      display: flex;
      border-right: 1px solid #CECECE;
      border-left: 1px solid #CECECE;
      align-items: center;
      font-weight: 500; 
      max-height: 38px;
      svg {
        margin-right: 9px;
      }
    }
    &--realization {
      flex: 0.5;
      max-width: 160px;
      justify-content: center;
      border-right: 1px solid #CECECE;
      font-weight: 500; 
      max-height: 38px;
      align-items: center;
      .SingleDatePickerInput_clearDate {
        display: none !important;
      }
      .SingleDatePickerInput {
        padding-right: 0;
      }
      .form__field--date-picker-blank input {
        font-size: 14px !important;
      }
      .DateInput {
        width: 85px !important;
      }
      svg {
        margin-right: 10px;
        flex-shrink: 0;
        width: 12px;
      }
    }
    &--action {
      flex: 0 0 170px; 
      padding: 0;
      display: flex;
      justify-content: center;
      height: 40px;
      .btn {
        font-size: 14px;
        padding: 0 10px;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        border-radius: 0;
        &:first-child {
          border-right: 1px solid #CECECE;
          color: #707070;
        }
        &:last-child {
          color: #F40000;
        }
      }
    }
    &--evidence {
      flex: 0 0 108px;
      display: flex;
      justify-content: space-around;
      border-right: 1px solid #CECECE;
      max-height: 38px;
      align-items: center;
    }
    
    &--status {
      flex: 0 0 171px;
      padding-top: 0;
      padding-bottom: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      padding-left: 0;
      .taskBox__task-item--action {
        flex: unset;
        margin-top: 30px;
      }
    }
  }
}