.dashboardUsers {
  .ReactTable .rt-td {
    word-break: break-word;
  }
  &__header {
    @media (min-width: 376px) {
      display: flex;
      justify-content: space-between;
      margin: 72px 15px 30px;
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    @media (max-width: 1665px) {
      margin-top: 64px;
    }
  }
  &__submenu {
    display: flex;
    align-items: flex-end;
    max-width: 100%;
    overflow: auto;
    &-item {
      border-bottom: 4px solid transparent;
      padding: 0 6px;
      font: 700 18px/22px $fontMontserrat;
      color: $black;
      opacity: 0.55;
      cursor: pointer;
      margin: 0 9px;
      white-space: nowrap;
      @media (max-width: 1665px) {
        font-size: 16px;
      }
      &:first-of-type {
        margin-left: 0;
      }
      &.active {
        @extend .font-big;
        border-bottom-color: $black;
        opacity: 1;
        font-size: 27px;
        @media (max-width: 1665px) {
          font-size: 22px;
        }
      }
    }
  }
  &__addClient {
    background-color: $mianNavyBlue;
    border: none;
    height: 35px;
    width: 170px;
    font: 600 16px/22px $fontMontserrat;
    color: $white;
    border-radius: 5px;
    margin-right: 25px;
    cursor: pointer;
    outline: none;
    @media (max-width: 1665px) {
      font-size: 14px;
      height: 36px;
      width: 168px;
    }
    &--bhp {
      background-color: $blue;
    }
    &--energy {
      background-color: $orange;
    }
    &--environment {
      background-color: $mainGreen;
    }
  }
  &__searchBox {
    display: flex;
    @media (max-width: 768px) {
      margin-top: 15px;
      width: 100%;
      min-width: 0;
    }
    @media (max-width: 375px) {
      padding: 0 15px;
      margin-bottom: 20px;
    }
  }
}

.SingleDatePicker {
  width: 100%;
}
.SingleDatePickerInput__withBorder {
  // border-bottom: 1px solid #2b2b2b !important;
  // border-top: none !important;
  // border-left: none !important;
  // border-right: none !important;
  background-color: transparent !important;
  border: none !important;
}

.DateInput {
  background: transparent !important;
  width: 100px!important;
  &_input {
    background: transparent !important;
    padding: 3px 5px !important;
    font-family: $fontRoboto;
    font-weight: 600 !important;
    font-size: 18px;
    // line-height: 1.25;
    line-height: 24px !important;
  }
  &_input__focused {
    line-height: 24px !important;
    border-bottom: none !important;
    border-bottom: 1px solid #2b2b2b !important;
  }
}
.date-picker-empty {
  pointer-events: none;
  cursor: auto;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover,
.CalendarDay__default,
.CalendarMonth {
  background-color: #f1f1f1 !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: $green !important;
  border: 1px double $green !important;
}
.DayPickerKeyboardShortcuts_show__topRight {
  display: none;
}
.DayPickerNavigation_button__verticalDefault {
  background-color: #f1f1f1 !important;
}
.DateInput_fangStroke {
  fill: #f1f1f1 !important;
  stroke: #f1f1f1 !important;
}
