.form__field {
  position: relative;
  margin: 0 auto 0 auto;
  font-family: $fontLato;
  input,
  select,
  textarea {
    font: 500 14px/18px $fontMontserrat;
    width: 100%;
    border: none;
    font-size: 14px;
    border: 1px solid rgba(226, 226, 226, 1);
    border-radius: 2px;
    background-color: #fff;
    outline: none;
    @media (max-width: 1665px) {
      font-size: 12px;
    }
    option {
      color: #444;
    }
  }
  textarea {
    resize: vertical;
    text-align: justify;
  }
  input,
  textarea {
    padding: 13px 15px;
    border-radius: 2px;
    @media (max-width: 1665px) {
      padding: 8px 14px;
    }
  }
  &--file {
    margin: 5px 0 10px;
  }
  .field-label {
    font-weight: 400;
    color: $dark;
    font-size: 12px;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 5px 2px;
  }
  .react-select {
    &__control {
      border-style: unset;
      // min-height: 24px;
      height: 33px;
      background-color: #fff;
      margin: 5px 0 20px 0;
      border-radius: 2px;
      font-size: 12px !important;
      padding: 0 0 0 10px;
      line-height: 0;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      color: rgba(26, 26, 26, 0.65);
      &:hover {
        border-color: #e9e6e6;
      }
      &--is-focused {
        outline: none;
        box-shadow: none;
        // height: 33px;
        // line-height: 33px;
      }
    }
    &--is-multi {
      &__control {
        border-style: unset;
        border-bottom: 1px solid #e9e6e6;
        border-radius: 0;
        min-height: 24px;
        background-color: transparent;
        &:hover {
          border-color: #e9e6e6;
        }
        &--is-focused {
          outline: none;
          box-shadow: none;
          min-height: 24px;
        }
      }
      &__value-container {
        padding: 0;
        min-height: 24px;
        top: -7px;
      }
    }
    &__menu {
      text-align: left;
    }
    &__value-container {
      padding: 0;
      min-height: 24px;
      // top: -7px;
    }
    &__indicators {
      // top: -6px;
      position: relative;
      img {
        height: 15px;
        margin-right: 3px;
      }
    }
    &__single-value {
      color: #212020;
      font-weight: 300;
      // font-size: 1.1rem;
      margin-left: 0;
      overflow: visible;
    }
  }
  &--date-picker-blank {
    input {
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid #2b2b2b !important;
      font-family: $fontRoboto;
      font-weight: 700;
      font-size: 17px;
      @media (max-width: 1665px) {
        font-size: 15px;
      }
    }
  }
  &--default {
    input,
    select,
    textarea {
      background-color: #fff !important;
      border-radius: 2px;
      border: none;
      height: 33px;
      width: 100%;
      font-size: 12px !important;
      padding: 19px 10px;
      font-family: $fontLato;
      font-weight: 400;
      color: rgba(26, 26, 26, 0.65);
      @media (max-width: 1665px) {
        font-size: 10px !important;
      }
    }
    textarea {
      height: 100px;
      padding: 10px 10px;
    }
  }
  &--gray {
    input {
      background-color: $inputGray;
      color: $black;
    }
  }
  &--error {
    input,
    textarea,
    .react-select__control {
      border: 1px solid #e20d0d !important;
    }
    .react-select__input {
      input {
        border: none !important;
      }
    }
  }
  &--checkbox {
    height: 20px;
    display: flex;
    align-items: center;
    margin: 0px auto 25px auto;
    font-size: 14px;
    color: #969090;
    label {
      margin: 0 0 0 10px !important;
    }
  }
  &--disabled {
    input[type='text']::placeholder {
      color: #dcdbdb;
    }
  }
  .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  input[type='checkbox'] {
    width: 20px;
    height: 17px;
  }
  &--label-error {
    font-family: $fontLato;
    color: #e20d0d;
    margin-left: 3px;
    position: relative;
    animation: show 0.25s ease-in;
  }
  @keyframes show {
    0% {
      right: -40px;
    }
    50% {
      right: 0px;
    }
    80% {
      right: -10px;
    }
    100% {
      right: 0px;
    }
  }
}

.search__form {
  input {
    height: 35px;
    border-radius: 5px;
    @media (min-width: 768px) {
      min-width: 446px;
    }
    @media (max-width: 1665px) {
      min-width: 356px;
    }
    @media (max-width: 950px) {
      min-width: 180px;
    }
    font-weight: 600;
    &::placeholder {
      color: $black;
    }
    @media (min-width: 375px) and (max-width: 767px) {
      font-size: 11px;
      line-height: 14px;
      padding-top: 10px;
      padding-bottom: 10px;
      &::placeholder {
        font-weight: 600;
        opacity: 0.23;
      }
    }
  }
}
.rating-option {
  display: flex;
  align-items: center;
  img, svg {
    margin-right: 10px;
  }
  svg {
    max-width: 16px;
    margin-top: -1px;
  }
}

.multi-filter.react-select__indicators {
  align-items: flex-start;
}

//color picker
.field-label {
  font-weight: 400;
  color: #1a1a1a;
  font-size: 12px;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 5px 2px;
}
