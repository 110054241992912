.new-row {
  background-color: $mainGreen;
  width: 30px;
  height: 16px;
  font-size: 9px;
  font-family: $fontMontserrat;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  &--bhp {
    background-color: $blue;
  }
  &--energy {
    background-color: $orange;
  }
  &--environment {
    background-color: $mainGreen;
  }
}
