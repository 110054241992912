.icons-field {
  &__icons-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  &__icon-row {
    width: calc(100% / 10);
    margin-bottom: 20px;
  }
  &__icon {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &--active {
      border-radius: 4px;
      background: #EFEFEF; 
    }
  }
  &__icon-check {
    top: -7px;
    right: -7px;
    position: absolute;
    width: 17px;
    height: 17px;
    background-color: #32C832;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}