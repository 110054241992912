.compendiumCounters {
  display: flex;
  border-radius: 50px;
  background: #ECECEC; 
  gap: 5px;
  padding: 5px;
  margin-right: auto;
  width: fit-content;
  margin-top: 10px;
  &__item {
    display: flex;
    width: 61px;
    justify-content: space-evenly ;
    border-radius: 20px;
    background-color: #fff;
    align-items: center;
  }
  &__number {
    color: #000;
    font-size: 14px;
    font-weight: 600;
  }
  &__icon {
    display: flex;
    align-items: center;
  }
}