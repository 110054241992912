.dashboard {
  height: 100vh;
  display: flex;
  font-family: $fontRoboto;
  overflow: hidden;
  &__menu {
    width: 115px;
    background-color: $gray;
    min-height: 100vh;
    position: relative;
    @media (max-width: 1280px) {
      display: none;
    }
    @media (max-width: 1665px) {
      width: 86px;
    }
    p {
      margin: 14px 0 0 0;
      font-size: 10px;
      font-weight: 700;
      text-align: center;
      @media (max-width: 1665px) {
        font-size: 9px;
      }
    }
  }
  &__role-name {
    background-color: black;
    height: 38px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 12px;
    @media (max-width: 1665px) {
      font-size: 10px;
    }
    img {
      margin: 0px 6px;
    }
    &:first-of-type {
      margin-top: 81px;
      @media (max-width: 1665px) {
        margin-top: 40px;
      }
    }
  }
  &__socials {
    position: absolute;
    bottom: 140px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  &__socials-icon {
    @media (max-width: 1665px) {
      width: 40px;
      height: 40px;
    }
    width: 48px;
    height: 48px;
    display: flex;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    background-color: #989696;
    &:hover {
      background-color: #888686;
      cursor: pointer;
    }
  }
  &__logout {
    // position: fixed;
    // width: 85px;
    // bottom: 60px;
    // left: 0;

    position: absolute;
    width: 100%;
    bottom: 70px;
    padding: 10px 0;
    font-size: 14px;
    color: #000;
    background-color: #fff;
    font-weight: 500;
    text-align: center;
    @media (max-width: 1665px) {
      font-size: 12px;
    }
    &:hover {
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;
    }
  }
  &__content {
    margin: 0;
    display: flex;
    flex-direction: column;
    @media (min-width: 769px) {
      width: calc(100vw - 115px);
    }

    @media (max-width: 1665px) {
      width: calc(100vw - 89px);
    }
    @media (max-width: 1280px) {
      width: 100%;
    }
    overflow-y: auto;
  }
  &__page {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  
  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: $grayLight;
    max-width: 100%;
    // overflow: scroll;
    height: 80px;

    @media (max-width: 1665px) {
      height: 70px;
    }
    @media (max-width: 1221px) {
      height: 80px;
    }
    @media (max-width: 768px) {
      height: 70px;
    }
    @media (max-width: 376px) {
      height: 70px;
    }
    &-logo {
      padding-right: 15px;
      img {
        // width: 155px;
        // height: 79px;
        max-width: unset;
        max-height: unset;
        // @media (max-width: 1665px) {
        width: 180px;
        height: 48px;
        // }
      }
    }
    &-items {
      display: flex;
    }
    &-item {
      height: 100%;
      padding: 18px 15px;
      width: 194px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      text-decoration: none;
      color: $black;

      &:after {
        content: '';
        position: absolute;
        height: 60px;
        width: 1px;
        background-color: $borderGray;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
      // &:last-of-type {
      //   &:after {
      //     content: none;
      //   }
      // }
      p {
        margin-left: 15px;
        font-size: 15px;
        line-height: 18px;
        font-weight: bold;
        @media (max-width: 1665px) {
          font-size: 14px;
        }
      }

      &:hover,
      &--active {
        background-color: #fff;
        &.dashboard__header-item--green {
          border-bottom-color: $green;
        }
        &.dashboard__header-item--blue {
          border-bottom-color: $blue;
        }
        &.dashboard__header-item--yellow {
          border-bottom-color: $yellow;
        }
        &.dashboard__header-item--gray {
          border-bottom-color: $gray;
        }
        &.dashboard__header-item--orange {
          border-bottom-color: $orange;
        }
      }
      &--forbidden {
        p,
        img {
          opacity: 0.31;
        }
        &:hover {
          background-color: transparent;
        }
      }

      .forbidden {
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        font: 600 9px/14px $fontOpenSans;
        padding: 3px 11px;
        border-radius: 5px;
        background-color: $red;
        color: #fff;
        width: 81px;
      }
      @media (max-width: 1483px) {
        width: 174px;
      }
      @media (max-width: 1665px) {
        width: 160px;
        &:last-child {
          width: 190px;
        }
      }
      @media (max-width: 768px) {
        width: 144px;
        padding: 15px 10px;
        p {
          font-size: 11px;
          line-height: 12px;
          margin-left: 10px;
        }
      }
      @media (max-width: 375px) {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
    &-logo {
      // padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: right;
      min-width: 140px;
      @media (max-width: 1477px) {
        min-width: 135px;
      }
    }
  }
  &__header-edit-icon {
    position: absolute;
    right: 10px;
    top: 25px;
    cursor: pointer;
  }
}

img {
  max-height: 100%;
  max-width: 100%;
}

.dictionaryTable {
  &--tablet {
    margin: 0 15px;
  }
  .expired-date {
    color: #fd3617;
    font-weight: bold;
  }
  .status-container {
    display: flex;
  }
}
.dictionary {
  &__element {
    font-family: $fontMontserrat;
    margin-bottom: 20px;
    &:first-of-type {
      .dictionary__element--letter {
        padding-top: 0;
      }
    }
    &--letter,
    &--header,
    &--content {
      padding: 0 25px;
    }
    &--letter {
      font-size: 21px;
      font-weight: 700;
      line-height: 25px;
      margin-bottom: 20px;
      padding-top: 15px;
    }
    &--title {
      font-size: 12px;
      line-height: 15px;
      font-weight: 700;
    }
    &--icon {
      width: 13px;
    }
    &--content {
      display: flex;
      font-size: 12px;
      line-height: 15px;

      &-r,
      &-l {
        width: 50%;
      }
      &-l {
        padding-right: 17px;
        font-weight: 600;
      }
      &-r {
        padding-left: 17px;
      }
    }
    &--header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      margin-bottom: 13px;
      border-bottom: 2px solid $grayLight;
      border-radius: 2px;
      &-l {
        display: flex;
        justify-content: space-between;
        padding-right: 17px;
      }
      &-l,
      &-r {
        width: 50%;
      }
      &-r {
        padding-left: 17px;
        display: flex;
        .dictionary__element--icon {
          margin-left: auto;
        }
      }
    }
    &--phone {
      margin-bottom: 0;
      padding-top: 20px;
      .dictionary__element {
        &--header {
          margin-bottom: 0;
          align-items: center;
          &-r,
          &-l {
            width: auto;
          }
        }
        &--icon {
          margin-left: 15px;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border: 1px solid transparent;
          transform: rotate(0deg);
          position: relative;
          &__inner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 13px;
          }
          &.active-ico {
            background-color: #fff;
            .dictionary__element--icon__inner {
              transform: rotate(-20deg) translate(-50%, -50%);
              transform-origin: 0 0;
            }
          }
          &.bordered {
            border-color: $inputGray;
          }
        }
        &--content {
          display: block;
          padding-bottom: 10px;
          font-weight: 700;
        }
        &--letter {
          background-color: #fff;
        }
        &--date {
          margin-top: 10px;
          font-size: 10px;
          line-height: 13px;
          font-weight: 600;
        }
      }
      &.active {
        background-color: $grayLight;
      }
    }
  }
}
