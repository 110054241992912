.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  font-family: $fontMontserrat;
  &__title {
    color: rgba(0, 0, 0, 0.38);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 35px;
    &--with-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        width: 35px;
        height: 40px;
        margin-bottom: 30px;
      }
    }
  }
  &__date-picker {
    display: flex;
    justify-content: center;
    padding: 0 0 45px 0;
  }
  &__info {
    background-color: rgb(241, 241, 241);
    padding: 20px 0;
    h2 {
      padding: 5px 27px;
      color: #000;
      font-weight: 700;
      font-size: 13px;
    }
    p {
      padding: 0px 27px 20px 27px;
      font-size: 12px;
    }
    ul {
      li {
        padding: 15px 27px;
        display: flex;
        justify-content: space-between;
        color: #000;
        font-weight: 700;
        font-size: 13px;
        @media (max-width: 1665px) {
          font-size: 12px;
        }
        .info-value {
          font-weight: 400;
          .expired-date {
            color: #fd3617;
            font-weight: bold;
          }
        }
        .info-areas {
          display: flex;
        }
      }
    }
  }
  &__inner {
    max-height: 100%;
    overflow: auto;
    padding: 15px 20px;
    @media (min-width: 768px) {
      padding: 40px 50px;
      min-width: 500px;
    }
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 3px 40px #00000029;
    .modal--big & {
      max-width: 700px;
      width: 100%;
      @media (max-width: 767px) {
        max-width: calc(100% - 30px);
      }
    }
    &--edit {
      max-width: 1400px !important;
    }
    &--overflow-unset {
      overflow: unset;
    }
    &--taskBox {
      min-width: 1080px;
    }

  }
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    .btn {
      &--green {
        width: auto;
      }
    }
  }
}