.new-tab {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 0px;
  .dashboard__header-item {
    width: 180px;
  }
  &__item {
    position: relative;
  }
  .btn {
    margin-left: 24px;
  }
 
  &__modal {
    h1 {
      text-align: center;
      color: #000;
      font-size: 18px;
      font-weight: 700;
    }
    .field-label {
      color: #000;
      font-family: 'Montserrat';
      font-size: 14px !important;
      font-weight: 700 !important;
    }
    .form__field input {
      background-color: #EFEFEF;
      border: 1px solid #EFEFEF;
    }
    .editModal__actions {
      justify-content: center !important;
      gap: 20px;
    }
    .btn {
      min-width: 262px;
    }
  }
  
}