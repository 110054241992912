.dashboardDictionary {
  &__header {
    @media (min-width: 376px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 15px 15px;
    }
    @media (max-width: 1215px) {
      flex-direction: column;
      align-items: start;
    }
    // @media (max-width: 1665px) {
    //   margin-top: 45px;
    // }
    // &--bhp {
    //   .dashboardDictionary__submenu-item {
    //     &:hover {
    //       @extend .font-big;
    //       font-size: 22px;
    //       opacity: 1;
    //     }
    //   }
    // }
  }
  &__buttons {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }
  &__submenu {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    max-width: 100%;
    overflow: auto;
    // margin-bottom: 20px;
    @media (max-width: 1215px) {
      margin-bottom: 20px;
    }
    &-item {
      border-bottom: 4px solid transparent;
      padding: 0 6px;
      font: 700 16px/19px $fontMontserrat;
      color: $black;
      opacity: 0.55;
      cursor: pointer;
      margin: 0 9px;
      white-space: nowrap;
      // transition: font 0.1s ease-in, border-bottom-color 0.1s ease-in;
      &:hover {
        // @extend .font-big;
        // font-size: 22px;
        opacity: 1;
        border-bottom: 2px solid transparent;
        .dashboardDictionary__header--bhp & {
          border-bottom-color: $blue;
        }
        .dashboardDictionary__header--energy & {
          border-bottom-color: $orange;
        }
        .dashboardDictionary__header--environment & {
          border-bottom-color: $mainGreen;
        }
      }
      &:first-of-type {
        margin-left: 0;
      }
      &.active {
        @extend .font-big;
        font-size: 27px;
        &:hover {
          border-bottom: 4px solid transparent;
        }
        .dashboardDictionary__header--bhp & {
          border-bottom-color: $blue;
        }
        .dashboardDictionary__header--energy & {
          border-bottom-color: $orange;
        }
        .dashboardDictionary__header--environment & {
          border-bottom-color: $mainGreen;
        }
        opacity: 1;
        @media (max-width: 1665px) {
          font-size: 22px;
        }
      }
      @media (max-width: 1665px) {
        font-size: 14px;
      }
    }
  }
  &__searchBox {
    display: flex;
    @media (max-width: 1215px) {
      // flex-direction: row;
      .search__form {
        display: flex;
        gap: 20px;
        align-items: center;
      }
      .compendiumCounters {
        margin-top: unset;
      }
    }
    @media (max-width: 768px) {
      
      margin-top: 15px;
      width: 100%;
      min-width: 0;
    }
    @media (max-width: 375px) {
      padding: 0 15px;
      margin-bottom: 20px;
    }
  }
  &--bhp {
    .text-highlighted {
      background-color: $blue;
    }
    .ReactTable .rt-thead .rt-th.-sort-asc,
    .ReactTable .rt-thead .rt-td.-sort-asc {
      box-shadow: inset 0 3px 0 0 $blue;
    }
    .ReactTable .rt-thead .rt-th.-sort-desc,
    .ReactTable .rt-thead .rt-td.-sort-desc {
      box-shadow: inset 0 -3px 0 0 $blue;
    }
  }
  &--energy {
    .text-highlighted {
      background-color: $orange;
    }
    .ReactTable .rt-thead .rt-th.-sort-asc,
    .ReactTable .rt-thead .rt-td.-sort-asc {
      box-shadow: inset 0 3px 0 0 $orange;
    }
    .ReactTable .rt-thead .rt-th.-sort-desc,
    .ReactTable .rt-thead .rt-td.-sort-desc {
      box-shadow: inset 0 -3px 0 0 $orange;
    }
  }
  &--environment {
    .text-highlighted {
      background-color: $mainGreen;
    }
    .ReactTable .rt-thead .rt-th.-sort-asc,
    .ReactTable .rt-thead .rt-td.-sort-asc {
      box-shadow: inset 0 3px 0 0 $mainGreen;
    }
    .ReactTable .rt-thead .rt-th.-sort-desc,
    .ReactTable .rt-thead .rt-td.-sort-desc {
      box-shadow: inset 0 -3px 0 0 $mainGreen;
    }
  }
}
