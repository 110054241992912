.editModal {
  &--form {
    label {
      font-weight: 400;
      color: #1a1a1a;
      font-size: 12px;
      pointer-events: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0 5px 2px;
    }
    .areaManagement__label {
      margin: 20px 0 10px;
    }
    .form__field {
      &--checkbox {
        label {
          order: 2;
        }
      }
    }
  }
  &--file-name {
    font-size: 12px;
    margin: 0 0 10px 5px;
  }
  &__topicality-categories {
    align-items: center;
    display: flex;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    .btn {
      &--green {
        width: auto;
      }
    }
  }
}
