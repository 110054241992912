.pagination {
  font-family: Arial, Helvetica, sans-serif;
  margin: 5px 0 15px 0;
  &__list {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 0;
  }
  &__list-element {
    width: 30px;
    height: 28px;
    // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    color: #949494;
    display: flex;
    margin: 0 5px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    &--active,
    &:hover {
      background-color: #f6f6f6;
      color: #222;
    }
    &--disabled {
      color: #e2e2e2;
      &:hover {
        background-color: #fff;
        color: #e2e2e2;
      }
    }
  }
}
