.regulations {
  padding: 35px 25px;
  overflow: auto;
  text-align: center;
  &__header {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  &__paragraph {
    font-weight: 700;
    margin-bottom: 10px;
  }
  &__title {
    font-weight: 700;
    margin-bottom: 10px;
  }
  &__content {
    margin: 0 auto;
    max-width: 600px;
    text-align: left;
    p {
      margin-bottom: 10px;
    }
    span {
      font-weight: 700;
    }
  }
  &__indent {
    margin-left: 25px;
  }
}
