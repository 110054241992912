@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
$darkBlue: #222d5c;
$mainGreen: #32c832;
$mainGreenHover: #2bab2b;
$mianNavyBlue: #212748;
$lightGray: #43425d80;
$cream: #f3efec;
$lightCream: #f3f3f3;
$dark: #1a1a1a;
$orange: #ff5800;

$gray: #f2f2f2;

$fontLato: 'Lato', sans-serif;
$fontRoboto: 'Roboto', sans-serif;
$fontInter: 'Inter', sans-serif;
$fontOpenSans: 'Open Sans', sans-serif;
$fontMontserrat: 'Montserrat', sans-serif;

$yellow: #e8e10d;
$blue: #0080ff;
$green: #00d154;
$orange: #ffaa00;
$gray: #ababab;
$red: #ff5454;
$deepRed: #ff0000;
$iconRed: #fd3617;
$grayLight: #f6f6f6;
$sharpGray: #afafaf;
$transparentHover: #f1f0f0;
$borderGray: #707070;
$black: #000;
$white: #fff;
$inputGray: #efefef;
$backgroundDark: #3a3a3a;
$grayDarker: #f1f1f1;
$inputBorderBottom: #2b2b2b;
$gray100: #c9c9c9;
$gray200: #f5f5f5;
$gray300: #f8f8f8;
