.radioButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $fontMontserrat;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #707070;
  cursor: pointer;
  &:hover {
    input,
    label {
      cursor: pointer;
    }
  }
  input {
    margin: 0 8px 0;
  }
}
