.svg {
  display: block;
  &--notification-dot circle{
    fill: #FF002E;
  }
  &--file {
    .a {
      fill: none;
      stroke: $black;
      stroke-linecap: round;
      stroke-linejoin: round;
      .active-ico & {
        stroke: $blue;
      }
    }
  }
  &--paragraph {
    width: 8px;
    margin: 0 auto;
    .a {
      font-size: 17px;
      font-family: TrebuchetMS, Trebuchet MS;
      .active-ico & {
        fill: $blue;
      }
    }
  }
  &--alert-x {
    width: 10px;
    .a {
      fill: $iconRed;
    }
  }
  &--file {
    min-width: 11px;
  }
  &--star {
    // .a {
    //   fill: #0080ff;
    // }

    .b {
      filter: url(#a);
    }
  }
  &--chevron {
    .a {
      fill: none;
      stroke: #000;
      stroke-width: 2px;
    }
  }
  &--magnifier {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 3px;
    }
  }
}
.grey-face {
  .a{fill:none;stroke:grey;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}
}