.notifications {
  padding: 0 150px;
  margin-top: 39px;
  &__settings {
    display: flex;
    .element {
      color: #707070;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      &:first-child {
        margin-right: 80px;
      }
    }
    .extendTextToggle {
      margin-right: 0;
    }
    .extendTextToggle__switch--active {
      background-color: #252D53;
    }
    .extendTextToggle__switch--active .extendTextToggle__switch_inner {
      margin-left: 19px;
      width: 17px;
      height: 16px;
      margin-top: 1px;
    }
  }
  .notification-popup__day {
    padding-left: unset;
  }
}