.mobileSubmenu {
  width: 100%;
  &__current {
    position: relative;
    cursor: pointer;
    font-size: 17px;
    line-height: 20px;
    font-weight: 700;
    border-bottom: 1px solid $grayLight;
    padding: 20px 15px 20px 35px;
    &:before {
      content: '';
      border-radius: 3px;
      width: 8px;
      height: 8px;
      border-bottom: 3px solid $black;
      border-left: 3px solid $black;
      position: absolute;
      left: 15px;
      top: 45%;
      transform: translateY(-50%) rotate(-45deg);
    }
  }
  &__item {
    padding: 10px 15px 10px 35px;
    font-size: 12px;
    line-height: 15px;
    &.active {
      font-weight: bold;
    }
  }
}
