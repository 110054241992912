.demo-row {
  background-color: $red;
  width: 36px;
  height: 16px;
  font-size: 9px;
  font-family: $fontMontserrat;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
