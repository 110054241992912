.instutitionElement,
.normElement {
  padding: 15px 25px;
  border-bottom: 2px solid $grayLight;
  font-family: $fontMontserrat;
  @extend .font-sm;
  &__name {
    display: flex;
    @extend .bold-700;
    span {
      &:first-of-type {
        padding-right: 5px;
      }
    }
  }
  &__block {
    margin-bottom: 15px;
    &:first-of-type {
      margin-top: 15px;
    }
    .label {
      display: block;
      margin-bottom: 5px;
      @extend .bold-700;
      @extend .font-xs;
    }
    .value,
    .extendText {
      @extend .bold-500;
    }
    &--edit {
      text-align: right;
      .btn {
        display: inline-block;
      }
    }
  }
}
