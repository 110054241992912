.area {
  padding: 32px 0 32px 58px;
  &--forbidden {
    background-color: $backgroundDark;
    min-height: calc(100vh - 99px);
    height: 100%;
    @media (max-width: 1665px) {
      min-height: calc(100vh - 80px);
    }
    @media (max-width: 1221px) {
      min-height: calc(100vh - 92px);
    }
    @media (max-width: 768px) {
      min-height: calc(100vh - 82px);
    }
    @media (max-width: 376px) {
      min-height: calc(100vh - 70px);
    }
    display: flex;
  }
  &__content {
    max-width: 550px;
    margin-top: 35px;
  }
  &__image {
    width: calc(100% - 550px);

    padding-left: 70px;
  }
}
.learnMore {
  color: #fff;
  font-family: $fontMontserrat;
  font-size: 14px;
  line-height: 18px;
  svg {
    margin-bottom: 25px;
    width: 100px;
    height: 100px;
    g {
      fill: #fff;
    }
  }
  &__icon--addition svg{
    path {
      fill: #fff;
    }
  }
  &__title {
    font: bold 49px/59px $fontMontserrat;
    margin-bottom: 20px;
  }
  &__badge {
    font: bold 14px/19px $fontOpenSans;
    background-color: $red;
    display: inline-block;
    padding: 4px 12px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 25px;
    &--blue {
      background-color: $blue;
    }
    &--green {
      background-color: $green;
    }
    &--orange {
      background-color: $orange;
    }
  }
  &__badges {
    width: fit-content;
    display: flex;
    flex-direction: column;
    .learnMore__badge {
      text-align: left;
      font-size: 16px;
      div {
        padding: 4px 2px;
      }
    }
  }
  &__heading {
    margin-bottom: 25px;
    font-weight: 500;
    strong {
      font-weight: bold;
    }
  }
  &__description {
    font-weight: 300;
    margin-bottom: 30px;
  }
  &__ctaDescription {
    @extend .font-big;
    font-size: 25px;
    margin-bottom: 25px;
  }
}
.imageWrapper {
  background-size: cover;
  // background-position: 50% 100%;
  background-position-x: 50%;
  width: 100%;
  height: 100%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
