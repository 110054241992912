.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  h1 {
    font-size: 20px;
  }
  button {
    width: 200px;
    font-size: 15px;
    margin-top: 25px;
  }
}
