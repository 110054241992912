.hiddenRowsToggle {
  @extend .font-md;
  font-family: $fontMontserrat;
  // margin-bottom: 20px;
  display: flex;
  align-items: center;
  // margin-top: -10px;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 500;
  color: $borderGray;
  span {
    cursor: pointer;
  }
  &__switch {
    cursor: pointer;
    width: 40px;
    height: 20px;
    margin-left: 10px;
    display: inline-block;
    border: 1px solid $gray100;
    border-radius: 10px;
    &_inner {
      width: 20px;
      height: 18px;
      border: 1px solid #fff;
      background-color: $inputGray;
      border-radius: 10px;
      transition: 0.1s linear margin-left, background-color;
    }
    &--active {
      .hiddenRowsToggle__switch_inner {
        margin-left: 18px;
        .dashboardDictionary--bhp & {
          background-color: $blue;
        }
        .dashboardDictionary--energy & {
          background-color: $orange;
        }
        .dashboardDictionary--environment & {
          background-color: $mainGreen;
        }
      }
    }
  }
}
