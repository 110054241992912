.compendiumElement {
  padding: 5px 25px;
  background-color: $grayLight;
  margin-bottom: 5px;
  border-radius: 2px;
  font-family: $fontMontserrat;
  &--term {
    @extend .font-sm;
    margin-bottom: 5px;
    font-weight: 700;
  }
  &--department {
    @extend .font-xs;
  }
}
.compendiums {
  position: relative;
}
.compendiumsMobile {
  max-height: calc(100vh - 182px);
  overflow: auto;
  .compendiumLetters {
    position: absolute;
    z-index: 9;
    right: 0;
    top: 10px;
    &__single {
      padding: 4px 5px;
      @extend .font-xs;
      text-align: right;
      font-family: $fontMontserrat;
      font-weight: 600;
      &.compendiumLetters__single--active {
        @extend .font-lg;
        font-weight: 700;
      }
    }
  }
  &--preview {
    max-height: calc(100vh - 70px);
  }
}

.compendiumPreview {
  &__term {
    @extend .font-sm;
    @extend .family-m;
    @extend .bold-700;
    margin-bottom: 5px;
  }
  &__department {
    @extend .font-xs;
    @extend .family-m;
    @extend .bold-500;
    margin-bottom: 25px;
  }
  &__extendedView {
    @extend .font-xs;
    @extend .bold-700;
    display: inline-block;
    padding: 7px 20px;
    border-radius: 15px;
    .star {
      padding-right: 7px;
    }
    .arrow {
      padding-left: 7px;
    }
  }
  &__options {
    text-align: center;
  }
  &__heading {
    &__inner {
      padding: 5px 35px 25px;
      background-color: $gray300;
      margin-bottom: 25px;
    }
  }
  &__goBack {
    padding: 15px 20px;
    border-bottom: 1px solid $gray200;
    span {
      @extend .font-md;
      @extend .bold-700;
    }
  }
  // &__details {
  //   padding: 0 15px;
  // }
}
.companies-select {
  width: 200px;
  margin: 0 0 0px 20px;
  .react-select {
    &__control {
      border-radius: 2px !important;
    }
    &__indicator {
      padding-right: 7px !important;
    }
    &__placeholder,
    &__single-value {
      font-size: 14px !important;
    }
    &__menu {
      font-size: 13px;
      z-index: 9;
    }
  }
}

.work-plan-date {
  margin-bottom: 10px;
  &--text {
    color: #acacac;
    font-weight: 500;
  }
  &--red {
    color: #ff0000;
  }
  &--yellow {
    color: #ffaa00;
  }
}
