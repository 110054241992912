.time-trial-list {
  position: absolute;
  width: 100%;
  height: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px;
    flex-direction: column;
    @include rwd(601px) {
      flex-direction: row;
    }
  }
  &__title {
    text-align: left;
    width: 100%;
    @include rwd(601px) {
      width: unset;
    }
    h2 {
      font-size: 17px;
      color: $darkBlue;
      font-family: $fontLato;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    width: 490px;
    // flex-direction: column;
    @include rwd(601px) {
      flex-direction: row;
    }
  }
  &__form-search {
    width: 890px;
    margin-right: 30px;
    .form__field {
      input {
        padding: 5px 10px;
        height: 25px;
      }
      input::placeholder {
        color: rgba(46, 46, 46, 0.29);
      }
    }
  }
  &__table {
    overflow-x: auto;
    @media screen and (max-width: 1200px) {
      table {
        table-layout: unset;
        tbody {
          .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            //   height: -webkit-fill-available;
          }
          tr {
            height: unset;
          }
          td {
            vertical-align: middle;
            white-space: nowrap;
            padding: 2px 15px 10px 15px;
          }
        }
      }
    }
  }
}
